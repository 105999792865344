export const table =
{
	"_id": "images",
	"indexFields": [
		"name",
		"img"
	],
	"name": "Képek",
	"collection": "images",
	"icon": "Filter",
	"title_create": "Új kép",
	"updatedAt": {
		"seconds": 1683973493,
		"nanoseconds": 484000000
	},
	"updatefields": [
		"name",
		"img"
	],
	"title_update": "Kép módosítása",
	"fields": [
		{
			"_document": null,
			"title_create": "",
			"name": "name",
			"updatefields": null,
			"_id": "item_1683973182699",
			"helper": "Név",
			"store_image_type": null,
			"document": null,
			"default": null,
			"fields": [],
			"model": null,
			"title_index": "",
			"bucket": "",
			"valuesField": null,
			"required": "true",
			"document_dynamic": null,
			"type": "text",
			"title_update": "",
			"indexFields": null,
			"label": "Név",
			"valuesKey": null,
			"valuesLabel": null
		},
		{
			"document": null,
			"model": null,
			"valuesLabel": null,
			"valuesField": null,
			"document_dynamic": null,
			"type": "img_store",
			"fields": [],
			"_id": "item_1683973252810",
			"title_update": "",
			"title_create": "",
			"required": "true",
			"store_image_type": "custom",
			"updatefields": null,
			"bucket": "image",
			"valuesKey": null,
			"helper": "Kép",
			"_document": null,
			"indexFields": null,
			"title_index": "",
			"label": "Kép",
			"default": null,
			"name": "img"
		}
	],
	"title_index": "Képek",
	"meta": null,
	"id": "images"
}
export default table