import React from 'react';
import CHtmlElement from '../../common/CHtmlElement';
import { getProp, getPropArray } from '../../common/Utility';
import { Avatar, CircularProgress, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { Comment } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ProcessButton from '../../common/ProcessButton';

const layout = {
    "mobile": {
        "type": "custom", //miu, html, custom
        "selector": "Mobile",
        "declarations": {
            "backgroundColor": '#f00',
        }
    },
    "desktop": {
        "type": "custom",//miu, html, custom
        "selector": "Main",
        "declarations": {
            "backgroundColor": '#0f0',
        }
    }
}
const View = ({ createList, id, model, children, app, loading, loading_backup, user, user_backup, view = 'desktop', ...params }) => {
    const navigate = useNavigate();
    return (
        <CHtmlElement app={app} layout={layout} view={view}>
            <List params sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {model && model.docs &&
                    model.docs.map((field, index) =>
                        <ListItem
                            key={index}
                            secondaryAction={
                                <ProcessButton
                                    name="Toolbar"
                                    icon="FormatAlignCenter"
                                    onClick={(t) => createList({ id:field.id, model: field.data() })}
                                />
                            }
                        >
                            <ListItemAvatar>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                            </ListItemAvatar>
                            <ListItemText>
                                {field.data().name}
                            </ListItemText>
                        </ListItem>
                    )}
            </List>
        </CHtmlElement>
    );
};

export default View;