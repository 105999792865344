import React from 'react';
import { TreeItem } from '@mui/lab';

const CTreeItem = ({
    main,
    node,
    nodes,
    createNode,
    updateNode,
    deleteNode,
    active,
    setActive
}) => {
    const data = nodes ? nodes[node] : { name: 'xx' };
    const children = data ? data.children : {};
    return (
        data ?
            <TreeItem nodeId={data.id} label={data.name === 'MUIComponent' ? data.properties['MUIcomponent'] : data.name}>
                {
                    children && children.components &&
                    children.components.map((cid, index) => (
                        <CTreeItem
                            key={index}
                            node={cid}
                            main={main}
                            nodes={nodes}
                            updateNode={updateNode}
                            deleteNode={deleteNode}
                            createNode={createNode}
                            active={active}
                            setActive={setActive}
                        />
                    ))
                }
            </TreeItem>
            : <></>
    );
};

export default CTreeItem;