import React from 'react';
import FSPRowField from './FSPRowField';
import { getProp } from '../../Utility';

const FSPRowFields = ({
    record,
    metaArray,
    fieldsArray,
    table,
    view
}) => {
    const indexFields = getProp(table, 'indexFields');
    return (
        <div>
            {indexFields &&
                indexFields.map((fieldName, index) => (
                    <FSPRowField
                        key={index}
                        fieldName={fieldName}
                        index={index}
                        record={record}
                        metaArray={metaArray}
                        fieldsArray={fieldsArray}
                        view={view}
                    />
                ))}
        </div>
    );
};

export default FSPRowFields;