import React from 'react';
import { ListItem, TableBody } from '@mui/material';
import { CardsContainer } from '../../../styles';

const CTableBody = ({ children, view = 'desktop', ...params }) => {
    return (
        <>
            {view == 'mobile' ?
                <>
                    {children}
                </>
                :
                <>
                    <CardsContainer params>
                        {children}
                    </CardsContainer>
                    <CardsContainer />
                </>
            }
        </>
    );
};

export default CTableBody;