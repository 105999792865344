import React, { useContext, useEffect, useState } from 'react';
import { getItemId } from '../Utility';
import { Context2 } from '../../services/fbcontext';
import { defaultNode } from './default';

const Nodes = () => {
    const [nodes, setNodes] = useState(
        defaultNode
    );

    const createNode = ({ parent_id, parent_prop, name, properties }) => {
        console.log('parent_id', parent_id);
        var newNodes = { ...nodes };
        // uj elem letrehozasa
        const id = getItemId();
        const e = {
            id: id,
            name: name,
            parent: parent_id,
            parent_prop: parent_prop,
            properties: properties,
            children: {}
        }
        newNodes[id] = e;

        // elem beillesztése a parentbe
        if (newNodes[parent_id]['children'][parent_prop] === undefined) {
            newNodes[parent_id]['children'][parent_prop] = []
        }
        newNodes[parent_id]['children'][parent_prop].push(id);

        // mentés
        setNodes(newNodes);
        return ({
            node: id,
            parent: newNodes[parent_id]
        })
    }

    const deleteNode = (id) => {
        console.log(id);
        console.log(nodes);
        var newNodes = { ...nodes };
        var n = newNodes[id];
        console.log(n);
        const parent_id = n.parent;
        const parent_prop = n.parent_prop;
        var pc = { ...newNodes[parent_id]['children'] };
        pc[parent_prop] = pc[parent_prop].filter((el) => el != id);

        newNodes[parent_id] =
        {
            ...newNodes[parent_id],
            children: pc
        }

        // TODO: törölni az összes childrent, rekorzivan
        // Object.keys(n.children).forEach((prop, index) => {
        //     n.children[prop].forEach((n, index2) => {
        //         console.log('ELEM TÖRLÉSE', n)
        //         deleteNode(n);
        //         newNodes[id]=null;
        //     })
        // });

        newNodes[id] = null;
        setNodes(newNodes);
        return newNodes[parent_id]
    }

    const updateNode = (id, properties) => {
        console.log('aaaa1', id);
        console.log('aaaa2', properties);
        console.log('aaaa3', nodes[id]);
        var newNodes = { ...nodes };
        newNodes[id] =
        {
            ...newNodes[id],
            properties: properties
        }
        setNodes(newNodes);
        return newNodes[id];
    }

    return {
        nodes, setNodes,
        createNode, deleteNode, updateNode
    };
};

export default Nodes;