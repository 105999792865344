import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import * as Icons from '@mui/icons-material';

const ProcessButton = ({ name, icon, color, onClick, disabled, fontSize = "small" }) => {
    const IconComponent = Icons[icon];
    return (
        onClick ? (
            <Tooltip title={name || icon}>
                <IconButton
                    onClick={() => onClick()}
                    disabled={disabled}
                   
                    fontSize={fontSize}
                    aria-label={icon} size={fontSize}>
                    <IconComponent
                        fontSize={fontSize}
                        color={color}
                        sx={{
                            fontSize: { fontSize },
                            // color: grey[500]
                            color: { color },

                        }}
                    />
                </IconButton>
            </Tooltip>
        ) :
            (
                <Tooltip title={name || icon}>
                    <IconButton
                        disabled={disabled}
                       
                        
                        aria-label={icon} size="small">
                        <IconComponent
                            
                            color={color}
                            
                            sx={{
                                fontSize:{fontSize},
                                // color: grey[500]
                                color: { color },
                            }}
                        />
                    </IconButton>
                </Tooltip>
            )

    );
};

export default ProcessButton;