export const table =
{
	"type": "array",
	"indexFields": [
		"evaluatedId",
		"type",
		"template",
		"name"
	],
	"updatefields": [
		"name"
	],
	"meta": [
		"users",
		"qtemplates"
	],
	"fields": [
		{
			"type": "document_select",
			"required": "true",
			"indexFields": [],
			"updatefields": [],
			"fields": {},
			"name": "evaluatedId",
			"helper": "evaluatedId",
			"label": "evaluatedId",
			"model": "users",
			"valuesKey": "id",
			"valuesLabel": "email",
			"_id": "item_1694772592708"
		},
		{
			"type": "text",
			"required": "true",
			"indexFields": [],
			"updatefields": [],
			"fields": {},
			"name": "type",
			"helper": "type",
			"label": "type",
			"_id": "item_1694772630059"
		},
		{
			"type": "document_select",
			"required": "true",
			"indexFields": [],
			"updatefields": [],
			"fields": {},
			"name": "template",
			"helper": "template",
			"label": "template",
			"model": "qtemplates",
			"valuesKey": "id",
			"valuesLabel": "name",
			"_id": "item_1694772660694"
		},
		{
			"type": "text",
			"required": "true",
			"indexFields": [],
			"updatefields": [],
			"fields": {},
			"name": "name",
			"helper": "name",
			"label": "name",
			"_id": "item_1694772673034"
		}
	],
	"id": "evaluated",
	"_id": "evaluated",
	"name": "Értékelések",
	"icon": "ThumbsUpDown",
	"collection": "evaluated",
	"title_index": "Értékelések",
	"title_create": "Új",
	"title_update": "Módosítás",
	// "ui": "general"
}
export default table