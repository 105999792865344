import { MenuItem, TextField } from '@mui/material';
import React from 'react';
import { convertArrayById } from '../../../Utility';
import { tables } from '../../../../data/tables';
import FSPRowFieldImgStore from '../../FSPRow/FSPRowField/FSPRowFieldImgStore';

const FSPFieldImageSelect = ({
    id,
    label,
    placeholder,
    options,
    valuesKey,
    valuesLabel,
    handleChange,
    model,
    value,
    addId
}) => {
    const f = convertArrayById(tables[model]['fields'], 'name');
    const v = f[valuesLabel];
    return (
        <div>
            <div>
                {label}
            </div>
            <TextField
                required
                fullWidth
                id={id}
                name={id}
                label={label}
                select
                value={value}
                onChange={handleChange}
            // helperText={field.helper}
            >
                {addId &&
                    <MenuItem
                        key={'id'}
                        value={'id'}>
                        {'id'}
                    </MenuItem>}
                {options.map((option) => (
                    <MenuItem
                        key={option[valuesKey]}
                        value={option[valuesKey]}>
                        <FSPRowFieldImgStore
                            value={option[valuesLabel]}
                            bucket={v.bucket}
                        />
                    </MenuItem>
                ))}
            </TextField>
        </div>
    );
};

export default FSPFieldImageSelect;