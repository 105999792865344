import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle, registerWithEmailAndPassword } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Alert } from "@mui/material";
import "./Register.css";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [loginerror, setError] = useState(null);
  const navigate = useNavigate();

  // const register = () => {
  //   if (!name) alert("Please enter name");
  //   registerWithEmailAndPassword(name, email, password);
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      await registerWithEmailAndPassword(data.get("name"), data.get("email"), data.get("password"), data.get("password"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/");
  }, [user, loading]);

  return (
    <Container component="main" maxWidth="xs">

      <Box
        sx={{
          marginTop: 12,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          // alt={translate('education_2030')}
          // className={classes.logo}
          src={'/logo.png'}
          width={100}
        />
        <Typography component="h1" variant="h5">
          Regisztráció
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Név"
            name="name"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email cím"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Jelszó"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Regisztráció
          </Button>
          {/* <button className="register__btn" onClick={register}>
            Register
          </button> */}
          {/* <button
            className="register__btn register__google"
            onClick={signInWithGoogle}
          >
            Register with Google
          </button> */}
          {/* <Button
            fullWidth
            onClick={signInWithGoogle}
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
          >
            Bejelentkezés Google fiókkal
          </Button> */}
          <Grid container>
            <Grid item xs>
              <Link to="/reset" variant="body2">
                Elfelejtett jelszó
              </Link>
            </Grid>
            <Grid item>
              <Link to="/signin" variant="body2">
                Belépés
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

export default Register;
