import { useEffect, useState } from 'react';
import { getFirestore, collection, onSnapshot, query, where, limit } from 'firebase/firestore';
import { db, auth } from "../../../firebase";
import { getPropArray } from '../../../common/Utility';

const Subscriptions = ({ setLoading, deleteUnSubscription }) => {
    const [subscriptions, setSubscriptions] = useState({});
    const subscribeToCollection = ({ callback, collectionName }) => {
        setLoading({ key: collectionName, status: true })
        // const collectionRef = collection(db, collectionName);
        let q = ''
        const queryConstraints = [];
        queryConstraints.push(limit(30));
        const collectionRef = query(collection(db, collectionName), ...queryConstraints);
        const unsubscribe = onSnapshot(collectionRef, docsSnap => {
            const fromCache = getPropArray(docsSnap, ['_snapshot', 'fromCache']);
            if (!fromCache)
                setLoading({ key: collectionName, status: false })
            callback(collectionName, docsSnap);
        });
        setSubscriptions((prevState) => ({
            ...prevState,
            [`${collectionName}`]: unsubscribe,
        }));
    };

    const unsubscribe = ({ collectionName }) => {
        if (subscriptions[collectionName]) {
            subscriptions[collectionName]();
        }
    }
    const unsubscribeFromCollection = ({ collectionName }) => {
        unsubscribe({ collectionName: collectionName })
        deleteSubscription({ collectionName: collectionName })
        deleteUnSubscription({ collectionName: collectionName })
        // const subscriptionKey = `${collectionName}`;
        // setSubscriptions((prevState) => {
        //     const { [subscriptionKey]: deleted, ...rest } = prevState;
        //     console.log('LEEEEE rest', rest);
        //     return rest;
        // });
    };

    const deleteSubscription = ({ collectionName }) => {
        setSubscriptions((prevState) => {
            const { [collectionName]: deleted, ...rest } = prevState;
            return rest;
        });
    }

    // useEffect(() => {
    //     return () => {
    //         console.log('Leiratkozunk mindenről');
    //         Object.values(subscriptions).forEach((unsubscribe) => {
    //             if (unsubscribe) {
    //                 unsubscribe();
    //             }
    //         });
    //     };
    // }, [subscriptions]);

    return {
        subscriptions, setSubscriptions,
        subscribeToCollection,
        unsubscribeFromCollection
    };
};

export default Subscriptions;