export const Button = {
  name: 'Button',
  description: 'A button component for user interactions.',
  props: [
    {
      name: 'content',
      type: 'string',
      default: 'contained',
      required: false,
      description: 'The visual variant of the button.',
    },
    {
      name: 'variant',
      type: 'string',
      default: 'contained',
      required: false,
      description: 'The visual variant of the button.',
    },
    {
      name: 'color',
      type: 'string',
      default: 'primary',
      required: false,
      description: 'The color scheme of the button.',
    },
    {
      name: 'size',
      type: 'string',
      default: 'medium',
      required: false,
      description: 'The size of the button.',
    },
    {
      name: 'disabled',
      type: 'bool',
      default: false,
      required: false,
      description: 'Specifies whether the button is disabled.',
    },
    {
      name: 'loading',
      type: 'bool',
      default: false,
      required: false,
      description: 'Specifies whether the button is disabled.',
    },
    {
      name: 'fullWidth',
      type: 'bool',
      default: false,
      required: false,
      description: 'Specifies whether the button is disabled.',
    },
    {
      name: 'onClick',
      type: 'function',
      default: '',
      required: false,
      description: 'Callback function to handle click event on the button.',
    },
  ],
  children: [
    {
      name: 'text',
      description: 'The text content of the button.',
    },
  ],
};
