export const value_mutiselect_local = ({
    name, required, label, model, valuesKey,
    valuesLabel, autoHide, addId, defaultValue
}) => {
    return {
        "required": required,
        "name": name,
        "helper": label,
        "q2": label,
        "label": label,
        "model": model,
        "valuesKey": valuesKey,
        "valuesLabel": valuesLabel,
        "addId": addId,
        "type": "value_mutiselect_local",
        "autoHide": autoHide,
        "default": defaultValue
    }
}