import React, { useState, useEffect, useContext } from 'react';
import { Context2 } from '../../services/fbcontext';
import { Chip, ToggleButton } from '@mui/material';
import { Face, Help, Hub } from '@mui/icons-material';
import { tables } from '../../data/tables';
import { DynamicFaIcon } from '../DynamicFaIcon';
import NormalView from './components/NormalView';
import MiniView from './components/MiniView';

const SubscriptionsCleaner = ({ showLabel = false, miniView = true }) => {
  const {
    unsubscribeFromMeta,
    metaUnSubscriptions,
    subscriptions, metaSubscriptions, loadings
  } = useContext(Context2);
  // const {
  //   subscriptions
  // } = useContext(Context);

  const [updatedList, setUpdatedList] = useState(metaUnSubscriptions);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      console.log('updatedList', updatedList);
      const filteredList = Object.keys(updatedList).filter(item => {
        const itemTime = new Date(updatedList[item]);
        // return (currentTime - itemTime) <= 2 * 60 * 1000; // 2 perc
        return (currentTime - itemTime) <= 5; // 2 perc
      });
      console.log('filteredList', filteredList);
      const outdatedItems = Object.keys(updatedList).filter(item => {
        const itemTime = new Date(updatedList[item]);
        return (currentTime - itemTime) > 5; // 2 perc
      });

      console.log('outdatedItems', outdatedItems);
      if (outdatedItems.length > 0) {
        console.log('collectionNames1111', outdatedItems)
        outdatedItems.forEach(item => unsubscribeFromMeta({ collectionName: item }));
      }
      // const outdatedItems2 = outdatedItems.map((i) => updatedList[i]);
      let filteredList2 = [];

      filteredList.forEach((i) => {
        filteredList2[i] = updatedList[i]
        console.log(i);
      });

      console.log('filteredList2', filteredList2);
      // setUpdatedList(filteredList2);
    }, 10000); // 10 másodpercenként frissítés

    return () => {
      clearInterval(interval);
    };
  }, [updatedList]);

  useEffect(() => {
    setUpdatedList(metaUnSubscriptions);
  }, [metaUnSubscriptions]);
  // useEffect(() => {
  //   // setUpdatedList(metaUnSubscriptions);
  //   console.log('subscriptions!!!!', subscriptions);
  // }, [subscriptions]);
  return (
    <>
      {
        miniView ?
          <MiniView
            unsubscribeFromMeta={unsubscribeFromMeta}
            metaUnSubscriptions={metaUnSubscriptions}
            subscriptions={subscriptions}
            metaSubscriptions={metaSubscriptions}
            loadings={loadings}
            showLabel={showLabel}
            updatedList={updatedList}
          /> :
          <NormalView
            unsubscribeFromMeta={unsubscribeFromMeta}
            metaUnSubscriptions={metaUnSubscriptions}
            subscriptions={subscriptions}
            metaSubscriptions={metaSubscriptions}
            loadings={loadings}
            showLabel={showLabel}
            updatedList={updatedList}
          />
      }
    </>
  )
};

export default SubscriptionsCleaner;
