import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Avatar,
    Typography,
    IconButton,
    Menu,
    MenuItem,
} from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

const CardWidget = ({ title, avatar, subtitle, onDelete, onEdit, onView }) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const handleMenuOpen = (e) => {
        setMenuAnchorEl(e.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleViewClick = (event) => {
        handleMenuClose();
        onView(event);
    };

    const handleEditClick = (event) => {
        handleMenuClose();
        onEdit(event);
    };
    const handleDeleteClick = (event) => {
        handleMenuClose();
        onDelete(event);
    };
    return (
        <Card style={{ height: '125px', float: 'left', maxWidth: '300px', width: '100%', margin: '10px' }}>
            <CardHeader
                avatar={<Avatar>{avatar}</Avatar>}
                title={<Typography variant="h6">{title}</Typography>}
                subheader={subtitle}
                action={
                    <>
                        <IconButton onClick={handleMenuOpen}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleEditClick}>Módosítás</MenuItem>
                            <MenuItem onClick={handleDeleteClick}>Törlés</MenuItem>
                        </Menu>
                    </>
                }
            />
            <CardContent onClick={handleViewClick} style={{ cursor: 'pointer' }}>
                {/* További tartalom vagy adatok */}
            </CardContent>
        </Card>
    );
};

export default CardWidget;
