import { field_icon } from "../fields/field_icon"
import { field_number } from "../fields/field_number"
import { field_text } from "../fields/field_text"

export const table_users =
{
	"ui": "user_widget",
	"title_update": "Felhasználók szerkesztése",
	"title_index": "Felhasználók kezelése",
	"updatefields": [
		"email",
		"icon",
		"active",
		"applications",
		"isAdmin",
		"name"
	],
	"name": "Felhasználók",
	"updatedAt": {
		"seconds": 1699021559,
		"nanoseconds": 981000000
	},
	"title_create": "Új fehasználó",
	"icon": "AccountCircleSharp",
	"collection": "users",
	"fields": [
		{
			"_id": "item_1682341780768",
			"label": "email",
			"helper": "email",
			"name": "email",
			"type": "text"
		},
		{
			"label": "Név",
			"type": "text",
			"name": "name",
			"_id": "item_1682341794771",
			"helper": "Név"
		},
		{
			"_id": "item_1683708433432",
			"type": "img_store",
			"bucket": "profile_images",
			"helper": "Profilkép",
			"store_image_type": "document",
			"name": "icon",
			"label": "Profilkép",
			"required": "true"
		},
		{
			"document": "igen_nem",
			"type": "value_select_static",
			"name": "active",
			"_id": "item_1683708534948",
			"valuesKey": "value",
			"model": "constants",
			"label": "Aktív",
			"valuesField": "values",
			"valuesLabel": "label",
			"helper": "Aktív"
		},
		{
			"helper": "Csoportok",
			"valuesKey": "id",
			"model": "groups",
			"label": "Csoportok",
			"valuesLabel": "name",
			"name": "groups",
			"type": "document_multiselect",
			"_id": "item_1683708640948",
			"required": "true"
		},
		{
			"_id": "item_1684239549157",
			"model": "applications",
			"fields": [],
			"updatefields": [],
			"required": "true",
			"valuesLabel": "name",
			"helper": "Applikációk",
			"name": "applications",
			"default": [
				"NZxgvEO6TDQw5t1DsA8Y"
			],
			"label": "Applikációk",
			"type": "document_multiselect",
			"indexFields": [],
			"valuesKey": "id"
		},
		{
			"valuesLabel": "label",
			"updatefields": [],
			"model": "constants",
			"valuesField": "values",
			"required": "true",
			"helper": "Admin",
			"document": "igen_nem",
			"name": "isAdmin",
			"fields": {},
			"type": "value_select_static",
			"label": "Admin",
			"indexFields": [],
			"_id": "item_1699021335072",
			"valuesKey": "value"
		}
	],
	"meta": [
		"constants",
		"groups",
		"applications"
	],
	"type": "array",
	"_id": "users",
	"indexFields": [
		"email",
		"icon",
		"name"
	],
	"id": "users"
}
export default table_users