import React, { useState } from 'react';
import './editor.css';
import { useDrop } from 'react-dnd';
import * as components from './components/index'
import { DropComponent } from './components/DropComponent';

const CNode = ({
    main,
    node,
    nodes,
    createNode,
    updateNode,
    deleteNode,
    active,
    setActive,
    store,
    setStore,
}) => {
    const data = nodes[node];
    // const properties = data.properties;
    const children = typeof data === 'object' ? data.children : [];
    console.log('JsonNodeEditorNode', nodes);
    console.log('JsonNodeEditorNode', data);

    const drop2 = (a) => {
        console.log('dropppp', a);
        console.log('dropppp', a.element.data);
        const d = createNode({
            parent_id: node,
            parent_prop: 'components',
            name: a.element.data.component,
            properties: a.element.data
        })
        setActive(d.node);
    }
    const [{ isOver, isHover }, dropRef] = useDrop({
        accept: 'pet',
        drop: (item) => drop2(item),
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true })
        }),
        canDrop: function (props, monitor, component) {
            return (
                isOver
            );
        }
    })

    // const MainComponent =
    //     data && data.properties && data.properties.component
    //         ? components[data.properties.component]
    //         : components['defaultComponent'];

    const MainComponent =
        data && data.properties && data.properties.component
            ? (data.properties.component === 'html' ||
                data.properties.component === 'custom' ||
                data.properties.component === 'MUIComponent'
                ? DropComponent
                : components[data.properties.component])
            : components['defaultComponent'];

    return (
        <>
            <MainComponent
                node={node}
                main={main}
                nodes={nodes}
                updateNode={updateNode}
                deleteNode={deleteNode}
                createNode={createNode}
                active={active}
                setActive={setActive}
                store={store}
                setStore={setStore}
            >
                <div ref={dropRef} className='backgrounds' >
                    {(data && !data.parent) && data.name}

                </div>
                {isOver && <div>Drop Here1!</div>}

                {
                    children && children.components &&
                    children.components.map((cid, index) => (
                        <CNode
                            key={index}
                            node={cid}
                            main={main}
                            nodes={nodes}
                            updateNode={updateNode}
                            deleteNode={deleteNode}
                            createNode={createNode}
                            active={active}
                            setActive={setActive}
                            store={store}
                            setStore={setStore}
                        />
                    ))
                }
            </MainComponent >
        </>
    );
};

export default CNode;