import React from 'react';
import { Drawer, IconButton, Tab, Tabs, TabPanel, Box, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Abc, Group, Headphones } from '@mui/icons-material';
import DragAbleComponent from './components/DragAbleComponent';


const CustomTab = (props) => {
    return (
        <Tab
            {...props}
            sx={{
                height: 6,
                minWidth: 0, // Minimális szélesség
                padding: '1px 12px', // Belső térközök
                fontSize: 10, // Betűméret
            }}
        />
    );
};


const CToolbar = ({ model }) => {
    const [value, setValue] = React.useState('0');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    console.log('model', model ? model: 's');
    return (
        <>

            <Tabs
                style={{
                    // padding: '1px 1px 1px 1px', // Belső térközök
                    // margin: '1px 1px 1px 1px', // Belső térközök
                    // height: 10, // Kívánt magasság
                }}
                value={value}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
            >
                <CustomTab
                    value="0"
                    label="New "

                />
                <CustomTab value="1" label="Item Two" />
                <CustomTab value="2" label="Item Three" />
            </Tabs>
            <Box >
                {value === "0" && (
                    <>
                        <BottomNavigationAction showLabel={true} label={"teszt"} icon={<Abc />} />
                        <BottomNavigationAction showLabel={true} label={"teszt"} icon={<Group />} />
                        <BottomNavigationAction showLabel={true} label={"teszt"} icon={<Headphones />} />
                        <BottomNavigationAction showLabel={true} label={"teszt"} icon={<Abc />} />
                        <BottomNavigationAction showLabel={true} label={"teszt"} icon={<Abc />} />
                        <BottomNavigationAction showLabel={true} label={"teszt"} icon={<Group />} />
                    </>
                )}

                {value === "1" && (
                    <Box>
                        <>
                            <BottomNavigationAction showLabel={true} label={"teszt"} icon={<Abc />} />
                            <BottomNavigationAction showLabel={true} label={"teszt"} icon={<Abc />} />
                            <BottomNavigationAction showLabel={true} label={"teszt"} icon={<Group />} />
                        </>
                    </Box>
                )}
                {value === "2" && (
                    <Box>
                        <>
                            <BottomNavigationAction showLabel={true} label={"teszt"} icon={<Group />} />
                        </>
                    </Box>
                )}
            </Box>
            {model && Array.isArray(model) && model.map((element, index) => (
                <DragAbleComponent
                    key={index}
                    element={element.data()}
                />
            ))
            }
        </>
    );
};

export default CToolbar;