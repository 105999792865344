import { field_text } from "../fields/field_text"
import { value_select_static } from "../fields/value_select_static"

export const table_groups =
{
	"title_create": "Új csoport",
	"icon": "Group",
	"fields": [
		{
			"helper": "Név",
			"type": "text",
			"_id": "item_1682341905151",
			"label": "Csoport megnevezés",
			"name": "name"
		},
		{
			"helper": "Aktív",
			"valuesLabel": "label",
			"type": "value_select_static",
			"valuesField1": "label",
			"name": "active",
			"valuesKey1": "label",
			"model": "constants",
			"_id": "item_1682341978945",
			"label": "Aktív",
			"document": "igen_nem",
			"valuesField": "values",
			"valuesKey": "value"
		},
		{
			"required": "false",
			"indexFields": [],
			"_id": "item_1685491604857",
			"store_image_type": "document",
			"helper": "Profilkép",
			"name": "Profilkép",
			"bucket": "group_images",
			"updatefields": [],
			"label": "Profilkép",
			"fields": [],
			"type": "img_store"
		},
		{
			"valuesKey": "id",
			"helper": "Oktató",
			"fields": [],
			"updatefields": [],
			"required": "false",
			"label": "Oktató",
			"indexFields": [],
			"_id": "item_1685491965949",
			"valuesLabel": "email",
			"model": "users",
			"type": "document_select",
			"name": "Oktató"
		},
		{
			"indexFields": [],
			"_id": "item_1699021018184",
			"fields": {},
			"name": "avatarUpdatedAt",
			"label": "Avatar frissítése",
			"updatefields": [],
			"type": "text",
			"helper": "Avatar frissítése",
			"required": "true"
		},
		{
			"valuesLabel": "name",
			"updatefields": [],
			"model": "projects",
			"type": "document_multiselect",
			"name": "projects",
			"fields": {},
			"helper": "Projektek",
			"required": "true",
			"label": "Projektek",
			"indexFields": [],
			"valuesKey": "id",
			"_id": "item_1699022267322"
		}
	],
	"name": "Csoportok",
	"type": "array",
	"updatefields": [
		"name",
		"active",
		"Profilkép",
		"Oktató",
		"avatarUpdatedAt",
		"projects"
	],
	"meta": [
		"constants",
		"ui",
		"users",
		"projects"
	],
	"_id": "groups",
	"collection": "groups",
	"indexFields": [
		"name",
		"active",
		"Profilkép"
	],
	"updatedAt": {
		"seconds": 1699195557,
		"nanoseconds": 933000000
	},
	"ui": "group_widget",
	"title_index": "Csoportok ",
	"title_update": "Csoport szerkesztése",
	"id": "groups"
}

export default table_groups