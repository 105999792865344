import React, { useRef, useState } from 'react';
import Editable from './Editable';

const JsonNodeEditorField = ({ value, saveValue }) => {
    const inputRef = useRef();
    const [task, setTask] = useState(value);
    const save = () => {
        saveValue(task);
    }
    const cancel = () => {
        setTask(value);
    }
    return (
        <Editable
            text={task}
            placeholder="Write a task name"
            type="input"
            save={save}
            cancel={cancel}
        >
            <input
                type="text"
                name="task"
                placeholder="Write a task name"
                value={task}
                onChange={e => setTask(e.target.value)}
            />
        </Editable>
    );
};

export default JsonNodeEditorField;