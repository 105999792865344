export const table =
{
	"updatefields": [
		"name",
		"example",
		"instruction",
		"type",
		"elemek"
	],
	"collection": "scales",
	"icon": "Straighten",
	"title_update": "Skála szerkesztése",
	"title_create": "Új skála",
	"name": "scales",
	"_id": "Skálák",
	"fields": [
		{
			"name": "name",
			"helper": "Név",
			"type": "text",
			"_id": "item_1683629412064",
			"label": "Név"
		},
		{
			"_id": "item_1683629454528",
			"label": "Minta",
			"name": "example",
			"type": "text",
			"helper": "Minta"
		},
		{
			"type": "text",
			"_id": "item_1683629508680",
			"name": "instruction",
			"label": "Instrukció",
			"helper": "Instrukció"
		},
		{
			"name": "type",
			"_id": "item_1683630635278",
			"valuesLabel": "label",
			"valuesField": "values",
			"type": "value_select_static",
			"helper": "Skála típus",
			"label": "Skála típus",
			"document": "skala_tipusok",
			"valuesKey": "value",
			"model": "constants"
		},
		{
			"helper": "Elemek",
			"title_update": "Elem szerkesztése",
			"type": "json",
			"label": "Elemek",
			"name": "elemek",
			"title_index": "Elemek",
			"updatefields": [
				"name",
				"value",
				"icon"
			],
			"fields": [
				{
					"name": "name",
					"label": "Név",
					"_id": "item_1683630795583",
					"helper": "Név",
					"type": "text"
				},
				{
					"label": "Érték",
					"type": "number",
					"_id": "item_1683630818118",
					"helper": "Érték",
					"name": "value"
				},
				{
					"label": "Szimbólum",
					"type": "img",
					"name": "icon",
					"helper": "Szimbólum",
					"_id": "item_1683630854532"
				}
			],
			"title_create": "Új elem",
			"indexFields": [
				"name",
				"value",
				"icon"
			],
			"_id": "item_1683630902584"
		}
	],
	"meta": [
		"constants"
	],
	"updatedAt": {
		"seconds": 1683631889,
		"nanoseconds": 755000000
	},
	"indexFields": [
		"name", "elemek"
	],
	"title_index": "Skálák",
	"id": "scales"
}
export default table