import pages_admin from './pages/pages_admin'
import pages_group from './pages/pages_group'
import pages_system from './pages/pages_system'
import pages_test from './pages/pages_test'

export const pages = {
    admin: pages_admin,
    group: pages_group,
    system: pages_system,
    test: pages_test
}