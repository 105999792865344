import React from 'react';
import * as Miu from '@mui/material';
import styled from 'styled-components';
import Main from '../layouts/Main/Main';
import Mobile from '../layouts/Main/Mobile';
import * as custom from '../layouts/index'

const CHtmlElement = ({ children, app, layout, view = 'desktop', ...params }) => {
    const layoutview = layout[view];
    const type = layoutview.type;
    let selector = '';
    switch (type) {
        case 'html':
            selector = layoutview.selector
            break;
        case 'custom':
            selector = custom[layoutview.selector]
            break;
        case 'miu':
            selector = Miu[layoutview.selector]
            break;
        default:
            selector = custom[layoutview.selector]
            break;
    }
    const declarations = layoutview.declarations;
    const StyledComponent = styled(selector)(declarations);
    return (
        <>
            <StyledComponent app={app}>
                {children}
            </StyledComponent>
        </>
    );
};

export default CHtmlElement;