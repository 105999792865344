import React from 'react';
import { Drawer, IconButton } from '@mui/material';
import { Table, TableContainer, TableHead, TableBody, TableCell, TableRow, Paper } from '@mui/material';

import ProcessButton from '../ProcessButton';
import JsonNodeEditorField from './components/components/JsonNodeEditorField';
import DynamicForm from './DynamicForm';
const CPropertyBar = ({
    main,
    node,
    nodes,
    createNode,
    updateNode,
    deleteNode,
    active,
    setActive,
    store,
    setStore,
}) => {
    const activeNode = nodes[active];
    console.log('activeNode', activeNode)
    console.log('STORE', store)
    const deleteN = () => {
        deleteNode(active);
    }
    return (
        <>
            <JsonNodeEditorField value='zsolt' saveValue={(x) => console.log(x)} />
            <JsonNodeEditorField value='zsolt' saveValue={(x) => console.log(x)} />
            <JsonNodeEditorField value='zsolt' saveValue={(x) => console.log(x)} />
            <JsonNodeEditorField value='zsolt' saveValue={(x) => console.log(x)} />

            <h3>Store</h3>
            {Object.keys(store).map((key, index) => (
                (
                    <TableRow>
                        <TableCell sx={{ height: 20 }}>
                            {key}
                        </TableCell >
                        <TableCell sx={{ height: 20 }}>
                            {store[key]}
                        </TableCell>
                    </TableRow>
                )))
            }
            <h3>Komponens</h3>
            {activeNode &&
                <>
                    Komponens:{activeNode.name}<br></br>
                    id:{activeNode.id}
                    <div className={'rightpanel3'}>
                        {activeNode && (
                            <><ProcessButton
                                name={'AccessAlarm'}
                                icon={'AccessAlarm'}
                                fontSize={"medium"}
                                color="#000"
                            // disabled={status !== 'run'}
                            // onClick={(t) => updateNode(activeNode.id, {...activeNode.properties, _input:{...activeNode.properties._input, }})}
                            />
                                <ProcessButton
                                    name={'AccessAlarm'}
                                    icon={'AccessAlarm'}
                                    fontSize={"medium"}
                                    color="#000"
                                    // disabled={status !== 'run'}
                                    onClick={(t) => activeNode.update('width', '200px')}
                                />
                                {activeNode &&
                                    <ProcessButton
                                        name={'Delete'}
                                        icon={'Delete'}
                                        fontSize={"medium"}
                                        color="#000"
                                        // disabled={status !== 'run'}
                                        onClick={() => deleteN()}
                                    />}
                            </>
                        )}

                    </div>
                </>}
            {activeNode && activeNode.properties && activeNode.properties._input &&
                <>
                    <DynamicForm component={activeNode} updateNode={updateNode} />
                    <TableContainer style={{ width: '300px' }}>
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Tulajdonság
                                    </TableCell>
                                    <TableCell>
                                        Érték
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* PROPS */}
                                {activeNode && activeNode.properties &&
                                    Object.keys(activeNode.properties).map((name, index) => (
                                        typeof activeNode.properties[name] !== 'object' &&
                                        <TableRow sx={{ height: 20, '&:last-child td, &:last-child th': { border: 1 } }} key={index} >
                                            <TableCell sx={{ height: 20 }}>
                                                {name}
                                            </TableCell >
                                            <TableCell sx={{ height: 20 }}>
                                                {activeNode.properties[name]}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {/* INPUTS */}
                                {activeNode && activeNode.properties && activeNode.properties._input &&
                                    Object.keys(activeNode.properties._input).map((name, index) => (

                                        <TableRow style={{ backgroundColor: '#dfd', height: 10, '&:last-child td, &:last-child th': { border: 1 } }} key={index} >
                                            <TableCell >
                                                {name}
                                            </TableCell >
                                            <TableCell >
                                                {typeof activeNode.properties._input[name] !== 'object' ? activeNode.properties._input[name] : activeNode.properties._input[name]['value']}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {/* EVENTS */}
                                {activeNode && activeNode.properties && activeNode.properties._events &&
                                    Object.keys(activeNode.properties._events).map((name, index) => (

                                        <TableRow style={{ backgroundColor: '#f44', height: 10, '&:last-child td, &:last-child th': { border: 1 } }} key={index} >
                                            <TableCell >
                                                {name}
                                            </TableCell >
                                            <TableCell >
                                                {typeof activeNode.properties._events[name] !== 'object' ? activeNode.properties._events[name] : activeNode.properties._events[name]['value']}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>}
        </>
    );
};

export default CPropertyBar;