import React from 'react';
import { getProp, getPropArray } from '../../../Utility';

const FSPRowFieldSelect = ({ value, field, valuesLabel }) => {
    const label = getPropArray(field, ['values', value, valuesLabel]);
    return (
        <>
            {label}
        </>
    );
};

export default FSPRowFieldSelect;