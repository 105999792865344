export const table =
{
	"type": "array",
	"indexFields": [
		"name"
	],
	"updatefields": [
		"name",
		"description"
	],
	"meta": [],
	"fields": [
		{
			"type": "text",
			"required": "true",
			"indexFields": [],
			"updatefields": [],
			"fields": {},
			"name": "name",
			"helper": "Név",
			"label": "Név",
			"_id": "item_1699979903629"
		},
		{
			"type": "text",
			"required": "true",
			"indexFields": [],
			"updatefields": [],
			"fields": {},
			"name": "description",
			"helper": "Leírás",
			"label": "Leírás",
			"_id": "item_1699979923675"
		}
	],
	"id": "questionnaireBundles",
	"_id": "questionnaireBundles",
	"name": "Kérdőív gyűjtemények",
	"icon": "Collections",
	"collection": "questionnaireBundles",
	"title_index": "Kérdőív gyűjtemények",
	"title_create": "Új gyűjtemény",
	"title_update": "Gyűjtemény módosítása",
	"ui": "general"
}
export default table