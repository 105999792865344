import { useState } from 'react';
import { isMobile } from 'react-device-detect';

const EnvironmentVariables = ({
    setImages
}) => {

    const [environmentVariables, setEnvironmentVariables] = useState({
        view: isMobile ? 'mobile' : 'desktop',
        selectedApp: 'admin'
    });

    const setEnvironmentVariable = ({ key, value }) => {
        setEnvironmentVariables((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const deleteAllEnvironmentVariable = () => {
        setEnvironmentVariables((prevState) => ({ view: isMobile ? 'mobile' : 'desktop' }));
        setImages([]);
    };

    const deleteEnvironmentVariable = ({ key }) => {

        console.log('LEEEEE deleteEnvironmentVariable key', key);
        setEnvironmentVariables((prevState) => {
            const { [key]: deleted, ...rest } = prevState;
            console.log('LEEEEE deleteEnvironmentVariable rest', rest);
            return rest;
        });
    }

    return {
        environmentVariables,
        setEnvironmentVariable,
        setEnvironmentVariables,
        deleteAllEnvironmentVariable,
        deleteEnvironmentVariable
    };
};

export default EnvironmentVariables;