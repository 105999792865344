import * as Icons from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';

export const DynamicFaIcon = ({ name, color = 'primary', title = '', fontSize = 'medium' }) => {
    const IconComponent = Icons[name];
    if (!IconComponent) { // Return a default one
        return
        <Tooltip title={title}>
            <Icons.Abc />;
        </Tooltip>
    }

    return <IconComponent />;
};
