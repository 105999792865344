export const table =
{
	"fields": [
		{
			"name": "name",
			"type": "text",
			"helper": "name",
			"_id": "item_1682450113936",
			"label": "name"
		},
		{
			"label": "values",
			"type": "json",
			"_id": "item_1682450169284",
			"name": "values",
			"title_index": "Értékek",
			"updatefields": [
				"value",
				"label"
			],
			"title_create": "Új érték",
			"title_update": "Érték módosítása",
			"fields": [
				{
					"helper": "value",
					"type": "text",
					"name": "value",
					"_id": "item_1682450148671",
					"label": "value"
				},
				{
					"type": "text",
					"helper": "label",
					"label": "label",
					"name": "label",
					"_id": "item_1682450166089"
				}
			],
			"indexFields": [
				"label",
				"value"
			],
			"helper": "values"
		},
		{
			"helper": "id",
			"name": "id",
			"label": "id",
			"type": "text",
			"_id": "item_1682790626718"
		}
	],
	"_id": "constants",
	"updatefields": [
		"name",
		"id",
		"values"
	],
	"name": "Konstansok",
	"icon": "EmojiSymbols",
	"title_index": "Konstansok",
	"title_update": "Konstans módosítása",
	"collection": "constants",
	"title_create": "Új konstans",
	"type": "array",
	"meta": [],
	"updatedAt": {
		"seconds": 1684445324,
		"nanoseconds": 6000000
	},
	"indexFields": [
		"name"
	],
	"id": "constants"
}
export default table