import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AppBar, Button, Drawer, Stack } from '@mui/material';
import LayoutSelect from './LayoutSelect';
import Profile from './Profile';
import { Logout } from '@mui/icons-material';
import AppSelect from './AppSelect';
import { signOut } from 'firebase/auth';
import { Context2 as Context } from '../../services/fbcontext';
import { auth } from '../../firebase';
import { Navigate, useNavigate } from 'react-router-dom';
import MobileMenu from './MobileMenu';
import SubscriptionsCleaner from '../../common/SubscriptionsCleaner/SubscriptionsCleaner';

const MobileAppBar = ({ app }) => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const { deleteAllEnvironmentVariable } = React.useContext(Context);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (
        <>
            <AppBar position="fixed" open={true}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                    // sx={{
                    //     marginRight: 5,
                    //     ...(open && { display: 'none' }),
                    // }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        PR
                    </Typography>
                    {/* <Stack direction="row" spacing={4}>
                        <AppSelect app={app} />
                        <LayoutSelect />
                    </Stack> */}
                    <SubscriptionsCleaner showLabel={false} />
                    <Profile />
                    <Button
                        color="inherit"
                        onClick={() => {
                            console.log("start logout");

                            signOut(auth).then(() => {
                                deleteAllEnvironmentVariable();
                                navigate("/signin");
                            })

                        }}
                    >

                        <Logout />
                    </Button>
                </Toolbar>
            </AppBar>
            <MobileMenu
                app={app}
                handleClose={handleDrawerClose}
                open={open} />
        </>
    );
};

export default MobileAppBar;