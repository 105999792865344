export const table =
{
	"collection": "qtemplates",
	"title_create": "Sablon készítése",
	"name": "qtemplates",
	"meta": [
		"constants",
		"labels",
		"questions",
		"scales",
		"projects"
	],
	"type": "array",
	"fields": [
		{
			"indexFields": [],
			"helper": "Név",
			"required": "true",
			"_id": "item_1692190140187",
			"fields": [],
			"label": "Név",
			"updatefields": [],
			"name": "name",
			"type": "text"
		},
		{
			"label": "Leírás",
			"required": "true",
			"_id": "item_1692190202114",
			"indexFields": [],
			"name": "description",
			"fields": [],
			"helper": "Leírás",
			"type": "text",
			"updatefields": []
		},
		{
			"fields": [],
			"updatefields": [],
			"label": "Útmutató",
			"helper": "Útmutató",
			"indexFields": [],
			"type": "text",
			"required": "true",
			"_id": "item_1692190221985",
			"name": "guide"
		},
		{
			"updatefields": [],
			"required": "true",
			"indexFields": [],
			"fields": [],
			"_id": "item_1692190248918",
			"type": "text",
			"label": "gdpr",
			"helper": "GDPR",
			"name": "gdpr"
		},
		{
			"type": "json",
			"_id": "item_1692259315509",
			"label": "Szakasz",
			"helper": "Szakasz",
			"updatefields": [
				"type",
				"guide",
				"questions",
				"scale",
				"questionare"
			],
			"name": "section",
			"title_create": "Új szakasz",
			"title_update": "Módosítás",
			"fields": {
				"name": {
					"valuesKey": "value",
					"helper": "Név",
					"_id": "item_1692259293150",
					"name": "name",
					"model": "constants",
					"valuesLabel": "label",
					"document": "4DWRGoUyKY0ogaCm3DVQ",
					"valuesField": "values",
					"type": "text",
					"label": "Név"
				},
				"guide": {
					"_id": "item_1692259399799",
					"label": "Útmutató",
					"helper": "Útmutató",
					"name": "guide",
					"type": "text"
				},
				"scale": {
					"model": "scales",
					"valuesLabel": "name",
					"_id": "item_1692259505737",
					"valuesKey": "id",
					"label": "Skála",
					"name": "scale",
					"helper": "Skála",
					"type": "document_select"
				},
				"questions": {
					"type": "json",
					"label": "Kérdések",
					"required": "false",
					"indexFields": [
						"question",
						"label"
					],
					"title_create": "Új kérdés",
					"updatefields": [
						"question",
						"label"
					],
					"helper": "Kérdések",
					"title_update": "Módosítás",
					"name": "questions",
					"fields": [
						{
							"type": "document_select",
							"valuesLabel": "name",
							"label": "Kérdés",
							"_id": "item_1692260962230",
							"valuesKey": "id",
							"name": "question",
							"model": "questions",
							"helper": "Kérdés"
						}
					],
					"title_index": "Kérdések"
				},
				"type": {
					"model": "constants",
					"valuesField": "values",
					"_id": "item_1692259454987",
					"label": "Típus",
					"type": "value_select_static",
					"required": "false",
					"valuesKey": "value",
					"helper": "Típus",
					"name": "type",
					"valuesLabel": "label",
					"document": "4DWRGoUyKY0ogaCm3DVQ"
				}
			},
			"title_index": "Szakasz",
			"required": "true",
			"indexFields": [
				"name",
				"type"
			]
		},
		{
			"helper": "Kitöltők köre",
			"updatefields": [],
			"fields": {},
			"label": "Kitöltők köre",
			"indexFields": [],
			"valuesKey": "value",
			"name": "scope",
			"required": "true",
			"type": "value_select_static",
			"document": "kerdoiv_scope",
			"valuesLabel": "label",
			"_id": "item_1694613665676",
			"model": "constants",
			"valuesField": "values"
		},
		{
			"updatefields": [],
			"valuesField": "values",
			"helper": "Értékeltek köre",
			"indexFields": [],
			"model": "constants",
			"valuesKey": "value",
			"document": "kerdoiv_szakasz_tipus",
			"required": "true",
			"name": "evaluate",
			"type": "value_select_static",
			"valuesLabel": "label",
			"fields": {},
			"label": "Értékeltek köre",
			"_id": "item_1694614096410"
		},
		{
			"required": "true",
			"fields": {},
			"name": "projects",
			"type": "document_multiselect",
			"valuesKey": "id",
			"indexFields": [],
			"_id": "item_1699195486527",
			"model": "projects",
			"helper": "Projektek",
			"valuesLabel": "name",
			"label": "Projektek",
			"updatefields": []
		}
	],
	"icon": "FeaturedPlayListOutlined",
	"_id": "qtemplates",
	"updatedAt": {
		"seconds": 1699195491,
		"nanoseconds": 767000000
	},
	"ui": "general",
	"title_update": "Sablon módosítása",
	"updatefields": [
		"name",
		"description",
		"guide",
		"gdpr",
		"section",
		"scope",
		"evaluate",
		"projects"
	],
	"title_index": "Kérdőív sablon",
	"indexFields": [
		"name",
		"section"
	],
	"id": "qtemplates"
}
export default table