import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Icon from '@mui/material/Icon';
import * as icons from '@mui/icons-material';

const IconAutocomplete = () => {
  const [selectedIcon, setSelectedIcon] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [iconOptions, setIconOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const filteredIcons = Object.keys(icons).filter(
      (icon) =>
        icon.toLowerCase().includes(inputValue.toLowerCase()) &&
        icon.length > 1
    );
    setIconOptions(filteredIcons.slice(0, 20));
    setIsLoading(false);
  }, [inputValue]);

  const handleIconChange = (event, value) => {
    setSelectedIcon(value);
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollHeight - scrollTop === clientHeight) {
      const nextIcons = Object.keys(icons).filter(
        (icon) =>
          icon.toLowerCase().startsWith(inputValue.toLowerCase()) &&
          icon.length > 1 &&
          !iconOptions.includes(icon)
      );
      const updatedIconOptions = [...iconOptions, ...nextIcons.slice(0, 20)];
      setIconOptions(updatedIconOptions);
    }
  };

  return (
    <Autocomplete
      value={selectedIcon}
      onChange={handleIconChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={iconOptions}
      renderInput={(params) => (
        <TextField {...params} label="Icon" variant="outlined" />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <Icon component={icons[option]} />
          {option}
        </li>
      )}
      ListboxProps={{ onScroll: handleScroll }}
      loading={isLoading}
      loadingText="Loading..."
    />
  );
};

export default IconAutocomplete;