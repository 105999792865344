import React from 'react';
import { getProp, getPropArray, getPropArrayParams } from '../../../Utility';

const FSPRowFieldValueSelectStatic = ({
    model,
    document,
    valuesField,
    valuesKey,
    value,
    valuesLabel,
    metaArray
}) => {
    // const { valuesKey, valuesField, document, valuesLabel, model } = fields;

    const data = {
        model: model,
        document: document,
        valuesField: valuesField,
        valuesKey: valuesKey,
        value: value,
        valuesLabel: valuesLabel
    }
    const label = getPropArrayParams(metaArray, data,
        [
            'model',      // const _model = getProp(metaArray, model);
            'document',   // const _document = getProp(_model, document);
            'valuesField',// const _field = getProp(_document, valuesField);
            ['valuesKey'],// const _values = _field ? convertArrayById(_field, valuesKey) : null;
            'value',      // const _value = getProp(_values, value);
            'valuesLabel' // const label = getProp(_value, valuesLabel);
        ]);

    return (
        <>
            {label}
        </>
    );
};

export default FSPRowFieldValueSelectStatic;