import { Add, Upload } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React from 'react';
import { getProp } from '../Utility';

const FSPTitle = ({
    setShowNewDialog,
    title,
    setNewRecord,
    table,
    setShowImportDialog
}) => {
    const fields = getProp(table, 'fields');

    const setDefaults = () => {
        var values = {}
        if (typeof fields == 'object') {
            Object.keys(fields).forEach(key => {
                var field = fields[key]
                if (field.default) {
                    values[field.name] = field.default;
                }
            });
        }
        return values;
    }
    return (
        <Box display="flex" p={1}>
            <Box p={1} flexGrow={1}>
                <h1>{title}</h1>
            </Box>
            <Box p={1}>
                <Button
                    type='submit'
                    color="primary"
                    onClick={(event) => {
                        event.preventDefault();
                        const r = setDefaults();
                        setNewRecord(r);
                        setShowNewDialog(true);
                    }}>
                    <Add />
                </Button>
                <Button
                    type='submit'
                    color="primary"
                    onClick={(event) => {
                        event.preventDefault();
                        // const aaa = setDefaults();
                        // console.log('bbbbaaaaa', aaa);
                        // setNewRecord(aaa);
                        setShowImportDialog(true);
                    }}>
                    <Upload />
                </Button>
            </Box>
        </Box>
    );
};

export default FSPTitle;