export const table =
{
    "name": "Komponensek",
    "title_create": "Új komponens",
    "updatedAt": {
        "seconds": 1685015869,
        "nanoseconds": 29000000
    },
    "title_index": "Komponensek",
    "updatefields": [
        "name",
        "label",
        "icon",
        "description",
        "data"
    ],
    "icon": "Input",
    "indexFields": [
        "name",
        "label",
        "icon",
        "description"
    ],
    "fields": [
        {
            "_id": "item_1685014675650",
            "updatefields": [],
            "fields": [],
            "name": "name",
            "indexFields": [],
            "type": "text",
            "label": "name",
            "helper": "name",
            "required": "true"
        },
        {
            "helper": "label",
            "indexFields": [],
            "name": "label",
            "fields": [],
            "required": "true",
            "label": "label",
            "updatefields": [],
            "_id": "item_1685014684729",
            "type": "text"
        },
        {
            "required": "true",
            "helper": "icon",
            "name": "icon",
            "indexFields": [],
            "type": "icon",
            "_id": "item_1685014711280",
            "fields": [],
            "label": "icon",
            "updatefields": []
        },
        {
            "_id": "item_1685014848366",
            "updatefields": [],
            "required": "true",
            "helper": "description",
            "label": "description",
            "fields": [],
            "indexFields": [],
            "type": "text",
            "name": "description"
        },
        {
            "type": "jsoninput",
            "required": "true",
            "indexFields": [],
            "updatefields": [],
            "fields": [],
            "name": "data",
            "helper": "data",
            "label": "data",
            "_id": "item_1685037582196"
        }
    ],
    "meta": [],
    "_id": "components2",
    "collection": "components2",
    "title_update": "Komponens módosítása",
    "type": "array",
    "id": "components2"
}
export default table