import React, { useEffect, useState } from 'react';

const Loadings = () => {
    const [loadings, setLoadings] = useState({})
    const setLoading = ({ key, status }) => {
        setLoadings((prevState) => ({
            ...prevState,
            [key]: status,
        }));
    };

    useEffect(() => {
        console.log('Loadings', loadings)
    }, [loadings]);

    return { loadings, setLoading };
};

export default Loadings;