import React, { useEffect, useState } from 'react';

const Model = ({ AppDataArray, environmentVariables }) => {
    const [label, setLabel] = useState('');
    const [view, setView] = useState('desktop');

    const refreshLabel = () => {
        if (environmentVariables && AppDataArray &&
            (environmentVariables.selectedApp != null)) {
            console.log(AppDataArray);
            setLabel(
                AppDataArray[environmentVariables.selectedApp].label
            )
        }
    }
    const refreshView = () => {
        if (environmentVariables)
            setView(environmentVariables.view)
        else
            setView('desktop');
    }

    return { label, refreshLabel, view, refreshView };;
};

export default Model;