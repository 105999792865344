export const table =
{
	// "ui": "user_widget",
	"indexFields": [
		"authorId",
		"template"
	],
	"title_update": "Módosítás",
	"collection": "filledquestionnaires",
	"icon": "Create",
	"meta": [
		"qtemplates",
		"users"
	],
	"updatefields": [
		"name"
	],
	"type": "array",
	"updatedAt": {
		"seconds": 1694772471,
		"nanoseconds": 708000000
	},
	"_id": "filledquestionnaires",
	"name": "Kitöltött kérdőívek",
	"fields": [
		{
			"name": "authorId",
			"valuesKey": "id",
			"_id": "item_1694772193400",
			"model": "users",
			"indexFields": [],
			"type": "document_select",
			"valuesLabel": "email",
			"helper": "authorId",
			"updatefields": [],
			"fields": {},
			"required": "true",
			"label": "authorId"
		},
		{
			"fields": {},
			"required": "true",
			"helper": "template",
			"_id": "item_1694772311721",
			"name": "template",
			"label": "template",
			"model": "qtemplates",
			"type": "document_select",
			"updatefields": [],
			"valuesLabel": "name",
			"valuesKey": "id",
			"indexFields": []
		},
		{
			"indexFields": [],
			"required": "true",
			"_id": "item_1694772397899",
			"label": "name",
			"name": "name",
			"type": "text",
			"helper": "name",
			"updatefields": [],
			"fields": {}
		}
	],
	"title_index": "Kitöltött kérdőívek",
	"title_create": "Új",
	"id": "filledquestionnaires"
}
export default table