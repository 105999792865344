import React from 'react';
import FSPRow from '../FSPRow/FSPRow';
import CTableBody from '../Layout/CTableBody';

const FSPTableRows = ({
    model,
    table,
    setNewRecord,
    setShowNewDialog,
    setShowDeleteDialog,
    fieldsArray,
    metaArray,
    page,
    rowsPerPage,
    view
}) => {
    return (
        <CTableBody view={view}>
            {model &&
                Object.keys(model).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((record, index) => (
                        <FSPRow
                            key={index}
                            record={model[record]}
                            table={table}
                            metaArray={metaArray}
                            fieldsArray={fieldsArray}
                            setNewRecord={setNewRecord}
                            setShowNewDialog={setShowNewDialog}
                            setShowDeleteDialog={setShowDeleteDialog}
                            view={view}
                        />
                    ))
            }
        </CTableBody>
    );
};

export default FSPTableRows;