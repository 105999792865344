export const table =
{
	"name": "Személyes adat kérdések",
	"ui": "general",
	"_id": "personals",
	"collection": "personals",
	"title_create": "Új kérdés",
	"indexFields": [
		"name"
	],
	"updatedAt": {
		"seconds": 1692189876,
		"nanoseconds": 792000000
	},
	"fields": [
		{
			"_id": "item_1692189832925",
			"name": "name",
			"helper": "Kérdés",
			"updatefields": [],
			"indexFields": [],
			"required": "true",
			"type": "text",
			"fields": [],
			"label": "Kérdés"
		}
	],
	"icon": "ContactPageOutlined",
	"title_update": "Kérdés módosítása",
	"type": "array",
	"meta": [
		"constants"
	],
	"updatefields": [
		"name"
	],
	"title_index": "Személyes adat kérdések",
	"id": "personals"
}
export default table