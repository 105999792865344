import React, { useEffect, useState, useContext } from 'react';
import { getUserId } from '../../../../services/GeneralService';
import { deleteObject, getDownloadURL, getMetadata, getStorage, listAll, ref, uploadBytes } from 'firebase/storage';
import { Context2 as Context } from "../../../../services/fbcontext";

const FSPRowFieldImgStore = ({ value, bucket }) => {
    const [imageUrl, setImageUrl] = useState('');
    const { images, setImage } = useContext(Context);

    const update = (storageRef) => {
        getDownloadURL(storageRef)
            .then((url) => {
                setImageUrl(url);
            })
            .catch((error) => {
                console.error('Hiba történt a kép letöltésekor:', error);
            });
    }
    useEffect(() => {
        // const storageRef = ref(getStorage(), bucket + '/' + value);
        // update(storageRef);
        setImage({ bucket: bucket, value: value });
    }, [value]);

    return (
        <>
            {<img src={images[bucket + '/' + value]} style={{ maxWidth: "150px", maxHeight: "150px" }} alt="Profilkép" />}
        </>
    );
};

export default FSPRowFieldImgStore;