import React, { useEffect, useState } from 'react';
import { getItemId } from '../Utility';

const FSPCrudModel = ({ table }) => {
    const [newRecord, setNewRecord] = useState(table && table.initial ? table.initial : {});
    const [showNewDialog, setShowNewDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(null);
    const [showImportDialog, setShowImportDialog] = useState(null);
    const [page, setPage] = useState(0);
    const [wkey, setKey] = useState(getItemId());
    const [fieldsArray, setFieldsArray] = useState();
    const [errors, setErrors] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setNewRecord({ ...newRecord, [name]: value });

        let e = errors;
        if (event.target.value == '' && fieldsArray[name].required) {
            e[event.target.name] = 'Nem lehet üres';
        } else {
            delete e[event.target.name];
        }
        setErrors(e);
    }

    const handleChangeArray = (name, value) => {
        if (Array.isArray(value)) {
            setNewRecord({ ...newRecord, [name]: value });
        } else {
            setNewRecord({ ...newRecord, [name]: value });
        }

    }
    const handleChangeTable = (name, value) => {
        setNewRecord({ ...newRecord, [name]: value });
    }

    const handleChangePage = React.useCallback((event, newPage) => {
        setPage(newPage);
    });

    const handleChangeRowsPerPage = React.useCallback((event) => {
        setRowsPerPage(parseInt(event.target.value));
    });

    return {
        newRecord, setNewRecord,
        showNewDialog, setShowNewDialog,
        showDeleteDialog, setShowDeleteDialog,
        showImportDialog, setShowImportDialog,
        page, setPage,
        wkey, setKey,
        fieldsArray, setFieldsArray,
        errors, setErrors,
        rowsPerPage, setRowsPerPage,
        handleChange,
        handleChangeArray, handleChangeTable,
        handleChangePage,
        handleChangeRowsPerPage,
    }
};

export default FSPCrudModel;