import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MobileAppBar from './MobileAppBar';
import MobileNavigation from './MobileNavigation';
import { Paper } from '@mui/material';

export const Mobile = ({ children, app }) => {
    const ref = React.useRef(null);
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <MobileAppBar app={app} />
            <Box component="main" sx={{ top: 150, flexGrow: 1, p: 3 }}>
                {children}
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                    <MobileNavigation />
                </Paper>
            </Box>
        </Box>
    );
};

export default Mobile;