import React from 'react';
import { getProp } from '../../../Utility';

const FSPRowFieldDocumentMultiSelectElement = ({ option, label }) => {
    const name = getProp(option, label);
    return (
        <div>
            {name}
        </div>
    );
};

export default FSPRowFieldDocumentMultiSelectElement;