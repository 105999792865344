import table_admins from "./tables/table_admins"
import table_applications from "./tables/table_applications"
import table_constants from "./tables/table_constants"
import table_groups from "./tables/table_groups"
import table_tables from "./tables/table_tables"
import table_users from "./tables/table_users"
import table_teszt from "./tables/table_teszt"
import table_teszt5 from "./tables/table_teszt5"
import table_styles from "./tables/table_styles"
import table_scales from "./tables/table_scales"
import table_ui from "./tables/table_ui"
import table_images from "./tables/table_images"
import table_systems from "./tables/table_systems"
import table_components2 from "./tables/table_components"
import table_groupmembers from "./tables/table_groupmembers"
import table_groupsubscribes from "./tables/table_groupsubscribes"
import table_labels from "./tables/table_labels"
import table_personals from "./tables/table_personals"
import table_qtemplates from "./tables/table_qtemplates"
import table_questions from "./tables/table_questions"
import table_questionnaires from "./tables/table_questionnaires"
import table_filledquestionnaires from "./tables/table_filledquestionnaires"
import table_evaluated from "./tables/table_evaluated"
import table_evaluatedmonth from "./tables/table_evaluatedmonth"
import table_projects from "./tables/table_projects"
import table_questionnaireBundles from "./tables/table_questionnairebundles"

// import table_tables_generated from './table_tables_generated.json'
export const tables = {
    users: table_users,
    groups: table_groups,
    groupadmins: table_admins,
    applications: table_applications,
    tables: table_tables,
    teszt: table_teszt,
    teszt5: table_teszt5,
    // le_tables_generated:// table_tables_generated,
    constants: table_constants,
    styles: table_styles,
    scales: table_scales,
    images: table_images,
    systems: table_systems,
    ui: table_ui,
    components2: table_components2,
    groupmembers: table_groupmembers,
    groupsubscribes: table_groupsubscribes,
    labels: table_labels,
    personals: table_personals,
    qtemplates: table_qtemplates,
    questions: table_questions,
    questionnaires:table_questionnaires,
    filledquestionnaires:table_filledquestionnaires,
    evaluated:table_evaluated,
    evaluatedMonth:table_evaluatedmonth,
    projects:table_projects,
    questionnaireBundles: table_questionnaireBundles
}