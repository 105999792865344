import React from 'react';
import * as Miu from '@mui/material';
import { Mail } from '@mui/icons-material';
import FSPIcon from '../../layouts/Main/FSPIcon';
import * as icons from '@mui/icons-material';

const DynamicFormPreview = ({ component, formValues }) => {
    const handleEvent = (eventName, ...params) => {
        // TODO: Kezelje az eseményt
    };

    const renderComponent = (componentData) => {
        const { name, props, children } = componentData;
        const runCode = ({ code }) => {
            console.log('code', code)
            var Func1 = new Function(code);
            Func1();
        }

        const componentProps = props
            ? Object.values(props).reduce((acc, prop) => {
                console.log('props', prop)
                if (prop && prop.name && formValues[prop.name] !== undefined && formValues[prop.name] !== null) {
                    if (prop.type === 'bool') {
                        acc[prop.name] = Boolean(formValues[prop.name]);
                    }
                    if (prop.type === 'function') {
                        // acc[prop.name] = runCode({ code: formValues[prop.name] });
                    } else if (prop.name === 'icon') {
                        acc[prop.name] = formValues['icon'] && (icons[formValues['icon']]) ? FSPIcon({ icon: formValues['icon'] }) : null;
                    } else if (prop.name === 'color') {
                        acc[prop.name] = formValues[prop.name];
                    } else {
                        acc[prop.name] = formValues[prop.name];
                    }
                }
                return acc;
            }, {})
            : {};

        const componentChildren = children ? children.map(child => renderComponent(child)) : null;

        const Component = Miu[name];
        console.log('componentProps', componentProps)
        console.log('formValues', formValues)
        const Component2 = Component ? (
            React.createElement(
                Component,
                {
                    ...componentProps,
                    // style: { style2 },
                    // ref: { dropRef },
                    onClick: (e) => formValues && formValues.onClick ? runCode({ code: formValues.onClick }) : runCode({ code: '' }),
                },
                componentChildren
            )) : null;
        // const componentChildren2 = {...componentChildren, teszt:    'Teszt'};
        console.log('componentChildren',componentChildren);
        return Component ? (
            React.createElement(
                Component,
                {
                    ...componentProps,
                    // style: { style2 },
                    // ref: { dropRef },
                    onClick: (e) => formValues && formValues.onClick ? runCode({ code: formValues.onClick }) : runCode({ code: '' }),
                },
                componentChildren
            )
            // <Component {...componentProps}>
            //     {componentChildren}
            //     TESZT
            // </Component>
        ) : null;
    };

    return <div>{renderComponent(component)}</div>;
};

export default DynamicFormPreview;
