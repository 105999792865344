import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import { Stack } from '@mui/material';
import AppSelect from './AppSelect';
import LayoutSelect from './LayoutSelect';
import { Close, Person } from '@mui/icons-material';

const MobileMenu = ({ app, open, handleClose }) => {
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');

    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value,
        );
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    return (
        <Dialog
            fullScreen
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Beállítások</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    PR-VET alkalmazások és nézetek
                    <Stack direction="row" spacing={4}>
                        <AppSelect
                            app={app}
                            orientation={"vertical"}
                            showLabel={true}
                        />
                    </Stack>
                </DialogContentText>
                <DialogContentText>
                    Nézetek
                    <Stack direction="row" spacing={4}>
                        <LayoutSelect />
                    </Stack>
                </DialogContentText>
                <DialogContentText>
                    Nézetek
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <Person />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Profil" secondary="Alapadatok beállítása" />
                    </ListItem>
                </DialogContentText>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}><Close /></Button>
            </DialogActions>
        </Dialog>
    );
};

export default MobileMenu;