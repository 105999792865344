import React from 'react';
import FSPTableRows from './FSPTableRows';
import FSPTableFooter from './FSPTableFooter';
import FSPTableHeader from './FSPTableHeader';
import CTable from '../Layout/CTable';

const FSPTable = (
    {
        model,
        table,
        metaArray,
        setNewRecord,
        setShowNewDialog,
        setShowDeleteDialog,
        fieldsArray,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        view
    }) => {
    return (
        <CTable view={view} size="small" aria-label="table">
            {/* <FSPTableHeader
                fieldsArray={fieldsArray ?? []}
                table={table}
                view={view}
            /> */}
            <FSPTableRows
                model={model}
                table={table}
                metaArray={metaArray}
                fieldsArray={fieldsArray}
                setNewRecord={setNewRecord}
                setShowNewDialog={setShowNewDialog}
                setShowDeleteDialog={setShowDeleteDialog}
                rowsPerPage={rowsPerPage}
                page={page}
                view={view}
            />
            <FSPTableFooter
                rowsPerPage={rowsPerPage}
                model={model}
                handleChangePage={(e, a) => handleChangePage(e, a)}
                handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
                view={view}
                page={page}
            />
        </CTable>
    );
};

export default FSPTable;