import styled from "@emotion/styled";

export const CardContainer = styled('div')({
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    padding: '16px',
    float: 'left',
    width: '300px',
    minHeight: '200px'
});

export const CardTitle = styled('h2')({
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '16px',
});

export const CardBody = styled('div')({
    fontSize: '1rem',
    lineHeight: '1.5',
});

export const CardsContainer = styled('div')({
    clear: 'both'
});
