import { field_json } from "../fields/field_json"
import { document_multiselect } from "../fields/document_multiselect"
import { value_mutiselect_local } from "../fields/value_mutiselect_local"
import { field_text } from "../fields/field_text"
import subtable_tables_fields from "./subtable_tables_fields"
import subtable_tables_fields2 from "./subtable_tables_fields2"
import { field_json2 } from "../fields/field_json2"
import { value_select_static } from "../fields/value_select_static"
import { field_icon } from "../fields/field_icon"
import { document_select } from "../fields/document_select"

export const table_tables =
{
    "collection": "tables",
    "icon": "TableView",
    "meta": [
        "tables", "constants", "images", "ui"
    ],
    "title_index": "Táblák",
    "title_create": "Új tábla",
    "title_update": "Tábla módosítása",
    "keyField": 0,
    "indexFields": [
        "collection",
        "name",
        "icon",
        "_id"
    ],
    "updatefields": [
        "type",
        "collection",
        "name",
        "indexFields",
        "updatefields",
        "meta",
        "title_index",
        "title_create",
        "title_update",
        "fields",
        "icon",
        "ui",
        "_id"
    ],
    "initial": {
        "name": null,
        "collection": null,
        "indexFields": [],
        "fields": [],
        "meta": []
    },
    "fields": [
        value_select_static({
            name: "type", label: "type(value_select_static)", required: true,
            model: "constants", valuesKey: "value", valuesLabel: "label",
            valuesField: "values", document: "tabla_tipusok", defaultValue: "array",
            autoHide: []
        }),
        field_text({ name: "id", label: "id", required: true }),
        field_text({ name: "_id", label: "_id", required: true }),
        field_text({ name: "name", label: "name", required: true }),
        field_icon({ name: "icon", label: "icon", required: true }),
        field_text({ name: "collection", label: "collection", required: true }),
        field_text({ name: "title_index", label: "title_index", required: true }),
        field_text({ name: "title_create", label: "title_create", required: true }),
        field_text({ name: "title_update", label: "title_update", required: true }),
        value_mutiselect_local({
            name: "indexFields", label: "indexFields", required: true,
            model: "fields", valuesKey: "name", valuesLabel: "name", defaultValue: [],
            autoHide: true
        }),
        value_mutiselect_local({
            name: "updatefields", label: "updatefields", required: true,
            model: "fields", valuesKey: "name", valuesLabel: "name", defaultValue: [],
            autoHide: true
        }),
        document_multiselect({
            name: "meta", label: "Metaadatok", required: true,
            model: "tables", valuesKey: "id", valuesLabel: "name", defaultValue: [],
            autoHide: true
        }),
        document_select({
            name: "ui", label: "ui(document_select)", required: false,
            model: "ui", valuesKey: "id", valuesLabel: "name",
            autoHide: []
        }),
        field_json({
            name: "fields", label: "Mezők",
            defaultValue: [],
            required: true, table: subtable_tables_fields,
            autoHide: ['json']
        }),
        field_json2({
            name: "fields", label: "Mezők",
            defaultValue: {},
            required: true, table: subtable_tables_fields,
            autoHide: ['array']
        })
    ]
}

export default table_tables