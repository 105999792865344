import React from 'react';
import CHtmlElement from '../../common/CHtmlElement';
import { Box, ButtonBase, Card, CardContent, CardMedia, IconButton, Typography } from '@mui/material';
import { Abc, PlayArrow, SkipNext, SkipPrevious } from '@mui/icons-material';
import { useTheme } from 'styled-components';
// import './style.css';
// import './style2.css';
import ProfileCard from './ProfilCard';
import Loadings from '../../common/SubscriptionsCleaner/Loadings';
import IconAutocomplete from '../../common/IconAutoComplete';
import DynamicForm from '../../common/miu/DynamicForm';
import { BottomNavigation } from '../../common/miu/components/BottomNavigation';
import { Button } from '../../common/miu/components/Button';
import Badge from '../../common/miu/components/Badge';
import BottomNavigationAction from '../../common/miu/components/BottomNavigationAction';
import { convertArrayById, getItemId } from '../../common/Utility';
import ComponentEditor from '../../common/ComponentEditor.js/ComponentEditor';
import MainLayout from '../../layouts/Main/MainLayout';
import EditorLayout from '../../layouts/Main/EditorLayout';

const layout = {
    "mobile": {
        "type": "custom", //miu, html, custom
        "selector": "Mobile",
        "declarations": {
            "backgroundColor": '#f00',
        }
    },
    "desktop": {
        "type": "custom",//miu, html, custom
        "selector": "Main",
        "declarations": {
            "backgroundColor": '#0f0',
        }
    }
}
const View = ({ children, app, loading, loading_backup,
    user, user_backup, view = 'desktop', ...params
}) => {

    return (
        <EditorLayout app={app}>
            <ComponentEditor />
            {/* <div className={"container"}>
                <div className={"tree"}>Tree</div>
                <div className={"content"}>Content</div>
                <div className={"properties"}>Properties</div>
            </div> */}
        </EditorLayout>
    );
};

export default View;