export const table =
{
	"type": "array",
	"title_create": "Új admin",
	"_id": "groupadmins",
	"title_update": "Admin szerkesztése",
	"title_index": "Csoport adminok",
	"name": "Adminisztrátorok",
	"collection": "groupadmins",
	"meta": [
		"groups",
		"users"
	],
	"indexFields": [
		"userId",
		"groupId"
	],
	"ui": "group_widget",
	"icon": "AdminPanelSettingsOutlined",
	"updatefields": [
		"userId",
		"groupId"
	],
	"fields": [
		{
			"valuesLabel": "email",
			"model": "users",
			"helper": "Felhasználó",
			"type": "document_select",
			"name": "userId",
			"_id": "item_1682455076938",
			"label": "Felhasználó",
			"valuesKey": "id",
			"required": "true"
		},
		{
			"valuesKey": "id",
			"required": "true",
			"valuesLabel": "name",
			"type": "document_select",
			"label": "Csoport",
			"_id": "item_1682455116407",
			"helper": "Csoport",
			"name": "groupId",
			"model": "groups"
		}
	],
	"updatedAt": {
		"seconds": 1693906697,
		"nanoseconds": 768000000
	},
	"id": "groupadmins"
}
export default table