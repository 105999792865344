import { useEffect } from 'react';
import Loadings from './firestore/states/loadings';
import Subscriptions from './firestore/states/subscriptions';
import ImagesBucket from './firestore/states/imagesBucket';
import EnvironmentVariables from './firestore/states/environmentVariables';

import Meta from './firestore/meta';
import SubscribesEV from './firestore/subscribesEV';
import QueriesEV from './firestore/queriesEV';
import UnSubscriptions from './firestore/states/unSubscriptions';

const useFirestoreStore2 = () => {
    //STATES
    const { loadings, setLoading } = Loadings();

    const {
        unSubscriptions,
        addUnSubscription,
        deleteUnSubscription
    } = UnSubscriptions({ setLoading: setLoading });

    const {
        subscriptions,
        subscribeToCollection,
        unsubscribeFromCollection
    } = Subscriptions({
        setLoading: setLoading,
        deleteUnSubscription: deleteUnSubscription
    });

    const {
        images,
        setImage,
        setImages
    } = ImagesBucket();

    const {
        environmentVariables,
        setEnvironmentVariable,
        setEnvironmentVariables,
        deleteAllEnvironmentVariable,
        deleteEnvironmentVariable
    } = EnvironmentVariables({
        setImages: setImages
    });

    const {
        metaSubscriptions,
        subscribeToMetas,
        unsubscribeFromMeta,
        unsubscribeFromMetas,
        metaUnSubscriptions,
        addMetaUnSubscription,
        deleteMetaUnSubscription,
        addMetaUnSubscriptions
    } = Meta({ setLoading: setLoading });

    const {
        subscribeToCollectionEV,
        unsubscribeFromCollectionEV
    } = SubscribesEV({
        setLoading: setLoading,
        subscribeToCollection: subscribeToCollection,
        unsubscribeFromCollection: unsubscribeFromCollection,
        environmentVariables: environmentVariables,
        setEnvironmentVariable: setEnvironmentVariable,
        deleteEnvironmentVariable, deleteEnvironmentVariable
    });

    const {
        queryDocumentEV,
        queryDocumentsEV,
    } = QueriesEV({
        setLoading: setLoading,
        subscribeToCollection: subscribeToCollection,
        unsubscribeFromCollection: unsubscribeFromCollection,
        environmentVariables: environmentVariables,
        setEnvironmentVariable: setEnvironmentVariable,
    });


    // useEffect(() => {
    //     console.log('hoppacska1111111-cc', metaUnSubscriptions);
    // }, [metaUnSubscriptions]);

    return {
        // subscriptions
        subscriptions,
        subscribeToCollection,
        unsubscribeFromCollection,
        // meta subscribes
        metaSubscriptions,
        subscribeToMetas,
        unsubscribeFromMeta,
        unsubscribeFromMetas,
        // metaUnSubscriptions
        metaUnSubscriptions,
        addMetaUnSubscription,
        deleteMetaUnSubscription,
        addMetaUnSubscriptions,
        //EV
        environmentVariables,
        setEnvironmentVariable,
        deleteAllEnvironmentVariable,
        queryDocumentEV,
        queryDocumentsEV,
        subscribeToCollectionEV,
        unsubscribeFromCollectionEV,
        images,
        setImage,
        //loadings
        loadings,
        setLoading
    };
};

export default useFirestoreStore2;