import React, { useContext, useEffect, useState } from "react";
import View from "./View";
import Model from "./Model";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth_backup } from "../../firebase_backup";
import { auth, db } from "../../firebase";
import { Navigate, useParams } from 'react-router-dom'

export function QTemplate({ table, app }) {
    const Templates = Model({ table: 'qtemplates' });
    const Questions = Model({ table: 'questions' });
    const [user_backup, loading_backup, error_backup] = useAuthState(auth_backup);
    const [user, loading, error] = useAuthState(auth);
    const { id } = useParams()

    console.log('id',id);
    console.log('Templates',Templates);
    console.log('Questions',Questions);

    return (
        <>
            <View model={Templates.model} loading_backup={loading_backup} loading={loading} app={app} user={user} user_backup={user_backup}>

            </View>
            <View model={Questions.model} loading_backup={loading_backup} loading={loading} app={app} user={user} user_backup={user_backup}>

            </View>
        </>
    );
}

