import React, { useState } from 'react';
import * as Miu from '@mui/material';
import * as Icons from '@mui/icons-material'; import FSPIcon from '../../../../layouts/Main/FSPIcon';

const Model = ({
    data,
    node,
    setActive,
    store,
    setStore,
}) => {
    const onClick = (e) => {
        e.stopPropagation();
        setActive(node);
        if (data && data.properties && data.properties._events && data.properties._events.onClick &&
            typeof data.properties._events.onClick == 'string'
        ) {
            runCode({
                code: data.properties._events.onClick, //"var teszt = set('teszt', store.teszt+10);alert(store.teszt)",
                store: store,
                set: set
            })
        }
    }

    const set = (variable, value) => {
        var n = { ...store }
        n[variable] = value;
        setStore(n)
    }

    const runCode = ({ code, store, set }) => {
        var Func1 = new Function("store", "set", code);
        Func1(store, set);
    }

    const events = (e, code, store, set) => {
        runCode({
            code: code, //"var teszt = set('teszt', store.teszt+10);alert(store.teszt)",
            store: store,
            set: set
        })
    }

    const setParams = ({ properties }) => {
        var style = {};
        Object.keys(properties._input).forEach((key, index) => {
            if (properties._input[key].type === 'MUIComponent') {
                style[key] = React.createElement(
                    Miu[properties._input[key].component],
                    properties._input[key].params,
                    // properties._input[key].value
                    // <FSPIcon icon={'Group'} />
                    key === 'icon' ? <FSPIcon icon={properties._input[key].value} /> : properties._input[key].value
                )
            } else {
                if (properties._input[key]['data'] == 'store') {
                    style[key] = store[properties._input[key]['value']];
                } else {
                    style[key] = properties._input[key]['value'];
                }
            }
        });
        Object.keys(properties._events).forEach((key, index) => {
            style[key] = (e) => events(e, properties._events[key], store, set)
        });
        return style
    }

    const setEvents = ({ store, set, properties }) => {
        var style = {};
        if (properties && properties._events) {
            Object.keys(properties._events).forEach((key, index) => {
                style[key] = (e) => events(e, properties._events[key], store, set)
            });
        }
        return style
    }

    const setProps = ({ p, data }) => {
        var style = {};
        p.forEach((key, index) => {
            if (data && data[key])
                style[key] = data[key];
            if (data && data._input[key]) {
                if (key === 'icon') {
                    style[key] = Icons[data._input[key]['value']];
                } else {
                    style[key] = data._input[key]['value'];
                }
            }
        });
        return style
    }

    return {
        onClick, setParams, setProps, setEvents
    };
};

export default Model;