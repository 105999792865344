import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getUserId } from '../../../../services/GeneralService';
import { deleteObject, getDownloadURL, getMetadata, getStorage, listAll, ref, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { storage } from '../../../../firebase';

const FSPFieldImgStore = ({
    field,
    id,
    handleChangeArray,
    value
}) => {
    const [file, setFile] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [uuid, setUuid] = useState(value ? value : uuidv4());
    const type = field.store_image_type;
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const userId = getUserId();
        setFile(selectedFile);
        var docId = userId;
        switch (type) {
            case 'document':
                docId = id
                break;
            case 'profile':
                docId = userId
                break;
            case 'custom':
                docId = uuid;
                break;
        }
        if (type === 'custom' && docId === userId) {
            docId = uuidv4();
            setUuid(docId);
            handleChangeArray(field.name, docId);
        }
        const storageRef = ref(storage, field.bucket + '/' + docId);
        uploadBytes(storageRef, selectedFile)
            .then(() => {
                console.log('Profilkép sikeresen feltöltve', storageRef);
                handleChangeArray(field.name, docId);
                update(storageRef);
            })
            .catch((error) => {
                console.error('Hiba történt a profilkép feltöltésekor:', error);
            });
    };
    const update = (storageRef) => {
        getDownloadURL(storageRef)
            .then((url) => {
                setImageUrl(url);
            })
            .catch((error) => {
                console.error('Hiba történt a kép letöltésekor:', error);
            });
    }
    useEffect(() => {
        const storageRef = ref(storage, field.bucket + '/' + value);
        update(storageRef);
    }, [value, file]);

    return (
        <div>
            <div>
                {field.label}{value ? value : typeof value}--{uuid}
            </div>
            <input type="file" id="profileImage" accept="image/*" onChange={handleFileChange} required />
            {imageUrl && <img src={imageUrl} alt="Profilkép" style={{ maxWidth: "150px", maxHeight: "150px" }} />}
        </div>
    );
};

export default FSPFieldImgStore;