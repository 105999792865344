import { AccountCircle } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';

const Profile = () => {
    return (
        <Button
            color="inherit"
            onClick={() => {
                // signOut(auth).then(() => {
                //     navigate("/signin");
                // })

            }}
        >
            <AccountCircle />
        </Button>
    );
};

export default Profile;