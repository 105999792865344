import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import FSPFieldJsonInput from './FSPForm/FSPField/FSPFieldJsonInput';
import { field_jsoninput } from '../../data/fields/field_jsoninput';
import { ImportRecord } from '../../services/GeneralService';
import { auth_backup, db_backup } from '../../firebase_backup';
import { db } from '../../firebase';
import { getFirestore, collection, getDocs, addDoc, setDoc, doc } from 'firebase/firestore';

const FSPImportDialog = ({ open, setShowImportDialog, table }) => {
    const [name, setName] = useState({});
    const [importdata, setImportdata] = useState([]);
    const [counter, setCounter] = useState(0);
    let timer;
    let imported = 0;
    const setJson = (a) => {
        setName(a);
    }
    const newRecord = ({ table, key, record }) => {
        ImportRecord(key, table, record);
    }
    const incrementCounter = () => {
        const records = Object.keys(name.target.value);
        const rows = records.length;
        if (imported < rows) {
            const key = records[imported];
            newRecord({
                table: table.collection,
                key: key,
                record: name.target.value[key]
            })
            setImportdata((prevState) => ({
                ...prevState,
                [imported]: name.target.value[imported],
            }));
            imported = imported + 1;
        } else {
            clearInterval(timer);
        }

    };
    const copyCollection = async () => {
        try {
            // Firebase 1 gyűjtemény lekérdezése
            const querySnapshot = await getDocs(collection(db, table.collection));

            // Firebase 1 gyűjtemény dokumentumainak másolása a Firebase 2 gyűjteménybe
            // querySnapshot.forEach((doc) => {
            //     addDoc(collection(db_backup, table.collection), doc.data());
            // });
            querySnapshot.forEach(async (queryDocSnapshot) => {
                const data = queryDocSnapshot.data();
                await setDoc(doc(db_backup, table.collection, queryDocSnapshot.id), data);
            });
            console.log('Collection copied successfully!');
        } catch (error) {
            console.error('Error copying collection:', error);
        }
    };

    const restoreCollection = async () => {
        try {
            const querySnapshot = await getDocs(collection(db_backup, table.collection));
            querySnapshot.forEach(async (queryDocSnapshot) => {
                const data = queryDocSnapshot.data();
                await setDoc(doc(db, table.collection, queryDocSnapshot.id), data);
            });
            console.log('Collection copied successfully!');
        } catch (error) {
            console.error('Error copying collection:', error);
        }
    };

    const importJson = () => {
        // copyCollection();
        imported = 0;
        console.log(name.target.value);
        timer = setInterval(incrementCounter, 1000);
        console.log(importdata);
    }
    return (
        <Dialog
            fullWidth={true}
            open={open}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
                Adatok importálása
                <FSPFieldJsonInput
                    field={field_jsoninput({
                        name: 'a', required: false, label: 'a',
                        autoHide: [], addId: null, defaultValue: {}
                    })}
                    value={name}
                    handleChange={setJson}
                    errors={null}
                />
                Adatok: {typeof importdata}{counter}
                {importdata && Object.keys(importdata).map((field, index) =>
                    <span key={index}>
                        {index}
                    </span>
                )}
            </DialogContent>
            <DialogActions>

                <Button
                    onClick={() => {
                        setShowImportDialog(null);
                    }}
                    color="primary"
                    autoFocus>
                    Mégsem
                </Button>
                <Button
                    onClick={(event) => {
                        event.preventDefault();

                        importJson();
                        // deleteElement(showDeleteDialog.id ?? showDeleteDialog);
                        // setShowImportDialog(null);
                    }}
                    color="primary"
                    variant="contained"
                    autoFocus>
                    Importálás
                </Button>
                <Button
                    onClick={(event) => {
                        event.preventDefault();

                        copyCollection();
                        // deleteElement(showDeleteDialog.id ?? showDeleteDialog);
                        // setShowImportDialog(null);
                    }}
                    color="primary"
                    variant="contained"
                    autoFocus>
                    Biztonsági mentés
                </Button>
                <Button
                    onClick={(event) => {
                        event.preventDefault();

                        restoreCollection();
                        // deleteElement(showDeleteDialog.id ?? showDeleteDialog);
                        // setShowImportDialog(null);
                    }}
                    color="primary"
                    variant="contained"
                    autoFocus>
                    Visszaállítás
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default FSPImportDialog;