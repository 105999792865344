import React, { useState } from 'react';
import { Button, Icon, IconButton } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { setProps } from './Utility';


export const ButtonComponent = ({
    node,
    nodes,
    setActive,
}) => {
    const data = nodes[node];
    const style = setProps({
        p: ['icon', 'size', 'color'],
        data: data.properties
    })
    const stop = (e) => {
        console.log(e);
        console.log(node);
        e.stopPropagation();
        setActive(node);
    }
    const IconComponents = Icons[style['icon']];
    return (
        (<Button className={'icon_component'} onClick={(e) => stop(e)}>
            <Icon baseClassName="material-icons-two-tone">{style['icon']}</Icon>
            Button
        </Button>)
    )
}