import React, { useEffect, useState } from 'react';

const MetaUnSubscriptions = () => {
    const [metaUnSubscriptions, setUnMetaSubscriptions] = useState({});

    const addMetaUnSubscription = ({ collectionName }) => {
        const subscriptionKey = `${collectionName}`;
        const currentTime = new Date();
        setUnMetaSubscriptions((prevState) => ({
            ...prevState,
            [collectionName]: currentTime
        }));
    }

    const deleteMetaUnSubscription = ({ collectionName }) => {
        const subscriptionKey = `${collectionName}`;
        setUnMetaSubscriptions((prevState) => {
            const { [collectionName]: deleted, ...rest } = prevState;
            return rest;
        });
    }

    return { metaUnSubscriptions, addMetaUnSubscription, deleteMetaUnSubscription };
};

export default MetaUnSubscriptions;
