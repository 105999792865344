import { Chip, CircularProgress, Container } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import FSPCrud from './FSPCrud/FSPCrud';
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { AddRecord, DeleteRecord, GetAllRecord, Subscibe, UpdateRecord } from "../services/GeneralService";
import { convertArrayById, convertArrayById2, getItemId, getProp, getPropArray } from './Utility';
// import useFirestoreStore2 from '../services/useFirestoreStore2';
import { ContextProvider2, useFirestore } from '../services/fbcontext';
import { Context2 } from '../services/fbcontext';
import { Face, Hub, LineAxis } from '@mui/icons-material';
import Loadings from './SubscriptionsCleaner/Loadings';
import SubscriptionsCleaner from './SubscriptionsCleaner/SubscriptionsCleaner';

const Page = ({
    table
}) => {
    // const {color, setColor} = useContext(FirestoreProvider)
    const [user, loading, error] = useAuthState(auth);
    // const value = useContext(Context);
    const { environmentVariables } = useContext(Context2);
    const { subscriptions,
        subscribeToCollection,
        unsubscribeFromCollection,
        metaSubscriptions,
        subscribeToMetas,
        unsubscribeFromMeta,
        unsubscribeFromMetas,
        metaUnSubscriptions,
        addMetaUnSubscription,
        deleteMetaUnSubscription,
        addMetaUnSubscriptions,
        loadings } = useContext(Context2);
    const [model, setModel] = useState(null);
    const [meta, setMeta] = useState({});
    const [metaArray, setMetaArray] = useState({});
    // const { subscriptions, metaSubscriptions,
    //     subscribeToCollection, unsubscribeFromCollection,
    //     subscribeToMetas, unsubscribeFromMeta,
    // } = useFirestoreStore2('myCollection');
    const [metaData, setMetaData] = useState({});
    const [metaDataArray, setMetaDataArray] = useState({});

    // useEffect(() => {
    //     console.log('hoppacska', subscriptions);
    // }, [subscriptions]);

    // useEffect(() => {
    //     console.log('loadings', loadings);
    // }, [loadings]);

    // useEffect(() => {
    //     console.log('metaSubscriptions', metaSubscriptions);
    // }, [metaSubscriptions]);

    // useEffect(() => {
    //     console.log('hoppacska1111111-cc', metaUnSubscriptions);
    // }, [metaUnSubscriptions]);

    useEffect(() => {

        const collectionName = getProp(table, 'collection');
        if (collectionName)
            subscribeToCollection({ callback: setModelData, collectionName: collectionName });
        return () => {
            unsubscribeFromCollection({ collectionName: table.collection });
        };
    }, [table]);

    useEffect(() => {
        console.log('EZ FUT LE MOST 1', table.meta);
        if (table && table.meta)
            subscribeToMetas({ callback: setMetaData3, collectionNames: table.meta });
        //TODO: TESZTELNI a firestore hatékonyságot, érdemes-e lejelentkezni
        return () => {
            console.log('EZ FUT LE MOST 2');
            unsubscribeFromMetas({ collectionNames: table.meta });
            // addMetaUnSubscriptions({ collectionNames: table.meta });
        };
    }, [table]);

    const setModelData = (tableName, records) => {
        const f = getPropArray(records, ['_snapshot', 'fromCache']);
        if (table.collection === tableName)
            setModel(records);
        else
            console.log('miezaz impedellum', tableName)
    }

    const setMetaData2 = (table, records) => {
        metaData[table] = convertArrayById2(records, 'id');
        metaDataArray[table] = convertArrayById(metaData[table], 'id');
        setMeta(metaData);
        setMetaArray(metaDataArray);
    }
    const setMetaData3 = (table, records) => {
        if (records) {
            const a = convertArrayById2(records.docs, 'id');
            const b = convertArrayById(a, 'id');

            setMeta((prevState) => ({
                ...prevState,
                [`${table}`]: a,
            }));
            setMetaArray((prevState) => ({
                ...prevState,
                [`${table}`]: b,
            }));
        }

    }
    const test = (a) => {
        unsubscribeFromMeta(a)
    }

    const createElement = (newRecord) => {
        AddRecord(table.collection, newRecord)
    }

    const deleteElement = (id) => {
        DeleteRecord(table.collection, id);
    }

    const updateElement = (id, record) => {
        UpdateRecord(id, table.collection, record, table.updatefields);
    }

    return (
        <>
            {/* <Loadings showLabel={true} /> */}
            {/* <SubscriptionsCleaner
                showLabel={true}
            /> */}
            {/* {environmentVariables.view == 'desktop' &&
                <>
                    {JSON.stringify(Object.keys(subscriptions), null, "\t")}
                    meta
                    {JSON.stringify(Object.keys(metaSubscriptions), null, "\t")}
                </>
            }
            {loadings &&
                Object.keys(loadings).map((l, index) =>
                    <div>
                        <span>{l}</span>:<span>{loadings[l] ? 'loading...' : ''}</span>
                    </div>
                )} */}
            {loading ? (<>Loading</>) : (
                model ?
                    <FSPCrud
                        model={model.docs}
                        createElement={createElement}
                        deleteElement={deleteElement}
                        updateElement={updateElement}
                        table={table}
                        meta={meta}
                        metaArray={metaArray}
                        view={environmentVariables.view}
                    // metaData={metaData}
                    /> : <CircularProgress />
            )}

        </>
    );
};

export default Page;