import React, { useState, useEffect, useContext } from 'react';
import { Chip } from '@mui/material';
import { Face, Help, Hub } from '@mui/icons-material';
import { Context2 } from '../../../services/fbcontext';
import { tables } from '../../../data/tables';


const NormalView = ({
    unsubscribeFromMeta,
    metaUnSubscriptions,
    subscriptions,
    metaSubscriptions,
    loadings,
    showLabel,
    updatedList
}) => {
    
    return (
        <>
            {loadings && subscriptions &&
                Object.keys(subscriptions).map((label, index) =>
                    <>
                        {loadings[label]
                            ? <Chip icon={<Face />} label={showLabel ? label : ''} color="warning" variant="filled" />
                            : <Chip icon={<Face />} label={showLabel ? label : ''} color="success" variant="outlined" />
                        }
                    </>
                )
            }
            {loadings && metaSubscriptions &&
                Object.keys(metaSubscriptions).map((label, index) =>
                    <>{
                        // <DynamicFaIcon fontSize="12" color={orange[500]} name={tables[label].icon} />
                        typeof updatedList[label] == 'object' && (
                            <Chip icon={
                                <Hub />
                            } label={showLabel ? label : ''} color="info" variant="filled" />
                        )
                    }
                        {
                            typeof updatedList[label] != 'object' && (
                                loadings[label]
                                    ? <Chip icon={<Hub />} label={showLabel ? label : ''} color="warning" variant="filled" />
                                    : <Chip icon={<Hub />} label={showLabel ? label : ''} color="success" variant="outlined" />
                            )

                        }
                    </>
                )
            }
            {/* {updatedList && Object.keys(updatedList).map(item => (
            <Chip icon={<Face />} label={showLabel ? item : ''} color="error" variant="filled" />
          ))} */}

        </>
    );
};

export default NormalView;