import React, { useEffect, useState } from 'react';

const UnSubscriptions = () => {
    const [unSubscriptions, setUnSubscriptions] = useState({});

    const addUnSubscription = ({ collectionName }) => {
        const currentTime = new Date();
        setUnSubscriptions((prevState) => ({
            ...prevState,
            [collectionName]: currentTime
        }));
    }

    const deleteUnSubscription = ({ collectionName }) => {
        setUnSubscriptions((prevState) => {
            const { [collectionName]: deleted, ...rest } = prevState;
            return rest;
        });
    }

    return { unSubscriptions, addUnSubscription, deleteUnSubscription };
};

export default UnSubscriptions;
