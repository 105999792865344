import React, { useContext, useEffect, useState } from "react";
import { Context2 as Context } from "../../services/fbcontext";
import { AppDataArray } from "../../data/appications";
import View from "./View";
import Model from "./Model";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth_backup } from "../../firebase_backup";
import { auth, db } from "../../firebase";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { GetAllRecord } from "../../services/GeneralService";

export function Teszt2({ table, app }) {
    const { environmentVariables } = useContext(Context);
    const { label, refreshLabel, view, refreshView } = Model({ AppDataArray: AppDataArray, environmentVariables: environmentVariables });
    const [user_backup, loading_backup, error_backup] = useAuthState(auth_backup);
    const [user, loading, error] = useAuthState(auth);

    return (
        <View view={view} loading_backup={loading_backup} loading={loading} label={label} app={app} user={user} user_backup={user_backup}>

        </View>
    );
}

