export const pages = {
    "questionnaires": {
        "id": "questions",
        "label": "Kérdőívek",
        "route": "/questionnaires",
        "table": "questionnaires",
        "type": "Main",
        "icon": "QuestionMark"
    },
    "evaluated": {
        "id": "evaluated",
        "label": "Értékelések",
        "route": "/evaluated",
        "table": "evaluated",
        "type": "Main",
        "icon": "ThumbsUpDown"
    },
    // "evaluatedMonth": {
    //     "id": "evaluatedMonth",
    //     "label": "Értékelések(hó)",
    //     "route": "/evaluatedMonth",
    //     "table": "evaluatedMonth",
    //     "type": "Main",
    //     "icon": "ThumbsUpDown"
    // },
    "filledquestionnaires": {
        "id": "filledquestionnaires",
        "label": "Kitöltések",
        "route": "/filledquestionnaires",
        "table": "filledquestionnaires",
        "type": "Main",
        "icon": "Create"
    },
    "questions": {
        "id": "questions",
        "label": "Ért. kérd.",
        "route": "/grouplist1",
        "table": null,
        "type": "Static",
        "icon": "ContactSupport",
        "component": "GroupList",
    },
    "questions2": {
        "id": "questions",
        "label": "Ért. kérd.",
        "route": "/grouplist2",
        "table": null,
        "type": "Static",
        "icon": "ContactSupport",
        "component": "GroupList",
        "params": 'id'
    }
}
export default pages