export const table =
{
	"updatedAt": {
		"seconds": 1684160845,
		"nanoseconds": 319000000
	},
	"name": "teszt5",
	"icon": "AbcOutlined",
	"title_create": "teszt5",
	"updatefields": [
		"szoveg",
		"json1",
		"json2"
	],
	"meta": [
		"admins"
	],
	"indexFields": [
		"szoveg",
		"json1",
		"json2"
	],
	"collection": "teszt5",
	"title_update": "teszt5",
	"fields": {
		"szoveg": {
			"name": "szoveg",
			"type": "text",
			"default": "szoveg",
			"updatefields": [],
			"fields": [],
			"label": "szoveg",
			"helper": "szoveg",
			"required": "true",
			"indexFields": []
		},
		"json2": {
			"label": "json2",
			"fields": {
				"value": {
					"name": "value",
					"helper": "value",
					"label": "value",
					"type": "text"
				},
				"name": {
					"type": "text",
					"helper": "name",
					"name": "name",
					"label": "name"
				}
			},
			"required": "true",
			"title_index": "json2",
			"title_create": "json2",
			"updatefields": [
				"name",
				"value"
			],
			"name": "json2",
			"indexFields": [
				"name",
				"value"
			],
			"type": "json2",
			"title_update": "json2",
			"helper": "json2",
			"default": {
				"ertek": {
					"value": "0",
					"name": "ertek"
				}
			}
		},
		"json1": {
			"indexFields": [
				"t1"
			],
			"helper": "json1",
			"type": "json",
			"fields": [
				{
					"name": "t1",
					"label": "t1",
					"type": "text",
					"helper": "t1",
					"_id": "item_1684160528485"
				}
			],
			"name": "json1",
			"title_index": "json1",
			"label": "json1",
			"required": "true",
			"updatefields": [
				"t1"
			],
			"title_create": "json1",
			"default": [
				{
					"t1": "ertek",
					"_id": "item_1684161019567"
				}
			]
		}
	},
	"_id": "teszt5",
	"type": "json",
	"title_index": "teszt5",
	"id": "teszt5"
}
export default table