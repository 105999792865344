import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import swDev from "./swDev";
 import reportWebVitals from './reportWebVitals';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as serviceWorker from './serviceWorker';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
    <App />
  
);
// serviceWorkerRegistration.register();
// serviceWorker.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 reportWebVitals();
// swDev()
