import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import FSPFormField from './FSPFormField';

const FSPForm = ({
    open,
    createElement,
    updateElement,
    table,
    setShowNewDialog,
    handleChange,
    handleChangeArray,
    handleChangeTable,
    newRecord,
    errors,
    meta,
    view,
    metaArray,
    parentRecord
}) => {

    const { form, title_create, title_update } = table;
    return (
        <Dialog
            fullWidth={true}
            // fullScreen
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{(newRecord && newRecord.id) || (newRecord && newRecord.id) ? title_update : title_create}</DialogTitle>
            <DialogContent style={{ minHeight: '250px' }}>
                {newRecord && newRecord.id}
                <form>
                    <div>
                        {table.fields && Array.isArray(table.fields) &&
                            table.fields.map((field, index) =>
                                <div key={index}>
                                    <FSPFormField
                                        field={field}
                                        errors={errors}
                                        newRecord={newRecord}
                                        handleChange={handleChange}
                                        handleChangeArray={handleChangeArray}
                                        handleChangeTable={handleChangeTable}
                                        meta={meta}
                                        view={view}
                                        metaArray={metaArray}
                                        parentRecord={parentRecord}
                                    />
                                </div>
                            )}
                        {table.fields && !Array.isArray(table.fields) &&
                            Object.keys(table.fields).map((field, index) =>
                                <div key={index}>
                                    <FSPFormField
                                        field={table.fields[field]}
                                        errors={errors}
                                        newRecord={newRecord}
                                        handleChange={handleChange}
                                        handleChangeArray={handleChangeArray}
                                        handleChangeTable={handleChangeTable}
                                        meta={meta}
                                        view={view}
                                        metaArray={metaArray}
                                        parentRecord={parentRecord}
                                    />
                                </div>
                            )}
                    </div>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setShowNewDialog(false);
                    }}
                    color="primary"
                    autoFocus>
                    Mégsem
                </Button>
                <Button
                    onClick={() => {
                        newRecord.id
                            ? updateElement(newRecord.id, newRecord)
                            : createElement(newRecord);
                        setShowNewDialog(false);
                    }}
                    disabled={Object.keys(errors).length > 0}
                    color="primary"
                    variant="contained"
                    autoFocus>
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FSPForm;