import { initializeApp } from "firebase/app";
// import { getMessaging } from 'firebase/messaging/sw';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  setDoc,
  doc,
} from "firebase/firestore";

import { getStorage } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { getProp } from "./common/Utility";
import { AddRecord } from "./services/GeneralService";

const publicKey = "BFBn84qAP35iy208a2lXopjwhduQz5YXGUPYAs5DzhZS00arOaiLogrAOT4cP2ZpER2x1SsmRU3FqXL6elA3tjM";

const firebaseConfig = {
  apiKey: "AIzaSyCoPMMjPpA9XY5pEfQ-YfgFxgny9nsqgts",
  authDomain: "edunetprvet-eea49.firebaseapp.com",
  projectId: "edunetprvet-eea49",
  storageBucket: "edunetprvet-eea49.appspot.com",
  messagingSenderId: "671993670064",
  appId: "1:671993670064:web:872860d05dceae83920e75",
  measurementId: "G-KEW941ZBD4"
};

const firstApp = {
  "id": 1,
  "name": "Main",
  "config": firebaseConfig
}
// const firebaseConfig = {
//   apiKey: "AIzaSyAUpXNjTkC6iB02HJOd5rElXb5cRONEHXg",
//   authDomain: "backup-885d4.firebaseapp.com",
//   projectId: "backup-885d4",
//   storageBucket: "backup-885d4.appspot.com",
//   messagingSenderId: "275604664793",
//   appId: "1:275604664793:web:3dcacc56302f93d87ae9c8"
// };
// const app = initializeApp(firebaseConfig, 'main');

let app = {};
let auth = {};
let db = {};
let storage = {};
let messaging = {};

const storedApps = JSON.parse(localStorage.getItem('firebaseApps'));
console.log('FIREBASE initializeApp 0', storedApps);
const apps = { ...storedApps, firstApp: firstApp };
console.log('FIREBASE initializeApp 1', apps);
const selectedAppName = localStorage.getItem('selectedApp') ?? 'firstApp';
console.log('FIREBASE initializeApp 2', selectedAppName);
const selectedApp = apps[selectedAppName];
console.log('FIREBASE initializeApp 3', selectedApp)
const selectedConfig = getProp(selectedApp, 'config') ?? firebaseConfig;
console.log('FIREBASE initializeApp 4', selectedConfig)


// app = initializeApp(selectedConfig);
app = initializeApp(selectedConfig, selectedAppName);
auth = getAuth(app);
db = getFirestore(app);
storage = getStorage(app);
// fetchToken();
messaging = getMessaging(app);

// Alkalmazás inicializálása
export const initializeFirebaseApp = () => {

  const storedApps = JSON.parse(localStorage.getItem('firebaseApps')) ?? {};
  console.log('FIREBASE initializeApp 0', storedApps);
  const apps = { ...storedApps, firstApp: firstApp };
  console.log('FIREBASE initializeApp 1', apps);
  const selectedAppName = localStorage.getItem('selectedApp') ?? 'firstApp';
  console.log('FIREBASE initializeApp 2', selectedAppName);
  const selectedApp = apps[selectedAppName];
  console.log('FIREBASE initializeApp 3', selectedApp)
  const selectedConfig = selectedApp.config;
  console.log('FIREBASE initializeApp 4', selectedConfig)

  // app = initializeApp(selectedConfig);
  app = initializeApp(selectedConfig, selectedAppName);
  auth = getAuth(app);
  db = getFirestore(app);
  storage = getStorage(app);
  // fetchToken();
  messaging = getMessaging(app);
};

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

// const logInWithEmailAndPassword = async (email, password) => {
//   try {
//     await signInWithEmailAndPassword(auth, email, password);
//   } catch (err) {
//     console.log(err);
//     // alert(err.message);
//   }
// };

const logInWithEmailAndPassword = async (email, password) => {
  const storedApps = JSON.parse(localStorage.getItem('firebaseApps'));
  console.log('FIREBASE initializeApp 0', storedApps);
  const apps = { ...storedApps, firstApp: firstApp };
  console.log('FIREBASE initializeApp 1', apps);
  const selectedAppName = localStorage.getItem('selectedApp') ?? 'firstApp';
  console.log('FIREBASE initializeApp 2', selectedAppName);
  const selectedApp = apps[selectedAppName];
  console.log('FIREBASE initializeApp 3', selectedApp)
  const selectedConfig = getProp(selectedApp, 'config') ?? firebaseConfig;
  console.log('FIREBASE initializeApp 4', selectedConfig)

  // app = initializeApp(selectedConfig, selectedAppName !== 'firstApp' ? selectedAppName : selectedAppName);
  app = initializeApp(selectedConfig, selectedAppName);
  auth = getAuth(app);
  db = getFirestore(app);
  storage = getStorage(app);
  // fetchToken();
  messaging = getMessaging(app);
  await signInWithEmailAndPassword(auth, email, password);
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await setDoc(doc(db, "users", user.uid), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
    // await addDoc(collection(db, "users"), {
    //   uid: user.uid,
    //   name,
    //   authProvider: "local",
    //   email,
    // });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  console.log('FIREBASE 1 LOGOUT')
  signOut(auth);
};

// const firebaseApp = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);

// const fetchToken = (setTokenFound) => {
//   return getToken(messaging, { vapidKey: 'BHGPr3pJQSflJAJtTIVXbmcEXlPV_HP29TZQRcqrGCN10gKIa-ojIJmtvM9kQGcsNKsWIA6ezKFG8Bd6LTjaVc0' }).then((currentToken) => {
//     if (currentToken) {
//       console.log('current token for client: ', currentToken);
//       setTokenFound(true);
//       // Track the token -> client mapping, by sending to backend server
//       // show on the UI that permission is secured
//     } else {
//       console.log('No registration token available. Request permission to generate one.');
//       setTokenFound(false);
//       // shows on the UI that permission is required 
//     }
//   }).catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
//     // catch error while creating client token
//   });
// }

// const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });
const fetchToken = (setTokenFound) => {
  console.log('token1', messaging);
  return getToken(messaging, { vapidKey: publicKey }).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(currentToken);
      AddRecord('tokens', { id: auth.currentUser.uid, token: currentToken })
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

const requestForToken = async (setTokenCallback) => {
  console.log('token2', messaging);
  try {
    const currentToken = await getToken(messaging, { vapidKey: publicKey });
    console.log('Fcm token: ', currentToken);
    setTokenCallback(currentToken);
    return currentToken;
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
};

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

const sendPushNotification = async () => {
  // const messaging = getMessaging();

  try {
    const token = await getToken(messaging, { vapidKey: 'BGWPfLoaHDPDr2jTxNwIgVzaXw2qGiEHYmNAe7qWjaAHfJ5dmU2qWIZXFjqWmQ7vIFqfUqtWALXJ6fO1Zmi1slo' });

    // Küldj egy push üzenetet a Firebase segítségével
    const response = await fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        Authorization: 'key=BFBn84qAP35iy208a2lXopjwhduQz5YXGUPYAs5DzhZS00arOaiLogrAOT4cP2ZpER2x1SsmRU3FqXL6elA3tjM',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: token,
        notification: {
          title: 'Push értesítés',
          body: 'Ez egy teszt push értesítés.',
        },
      }),
    });

    if (response.ok) {
      console.log('Push értesítés sikeresen elküldve!');
    } else {
      console.log('Push értesítés küldése sikertelen.');
    }
  } catch (error) {
    console.error('Hiba történt a push értesítés küldésekor:', error);
  }
};

export {
  auth,
  db,
  storage,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  fetchToken,
  requestForToken,
  sendPushNotification,
  onMessageListener
  // onMessageListener,
  // fetchToken
};
