export const defaultNode =
{
    "item_1680791259673": {
        "id": "item_1680791259673",
        "type": "html",
        "name": "Main node",
        "parent": null,
        "parent_prop": null,
        "properties": {
            "component": "defaultComponent",
            "_input": {}
        },
        "children": {
            "components": [
                "item_1680791263715"
            ]
        }
    },
    "item_1680791263715": {
        "id": "item_1680791263715",
        "type": "html",
        "name": "WindowComponent",
        "parent": "item_1680791259673",
        "parent_prop": "components",
        "properties": {
            "component": "WindowComponent",
            "a": "b",
            "_input": {
                "background": {
                    "type": "color",
                    "value": "#ffffd7"
                },
                "height": {
                    "value": "500px",
                    "type": "number",
                    "max": 100,
                    "min": 20
                },
                "width": {
                    "value": "100%",
                    "max": 100,
                    "min": 20,
                    "type": "number"
                },
                "min-height": {
                    "max": 100,
                    "type": "string",
                    "min": 20,
                    "value": "100px"
                }
            }
        },
        "children": {
            "components": [
                "item_1680791279094",
                "item_1680791283711"
            ]
        }
    },
    "item_1680791279094": {
        "id": "item_1680791279094",
        "type": "html",
        "name": "WindowComponent",
        "parent": "item_1680791263715",
        "parent_prop": "components",
        "properties": {
            "_input": {
                "border-width": {
                    "type": "color",
                    "value": "1px"
                },
                "background": {
                    "value": "#668866",
                    "type": "color"
                },
                "width": {
                    "min": 20,
                    "value": "100px",
                    "type": "number",
                    "max": 100
                },
                "border": {
                    "type": "color",
                    "value": "dotted"
                },
                "height": {
                    "max": 100,
                    "value": "100%",
                    "type": "number",
                    "min": 20
                },
                "float": {
                    "value": "right"
                }
            },
            "a": "b",
            "component": "WindowComponent"
        },
        "children": {}
    },
    "item_1680791283711": {
        "id": "item_1680791283711",
        "type": "html",
        "name": "iconComponent",
        "parent": "item_1680791263715",
        "parent_prop": "components",
        "properties": {
            "component": "iconComponent",
            "_input": {
                "size": {
                    "type": "number",
                    "max": 100,
                    "min": 10,
                    "value": 20
                },
                "icon": {
                    "type": "icon",
                    "value": "mode_edit"
                },
                "color": {
                    "value": "#efe",
                    "type": "color"
                }
            }
        },
        "children": {}
    }
}