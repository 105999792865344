import React from 'react';
import { getProp, getPropArray, getPropArrayParams } from '../../../Utility';

const FSPRowFieldDocumentSelect = ({
    model,
    value,
    valuesLabel,
    metaArray
}) => {
    const data = {
        model: model,
        value: value,
        valuesLabel: valuesLabel
    }
    const label = getPropArrayParams(metaArray, data,
        ['model', 'value', 'valuesLabel']);

    return (
        <>
            {label}
        </>
    );
};

export default FSPRowFieldDocumentSelect;