export const table =
{
	"fields": [
		{
			"default": "",
			"required": "true",
			"_id": "item_1682446275182",
			"name": "szovegmező",
			"helper": "dsfs",
			"type": "text",
			"label": "sdfs"
		},
		{
			"type": "icon",
			"default": "AccessAlarm",
			"_id": "item_1682459580625",
			"helper": "icon",
			"label": "icon",
			"name": "icon"
		},
		{
			"helper": "számmező",
			"type": "number",
			"name": "számmező",
			"default": "1",
			"_id": "item_1682460185646",
			"label": "számmező"
		},
		{
			"valuesLabel": "name",
			"_id": "item_1682460237723",
			"name": "dokumentum választó",
			"helper": "dokumentum választó",
			"label": "dokumentum választó",
			"valuesKey": "name",
			"type": "document_select",
			"default": "igen_nem",
			"model": "constants"
		},
		{
			"name": "dokumentum multiselect",
			"default": [
				"css_selector_tipusok",
				"igen_nem"
			],
			"helper": "dokumentum multiselect",
			"type": "document_multiselect",
			"valuesLabel": "name",
			"_id": "item_1682461022638",
			"valuesKey": "id",
			"model": "constants",
			"label": "dokumentum multiselect"
		},
		{
			"name": "img",
			"_id": "item_1683664196835",
			"label": "kép",
			"type": "img",
			"helper": "kép"
		},
		{
			"label": "profilkép",
			"type": "img_store",
			"name": "profile_images",
			"bucket": "profile_images",
			"store_image_type": "profile",
			"_id": "item_1683717007769",
			"helper": "profilkép"
		},
		{
			"model": "constants",
			"bucket": "",
			"document_dynamic": "igen/nem",
			"_id": "item_1683852355399",
			"title_create": "",
			"title_index": "",
			"store_image_type": null,
			"indexFields": null,
			"document": "igen_nem",
			"default": "true",
			"_document": "title_create",
			"name": "required",
			"helper": "Kötelező",
			"required": "false",
			"fields": [],
			"label": "Kötelező",
			"valuesLabel": "label",
			"updatefields": null,
			"valuesField": "values",
			"valuesKey": "value",
			"type": "value_select_static",
			"title_update": ""
		},
		{
			"helper": "most",
			"title_update": "",
			"indexFields": null,
			"default": "tobbvalasztos",
			"updatefields": null,
			"store_image_type": null,
			"name": "valasztas",
			"document": "skala_tipusok",
			"valuesField": "values",
			"_document": null,
			"title_index": "",
			"model": "constants",
			"document_dynamic": null,
			"required": "true",
			"valuesKey": "value",
			"title_create": "",
			"valuesLabel": "label",
			"_id": "item_1683853395283",
			"bucket": "",
			"fields": [],
			"type": "value_select_static",
			"label": "most"
		},
		{
			"store_image_type": null,
			"indexFields": [
				"bzsolt"
			],
			"default": null,
			"title_create": "sdf",
			"label": "fields",
			"helper": "fields",
			"valuesLabel": null,
			"document_dynamic": null,
			"_id": "item_1683895293117",
			"_document": null,
			"document": null,
			"updatefields": [
				"bzsolt"
			],
			"required": "false",
			"model": null,
			"type": "json",
			"title_index": "sdf",
			"valuesField": null,
			"bucket": "",
			"title_update": "sdf",
			"valuesKey": null,
			"fields": [
				{
					"valuesKey": null,
					"type": "text",
					"updatefields": null,
					"valuesField": null,
					"document_dynamic": null,
					"label": "asd",
					"indexFields": null,
					"_id": "item_1683895283059",
					"valuesLabel": null,
					"bucket": "",
					"title_index": "",
					"name": "bzsolt",
					"default": null,
					"model": null,
					"store_image_type": null,
					"document": null,
					"helper": "asd",
					"title_update": "",
					"title_create": "",
					"_document": null
				}
			],
			"name": "fields"
		},
		{
			"valuesKey": "id",
			"_document": null,
			"model": "images",
			"helper": "kep2",
			"valuesField": null,
			"valuesLabel": "img",
			"type": "image_select",
			"default": "7aUXqYM0DfX8UEVxyvKz",
			"label": "kep2",
			"title_update": "",
			"title_index": "",
			"required": "false",
			"name": "image",
			"_id": "item_1683974660673",
			"document": null,
			"updatefields": null,
			"fields": [],
			"indexFields": null,
			"bucket": "",
			"document_dynamic": null,
			"store_image_type": null,
			"title_create": ""
		},
		{
			"type": "document_select",
			"label": "teszt5",
			"valuesField": "name",
			"default": "S7g6dSgusdLCKtKpw99y",
			"required": "true",
			"helper": "teszt5",
			"valuesKey": "id",
			"model": "teszt5",
			"valuesLabel": "szoveg",
			"_id": "item_1684151949564",
			"name": "teszt5"
		}
	],
	"title_update": "Példa módosítása",
	"_id": "table1",
	"collection": "teszt",
	"icon": "AbcOutlined",
	"meta": [
		"constants",
		"images",
		"teszt5",
		"admins"
	],
	"title_index": "Teszt tábla111",
	"updatefields": [
		"szovegmező",
		"icon",
		"számmező",
		"dokumentum választó",
		"dokumentum multiselect",
		"img",
		"profile_images",
		"required",
		"valasztas",
		"fields",
		"image",
		"teszt5"
	],
	"type": "array",
	"updatedAt": {
		"seconds": 1684161789,
		"nanoseconds": 108000000
	},
	"title_create": "Új példa",
	"indexFields": [
		"szovegmező",
		"icon",
		"számmező",
		"dokumentum választó",
		"dokumentum multiselect",
		"img",
		"profile_images",
		"required",
		"valasztas",
		"image",
		"teszt5",
		"fields"
	],
	"name": "Teszt tábla",
	"id": "table1"
}
export default table