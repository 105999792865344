export const table =
{
	"type": "array",
	"_id": "evaluatedmonth",
	"collection": "evaluatedMonth",
	"updatefields": [
		"month",
		"evaluatedId"
	],
	"indexFields": [
		"month",
		"evaluatedId"
	],
	"title_index": "Értékelés (havi)",
	"meta": [
		"users"
	],
	"icon": "Abc",
	"name": "Értékelés (havi)",
	"fields": [
		{
			"_id": "item_1697833879989",
			"updatefields": [],
			"indexFields": [],
			"type": "text",
			"label": "month",
			"fields": {},
			"helper": "month",
			"name": "month",
			"required": "true"
		},
		{
			"helper": "Értékelt",
			"indexFields": [],
			"_id": "item_1697833930311",
			"fields": {},
			"required": "true",
			"valuesKey": "id",
			"updatefields": [],
			"valuesLabel": "email",
			"model": "users",
			"name": "evaluatedId",
			"type": "document_select",
			"label": "Értékelt"
		}
	],
	"title_update": "Módosítás",
	"updatedAt": {
		"seconds": 1697836426,
		"nanoseconds": 607000000
	},
	"ui": "general",
	"title_create": "Új",
	"id": "evaluatedmonth"
}
export default table