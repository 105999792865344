import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, fetchToken, requestForToken } from '../../firebase';
import { Context2 as Context } from '../../services/fbcontext';
import table_groups from '../../data/tables/table_groups';
import { convertArrayById, convertArrayById2, getProp } from '../../common/Utility';
import MainLayout from '../../layouts/Main/MainLayout';
import { Navigate, useParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import Model from './Model';
import View from './View';
import { getPropArray } from '../../common/Utility';
import PushNotification from '../../services/PushNotification';

export const GroupList = ({ table = 'group', app }) => {
    const { getMyGroups, getProfile, getSubscribed } = Model(table_groups);
    const [user, loading, error] = useAuthState(auth);
    const { environmentVariables } = React.useContext(Context);
    const { id } = useParams()
    const navigate = useNavigate();
    const [isTokenFound, setTokenFound] = useState(false);


    useEffect(() => {
        getProfile({ user: user });
        getMyGroups({ user: user });
        getSubscribed({ user: user });
        fetchToken(setTokenFound);
    }, [user]);

    // useEffect(() => {
    //     getMyGroups({ myGroups: getPropArray(environmentVariables, ['profile', 'myGroups']) })
    // }, [environmentVariables.profile]);

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/signin");
    }, [user, loading]);

    return (
        <MainLayout app={app}>

            <View
                user={user}
                id={id}
                environmentVariables={environmentVariables}
            >
                Teszt
            </View>
            Token
            
                {isTokenFound}
            

            <PushNotification />
        </MainLayout>
    );
};
