import React from 'react';

const FSPRowFieldText = ({ value }) => {
    return (
        <>
            {value}
        </>
    );
};

export default FSPRowFieldText;