import React, { useState } from 'react';
import * as Miu from '@mui/material';
import * as Icons from '@mui/icons-material'; import FSPIcon from '../../../../layouts/Main/FSPIcon';
import Model from './Model';

const MiuComponent = ({
    children,
    nodes,
    node,
    store,
    setStore,
    setActive,
    active,
    isOver,
    dropRef
}) => {
    const data = nodes[node];
    const properties = data.properties;
    const { onClick, setParams } = Model({
        data: data,
        node: node,
        setActive: setActive,
        store: store,
        setStore: setStore,
    });

    const Comp = Miu[properties.MUIcomponent];

    var params = setParams({ properties: data.properties })
    params = isOver ? {
        ...params,
        sx: {
            ...params.sx,
            border: 1,
            borderColor: '#00f',
            borderStyle: 'dashed'
        }
    }
        : params;

    params = (active == node) ? {
        ...params,
        sx: {
            ...params.sx,
            border: 1,
            borderColor: '#f00',
            borderStyle: 'dashed'
        }
    }
        : params;
    console.log('PARAMS1', params)
    return (<>
        {React.createElement(
            Comp,
            {
                ...params,
                // style: { style3 },
                // ref: { dropRef },
                onClick: (e) => onClick(e),
                ref: dropRef
            },
            <>
                {children}
                {isOver && <div>Drop Here3!</div>}
            </>
        )}

    </>
    );
};

export default MiuComponent;