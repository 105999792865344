import React, { useContext, useEffect, useState } from "react";
import { Context2 as Context } from "../../services/fbcontext";
import { AppDataArray } from "../../data/appications";
import View from "./View";
import Model from "./Model";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth_backup } from "../../firebase_backup";
import { auth, db } from "../../firebase";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { GetAllRecord } from "../../services/GeneralService";

export function Teszt({ table, app }) {
    const { environmentVariables } = useContext(Context);
    const { label, refreshLabel, view, refreshView } = Model({ AppDataArray: AppDataArray, environmentVariables: environmentVariables });
    const [user_backup, loading_backup, error_backup] = useAuthState(auth_backup);
    const [user, loading, error] = useAuthState(auth);

    useEffect(() => {
        refreshLabel();
    }, [environmentVariables, AppDataArray])

    useEffect(() => {
        refreshView();
    }, [environmentVariables])

    useEffect(() => {
        console.log('backup', user_backup)
    }, [user_backup])


    useEffect(() => {
        console.log('backup', user)
        if (user) {
            console.log('FIREBASE initializeApp 5', user)
            const userRef = doc(db, 'users', user.uid);
            getDoc(userRef).then((snap) => {
                console.log('snap.....', snap.data());
                const userRef2 = collection(db, 'systems');
                getDocs(userRef2).then((snap) => {
                    console.log('.....', snap.docs);

                    const apps = {}
                    snap.forEach((doc) => {
                        apps[doc.id] = doc.data();
                        console.log(doc.id);
                        console.log(doc.data());
                    });
                    const a = Object.keys(snap.docs).map((i) => snap.docs[i].data());
                    console.log('a.....', apps);
                    const sa = localStorage.getItem('selectedApp')
                    console.log('sa.....', sa);
                    if (sa === 'firstApp' || sa === null) {
                        console.log('mentunk.....', apps);
                        localStorage.setItem('firebaseApps', JSON.stringify(apps));
                    }

                });
            })
        }
    }, [user])
    localStorage.setItem('teszt', 'teszt1.1');
    return (
        <View view={view} loading_backup={loading_backup} loading={loading} label={label} app={app} user={user} user_backup={user_backup}>

        </View>
    );
}

