import {
    getFirestore, collection, setDoc, getDocs, onSnapshot,
    deleteDoc, addDoc, doc, getDoc, query, where, documentId
} from "firebase/firestore";
import { db, auth, storage } from "../../firebase";

const SubscribesEV = ({
    setLoading,
    subscribeToCollection,
    unsubscribeFromCollection,
    environmentVariables,
    setEnvironmentVariable,
    deleteEnvironmentVariable
}) => {

    const setModelData = (tableName, records) => {
        setEnvironmentVariable({ key: tableName, value: records });
        setLoading({ key: tableName, status: false })
    }

    const subscribeToCollectionEV = ({ table }) => {
        setLoading({ key: table, status: true })
        console.log('LEEE environmentVariables', environmentVariables);
        if (table) {
            if (!environmentVariables[table]) {
                subscribeToCollection({ callback: setModelData, collectionName: table });
            } else {
                console.log('már megvan nyugi!!!');
            }
        }
    }

    const unsubscribeFromCollectionEV = ({ table }) => {
        unsubscribeFromCollection({ collectionName: table });
        // setEnvironmentVariable({ key: table, value: null });
        console.log('LEEE deleteEnvironmentVariable', table);
        deleteEnvironmentVariable({ key: table });
    }

    return {
        subscribeToCollectionEV,
        unsubscribeFromCollectionEV,
    };
};

export default SubscribesEV;