import React, { useEffect, useState } from 'react';
import { TextField, Checkbox, FormControlLabel, Button, MenuItem } from '@mui/material';
import FSPFieldIcon2 from '../FSPCrud/FSPForm/FSPField/FSPFieldIcon2';
import { getItemId } from '../Utility';
import { v4 as uuidv4 } from 'uuid';

const DynamicForm = ({ component, updateNode }) => {
    const [formValues, setFormValues] = useState({});
    const { properties } = component;
    const { _input, _events } = properties;
    // const prop = 'label';


    const handleChange = (event) => {
        console.log('formValues', formValues);
        console.log(event)
        const { name, value, type, checked } = event.target;
        const newValue = parseValueByType(value, type);
        console.log('checked', checked);
        console.log('name', name);
        setFormValues((prevValues) => ({ ...prevValues, [name]: { ...prevValues[name], value: checked } }));
        update({ ...formValues, [name]: { ...formValues[name], value: checked } });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Values:', formValues);
        // Esetleg további műveletek vagy adatkezelés itt
    };

    const update = (values) => {
        console.log('iii', {
            ...properties,
            _input: values
        })
        updateNode(component.id,
            {
                ...properties,
                _input: values
            });
    };
    const parseValueByType = (value, type) => {
        if (type === 'boolean' || type === 'checkbox') {
            return Boolean(value);
        } else if (type === 'number') {
            return Number(value);
        } else if (type === 'array' || type === 'object') {
            try {
                return JSON.parse(value);
            } catch (error) {
                console.error('Invalid JSON value');
                return value;
            }
        } else if (type === 'elementType') {
            return value; // No type conversion needed for elementType
        }
        return value;
    };
    const handleInputChange = (event) => {
        // event.preventDefault();
        console.log('formValues', formValues);
        console.log(event)
        const { name, value, type, checked } = event.target;
        const newValue = parseValueByType(value, type);
        console.log('newValue', newValue);
        setFormValues((prevValues) => ({ ...prevValues, [name]: { ...prevValues[name], value: newValue } }));
        update({ ...formValues, [name]: { ...formValues[name], value: value } });
    };
    const handleChangeIcon = (name, value) => {

        console.log(name);
        console.log(value);
        console.log('formValues', formValues);
        setFormValues((prevValues) => ({ ...prevValues, [name]: { ...prevValues[name], value: value } }));
        update({ ...formValues, [name]: { ...formValues[name], value: value } });
        // if (Array.isArray(value)) {
        //     setNewRecord({ ...newRecord, [name]: value });
        // } else {
        //     setNewRecord({ ...newRecord, [name]: value });
        // }

    }

    const isPropRequired = (propName) => {
        const prop = Object.keys(_input).find((p) => _input[p].name === propName);
        return prop && prop.required;
    };

    const isParamRequired = (methodName, paramName) => {
        const method = properties.methods.find((m) => m.name === methodName);
        return method && method.params.some((p) => p.name === paramName && p.required);
    };

    useEffect(() => {
        console.log('formValues', formValues);
        // updateNode(activeNode.id, {...activeNode.properties, _input:{...activeNode.properties._input, }})}
    }, [formValues]);

    useEffect(() => {
        setFormValues(_input)
    }, [_input]);

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label htmlFor="name">Name:</label>

                {_input && Object.keys(_input).map((prop) => (
                    _input[prop].type === 'boolean' ? (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={prop}
                                    checked={formValues && prop && formValues[prop] ? formValues[prop].value : false}
                                    onChange={handleChange}
                                />
                            }
                            label={prop}
                        />
                    ) : prop === 'color' && formValues[prop] && formValues[prop].control == 'picker' ? (
                        <TextField
                            name={prop}
                            label={prop}
                            type="color"
                            value={formValues && prop && formValues[prop] ? formValues[prop].value : 'primary'}
                            onChange={handleInputChange}
                            required={isPropRequired(prop)}
                            helperText={_input[prop].description}
                        >
                        </TextField>
                    ) : prop === 'color' && formValues[prop] && formValues[prop].control != 'picker' ? (
                        <TextField
                            name={prop}
                            label={prop}
                            // type="color"
                            value={formValues && prop && formValues[prop] ? formValues[prop].value : 'primary'}
                            onChange={handleInputChange}
                            required={isPropRequired(prop)}
                            helperText={_input[prop].description}
                            select>
                            <MenuItem value={"primary"}>primary</MenuItem>
                            <MenuItem value={"secondary"}>secondary</MenuItem>
                            <MenuItem value={"error"}>error</MenuItem>
                            <MenuItem value={"info"}>info</MenuItem>
                        </TextField>
                    ) : prop === 'size' ? (
                        <TextField
                            name={prop}
                            label={prop}
                            // type="color"
                            value={formValues[prop]}
                            onChange={handleInputChange}
                            required={isPropRequired(prop)}
                            helperText={_input[prop].description}
                            select>
                            <MenuItem value={"small"}>small</MenuItem>
                            <MenuItem value={"medium"}>medium</MenuItem>
                            <MenuItem value={"large"}>large</MenuItem>

                        </TextField>
                    ) : prop === 'icon' ? (
                        <FSPFieldIcon2
                            field={{ name: 'icon' }}
                            handleChangeArray={handleChangeIcon}
                            newRecord={formValues && prop && formValues[prop] ? { icon: formValues[prop].value } : { icon: 'Group' }}
                        />
                    ) : prop === 'variant' ? (
                        <TextField
                            name={prop}
                            label={prop}
                            // type="color"
                            value={formValues[prop]}
                            onChange={handleInputChange}
                            required={isPropRequired(prop)}
                            helperText={_input[prop].description}
                            select>
                            <MenuItem value={"contained"}>contained</MenuItem>
                            <MenuItem value={"outlined"}>outlined</MenuItem>
                            <MenuItem value={"text"}>text</MenuItem>

                        </TextField>
                    ) :
                        <TextField
                            name={prop}
                            label={prop}
                            value={formValues && prop && formValues[prop] ? formValues[prop].value : ''}
                            onChange={handleInputChange}
                            required={isPropRequired(prop)}
                        // helperText={_input[prop].description}
                        />
                ))}
            </div>
            <h3>Events:</h3>
            {properties && properties.events && properties.events.map((event) => (
                <div key={uuidv4('2')}>
                    <h4>{event.name}</h4>
                    {event && event.params && event.params.map((param) => (
                        <div key={uuidv4('3')}>
                            <TextField
                                name={`${event.name}_${param.name}`}
                                label={param.name}
                                value={formValues[`${event.name}_${param.name}`]}
                                onChange={handleInputChange}
                                required={isParamRequired(event.name, param.name)}
                                helperText={param.description}
                            />
                        </div>
                    ))}
                </div>
            ))}
            <h3>Methods:</h3>
            {properties && properties.methods && properties.methods.map((method) => (
                <div key={uuidv4('4')}>
                    <h4>{method.name}</h4>
                    {method && method.params && method.params.map((param) => (
                        <div key={uuidv4('5')}>
                            <TextField
                                name={`${method.name}_${param.name}`}
                                label={param.name}
                                value={formValues[`${method.name}_${param.name}`]}
                                onChange={handleInputChange}
                                required={isParamRequired(method.name, param.name)}
                                helperText={param.description}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </form>
    );
};

export default DynamicForm;
