export const pages = {
    "bundles": {
        "id": "bundles",
        "label": "Gyűjtemények",
        "route": "/bundles",
        "table": "questionnaireBundles",
        "type": "Main",
        "icon": "Collections"
    },
    "projects": {
        "id": "projects",
        "label": "Projektek",
        "route": "/projects",
        "table": "projects",
        "type": "Main",
        "icon": "Collections"
    },
    "scales": {
        "id": "scales",
        "label": "Skálák",
        "route": "/scales",
        "table": "scales",
        "type": "Main",
        "icon": "Straighten"
    },
    "scales2": {
        "id": "scales2",
        "label": "Skálák",
        "route": "/scales2",
        "table": "scales",
        "type": "Main",
        "icon": "Straighten"
    },
    "labels": {
        "id": "labels",
        "label": "Címkék",
        "route": "/labels",
        "table": "labels",
        "type": "Main",
        "icon": "LabelSharp"
    },
    "personals": {
        "id": "personals",
        "label": "Demog. kérd",
        "route": "/personals",
        "table": "personals",
        "type": "Main",
        "icon": "ContactPageOutlined"
    },
    "questions": {
        "id": "questions",
        "label": "Kérdések",
        "route": "/questions",
        "table": "questions",
        "type": "Main",
        "icon": "QuestionMark"
    },
    "qtemplates": {
        "id": "qtemplates",
        "label": "Sablonok",
        "route": "/qtemplates",
        "table": "qtemplates",
        "type": "Main",
        "icon": "FeaturedPlayListOutlined"
    },
    "qtemplates2": {
        "id": "qtemplates2",
        "label": "Sablonok",
        "route": "/qtemplates2",
        "table": "qtemplates",
        "type": "Static",
        "component": "QTemplates",
        "icon": "FeaturedPlayListOutlined"
    },
    "qtemplate": {
        "id": "qtemplate",
        "label": "Sablonok",
        "route": "/qtemplate",
        "table": "qtemplates",
        "type": "Static",
        "component": "QTemplate",
        "icon": "FeaturedPlayListOutlined",
        "params": 'id',
        "show": false
    }
}
export default pages