import React from 'react';
import { getProp, getPropArray, getPropArrayParams } from '../../../Utility';
import { tables } from '../../../../data/tables';
import { convertArrayById } from '../../../Utility';
import FSPRowField from '../FSPRowField';
import FSPRowFieldImgStore from './FSPRowFieldImgStore';

const FSPRowFieldImageSelect = ({
    model,
    value,
    valuesLabel,
    metaArray,
    field
}) => {
    const data = {
        model: model,
        value: value,
        valuesLabel: valuesLabel
    }
    const label = getPropArrayParams(metaArray, data,
        ['model', 'value', 'valuesLabel']);
    const f = convertArrayById(tables[model]['fields'], 'name');
    const v = f[valuesLabel];
    return (
        <>  {v.type === 'img_store' ?
            <FSPRowFieldImgStore
                value={label}
                bucket={v.bucket}
            /> :
            <>{label}</>
        }
        </>
    );
};

export default FSPRowFieldImageSelect;