import React from 'react';
import { Box, Drawer, IconButton } from '@mui/material';
import CTreeItem from './components/CTreeItem';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { TreeView } from '@mui/lab';

const CTree = ({
    main,
    nodes,
    createNode,
    updateNode,
    deleteNode,
    active,
    setActive
}) => {
    const [expanded, setExpanded] = React.useState(Object.keys(nodes));
    const [selected, setSelected] = React.useState([]);

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleSelect = (event, nodeIds) => {
        console.log(nodeIds);
        setActive(nodeIds);
    };

    const handleExpandClick = () => {
        setExpanded((oldExpanded) =>
            oldExpanded.length === 0 ? ['1', '5', '6', '7'] : [],
        );
    };

    const handleSelectClick = () => {
        setSelected((oldSelected) =>
            oldSelected.length === 0 ? ['1', '2', '3', '4', '5', '6', '7', '8', '9'] : [],
        );
    };
    return (
        <TreeView
            // aria-label="controlled"
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            expanded={expanded}
            // selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            multiSelect
        >
            <CTreeItem
                node={main}
                main={main}
                nodes={nodes}
                updateNode={updateNode}
                deleteNode={deleteNode}
                createNode={createNode}
                active={active}
                setActive={setActive}
            />
        </TreeView>
    );
};

export default CTree;