export const table =
{
	"fields": [
		{
			"indexFields": [],
			"helper": "Név",
			"updatefields": [],
			"required": "true",
			"fields": [],
			"label": "Név",
			"type": "text",
			"name": "name",
			"_id": "item_1692189510158"
		},
		{
			"type": "text",
			"required": "true",
			"indexFields": [],
			"updatefields": [],
			"fields": {},
			"name": "description",
			"helper": "Leírás",
			"label": "Leírás",
			"_id": "item_1692269196248"
		}
	],
	"collection": "labels",
	"_id": "labels",
	"updatefields": [
		"name",
		"description"
	],
	"name": "Címkék",
	"title_create": "Új címke",
	"type": "array",
	"meta": [
		"constants"
	],
	"title_index": "Címkék",
	"icon": "LabelSharp",
	"title_update": "Címke módosítása",
	"updatedAt": {
		"seconds": 1692192807,
		"nanoseconds": 189000000
	},
	"indexFields": [
		"name",
		"description"
	],
	"ui": "general",
	"id": "labels"
}
export default table