export const pages = {
    "groups": {
        "id": "groups",
        "label": "Csoportok",
        "route": "/groups",
        "table": "groups",
        "type": "Main",
        "icon": "Diversity2"
    },
    "users": {
        "id": "users",
        "label": "Résztvevők",
        "route": "/users",
        "table": "users",
        "type": "Main",
        "icon": "PeopleAlt"
    },
    "groupmembers": {
        "id": "groupmembers",
        "label": "Csoporttagok",
        "route": "/groupmembers",
        "table": "groupmembers",
        "type": "Main",
        "icon": "GroupAdd"
    },
    "groupsubscribes": {
        "id": "groupsubscribes",
        "label": "Feliratkozások",
        "route": "/groupsubscribes",
        "table": "groupsubscribes",
        "type": "Main",
        "icon": "Subscriptions"
    },
    "groupadmins": {
        "id": "groupadmins",
        "label": "Oktatók",
        "route": "/admins",
        "table": "groupadmins",
        "type": "Main",
        "icon": "AdminPanelSettings"
    },
}
export default pages