import React from 'react';
import { Avatar, CircularProgress, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { Comment } from '@mui/icons-material';
import { getProp, getPropArray } from '../../common/Utility';
import CardWidget from '../Widgets/CardWidget';
import CardsWidget from '../Widgets/CardsWidget';

const View = ({ children, user, id, environmentVariables }) => {
    const model = getProp(environmentVariables, 'myGroups');
    const model2 = getProp(environmentVariables, 'subscribed');
    const view = getProp(environmentVariables, 'view');
    const uid = getPropArray(environmentVariables, ['profile', 'uid']);
    const name = getPropArray(environmentVariables, ['profile', 'name']);


    const handleDelete = () => {
        console.log('Delete clicked');
    };

    const handleEdit = () => {
        console.log('Edit clicked');
    };

    const handleView = () => {
        console.log('View clicked');
    };

    return (
        <>
            <h1>Csoportjaim</h1>

            <CardsWidget
                cards={model}
                Widget={CardWidget}
                values={{
                    avatar: ['data', 'Profilkép'],
                    title: ['data', 'name'],
                    subtitle: ['data', 'Oktató'],
                    onDelete: handleDelete,
                    onEdit: handleEdit,
                    onView: handleView
                }}
            />
            <h1>Feliratkozások</h1>

            <CardsWidget
                cards={model2}
                Widget={CardWidget}
                values={{
                    avatar: ['data', 'Profilkép'],
                    title: ['data', 'name'],
                    subtitle: ['data', 'Oktató'],
                    onDelete: handleDelete,
                    onEdit: handleEdit,
                    onView: handleView
                }}
            />
            <div>
                {children}
            </div>
        </>
    );
};

export default View;