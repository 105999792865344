import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Alert, BottomNavigation, BottomNavigationAction } from "@mui/material";
import backgroundImage from "../../PR_VET2.png";
import { logInWithEmailAndPassword_backup, logout_backup } from "../../firebase_backup";
import FSPFieldIcon from "../../common/FSPCrud/FSPForm/FSPField/FSPFieldIcon";
import FSPIcon from "../../layouts/Main/FSPIcon";

const handleSubmit2 = (event) => {
  event.preventDefault();
  const data = new FormData(event.currentTarget);
  console.log({
    email: data.get("email"),
    password: data.get("password"),
  });
  logInWithEmailAndPassword(data.get("email"), data.get("password"));

};


function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [loginerror, setError] = useState(null);
  const [loginerror2, setError2] = useState(null);
  const navigate = useNavigate();
  const [selectedApp, setSelectedApp] = useState(localStorage.getItem('selectedApp') ?? 'firstApp');
  const storedApps = JSON.parse(localStorage.getItem('firebaseApps'));

  const setApp = (app) => {
    setSelectedApp(app);
    localStorage.setItem('selectedApp', app);
  }

  useEffect(() => {
    console.log('user', user)
    console.log('loading', loading)
    console.log('error', error)
    if (loading) {
      console.log('ERROR')
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      console.log('LOGIN2')
      handleSubmit2();
    }
    if (!user) {
      console.log('LOGOUT 2')
      logout_backup();
    }
    if (user) navigate("/");
  }, [user, loading]);

  useEffect(() => {
    console.log('ERROR', error)
  }, [error]);



  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log('FIREBASE 1');
    setEmail(data.get("email"));
    setPassword(data.get("password"));
    try {
      await logInWithEmailAndPassword(data.get("email"), data.get("password"));
    } catch (err) {
      console.log('FIREBASE 2 HIBA!!!!!!', err);
      setError(err);
    }
    finally {
      console.log('FIREBASE 1 OK ');
    }

    // console.log('FIREBASE AUTH4');
    // try {
    //   console.log('FIREBASE AUTH44');
    //   await logInWithEmailAndPassword_backup(data.get("email"), data.get("password"));
    //   console.log('FIREBASE AUTH5');
    // } catch (err) {
    //   console.log('FIREBASE AUTH6');
    //   setError(err);
    // } finally {
    //   console.log('FIREBASE AUTH7');
    // }
  };
  const handleSubmit2 = async () => {

    console.log('FIREBASE 2');
    try {
      await logInWithEmailAndPassword_backup(email, password);
    } catch (err) {
      console.log('FIREBASE 2 HIBA!!!!!!', err);
      setError2(err);
    } finally {
      console.log('FIREBASE 2 OK ');
    }
  };
  return (<>
    {/* <Background src={backgroundImage} alt="background" /> */}
    <Container component="main" maxWidth="xs">

      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          // alt={translate('education_2030')}
          // className={classes.logo}
          src={'/logo.png'}
          width={100}
        />
        <Typography component="h1" variant="h5">
          Bejelentkezés
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email cím"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Jelszó"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {storedApps &&
            <BottomNavigation
              showLabels
              value={selectedApp}
              onChange={(event, newValue) => {
                setApp(newValue)
              }}
            >
              {Object.keys(storedApps).map((appName, index) => (
                <BottomNavigationAction
                  key={index}
                  label={storedApps[appName].label}
                  value={appName}
                  icon={
                    <FSPIcon icon={storedApps[appName].icon} active={selectedApp == appName} />
                  } />

              ))}
            </BottomNavigation>
          }
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Emlékezzen"
          />
          {loginerror ? (
            <Alert
              variant='filled'
              severity='error'
            // className={classes.standardError}
            >
              Invalid Email or Password
            </Alert>
          ) : null}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Bejelentkezés
          </Button>
          {/* <Button
            fullWidth
            onClick={signInWithGoogle}
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
          >
            Bejelentkezés Google fiókkal
          </Button> */}
          <Grid container>
            <Grid item xs>
              <Link to="/reset" variant="body2">
                Elfelejtett jelszó
              </Link>
            </Grid>
            <Grid item>
              <Link to="/register" variant="body2">
                {"Regisztráció"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  </>
  );
}


export default Login;

const Background = styled.img`
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: -1;
`;