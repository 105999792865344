import React, { useState } from 'react';
import { setProps, setEvents } from './Utility';
import Model from './Model';

export const HTMLComponent = ({
    children,
    node,
    nodes,
    active,
    setActive,
    store,
    setStore,
    isOver,
    dropRef
}) => {
    const data = nodes[node];
    const properties = data.properties;
    const { setProps, setEvents, onClick } = Model({
        data: data,
        node: node,
        setActive: setActive,
        store: store,
        setStore: setStore,
    });
    console.log('PROPS222', data)
    const style = setProps({
        p: ['display', 'flex', 'flex-flow', 'border', 'border-width', 'float',
            'top', 'bottom', 'left', 'right', 'color', 'background',
            'height', 'min-height', 'width'],
        data: data.properties
    });
    const events = setEvents({
        store: store,
        properties: properties
    });
    console.log('events1', events);
    var style2 = { ...style };
    if (active == node) {
        style2 = { ...style, 'border-style': 'dashed' };
        style2 = { ...style2, 'border-color': '#f00' };
        style2 = { ...style2, 'border': '2px' };
    }
    const style3 = { ...style2, 'border-style': 'dashed', 'border-color': '#00f' };

    // const onClick = (e) => {
    //     e.stopPropagation();
    //     setActive(node);
    // }

    let label = '';
    if (properties && properties._input && properties._input.label) {
        if (properties._input.label.data === 'store')
            label = store[properties._input.label.value];
        else
            label = properties._input.label.value;
    } else {
        label = '';
    }
    console.log('PARAMS2', style2)
    console.log('PARAMS3', properties && properties._input && properties._input.label ? properties._input.label : data)

    return (
        <>
            {React.createElement(
                properties.MUIcomponent,
                {
                    style: style2,
                    ref: dropRef,
                    onClick: (e) => onClick(e),
                }
                ,
                <>
                    {children}
                    {isOver && <div>Drop Here3!</div>}
                    {label}
                </>
            )
            }
        </>
    );
}

