import React from 'react';
import FSPRowField from './FSPRowField';
import { getProp } from '../../Utility';

const FSPRowFieldsToJson = ({
    record,
    metaArray,
    fieldsArray,
    table,
    view
}) => {
    const indexFields = getProp(table, 'indexFields');
    
    const componentProps = indexFields ?
        indexFields.reduce((acc, fieldName) => {
            acc[fieldName] = <FSPRowField
                key={fieldName}
                fieldName={fieldName}
                index={fieldName}
                record={record}
                metaArray={metaArray}
                fieldsArray={fieldsArray}
                view={view}
            />
            return acc;
        }, {}) : {}
    
    return componentProps;
};

export default FSPRowFieldsToJson;