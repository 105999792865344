import React, { useEffect, useState } from 'react';
import FSPCrud from '../../FSPCrud';
import { convertObjectToArray, getItemId } from '../../../Utility';

const FSPFormFieldJson = ({
    field,
    handleChangeTable,
    meta,
    metaArray,
    value,
    view,
    parentRecord
}) => {
    const [jsonData, setJsonData] = useState(value ?? [{ name: 'table' }]);
    const createElement1 = (newRecord) => {
        const jsonData2 = Array.isArray(jsonData) ? jsonData :
            (typeof jsonData == 'object' ? convertObjectToArray(jsonData) : []);
        if (newRecord._id != undefined) {
            var filteredItems = jsonData2.map((i) => i._id === newRecord._id ? newRecord : i);
            setJsonData([...filteredItems]);
            handleChangeTable(field.name, [...filteredItems])
        } else {
            const n = { ...newRecord, _id: getItemId() }
            setJsonData([...jsonData2, n]);
            handleChangeTable(field.name, [...jsonData2, n])
        }
    }

    const deleteElement1 = (id) => {
        var filteredItems = jsonData.filter(function (item, index) {
            return (item != id);
        });
        setJsonData([...filteredItems]);
        handleChangeTable(field.name, [...filteredItems])
    }

    const updateElement1 = (id, record) => { }
    useEffect(() => {

    }, [jsonData]);
    
    return (
        <div>
            <FSPCrud
                model={jsonData}
                createElement={createElement1}
                deleteElement={deleteElement1}
                updateElement={updateElement1}
                // table={field}
                table={!Array.isArray(field.table)
                    ? (field.table ? field.table : field)
                    : (field.table.length > -1 ? field.table[0] : null)}
                meta={meta}
                metaArray={metaArray}
                view={view}
                parentRecord={parentRecord}
            />
        </div>
    );
};

export default FSPFormFieldJson;