import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./UI/Authentication/Login";
import Register from "./UI/Authentication/Register";
import Reset from "./UI/Authentication/Reset";
import Dashboard from "./Dashboard";
import Main from "./Main";
// import AppData from './AppConfig.json';
// import Teszt from "./Teszt";
import { convertArrayById } from "./common/Utility";
import { AppDataArray } from "./data/appications";
import { ContextProvider2 } from "./services/fbcontext";
// import { Context, ContextProvider1 } from './services/context';
import { Context2 } from './services/fbcontext';
import { useMemo, useState } from "react";
import { GroupList } from "./UI/GroupList/GroupList";
import { Teszt } from "./UI/Teszt/Teszt";
import CustomComponent from "./common/CustomComponent";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const Switch = props => {
  const { test, children } = props
  // filter out only children with a matching prop
  return children.find(child => {
    return child.props.value === test
  })
}

function App() {
  const { environmentVariables, setEnvironmentVariable } = Context2;
  // const ActiveAppData = AppDataArray[environmentVariables && environmentVariables.selectedApp ? environmentVariables.selectedApp : 0];
  console.log('AppDataArray', AppDataArray)
  const aaa = [];
  Object.keys(AppDataArray).forEach((key, index) => (
    Object.keys(AppDataArray[key].pages).forEach((page, index2) => (
      aaa[page] = key
    ))

  ))
  const path = (p) => {
    const params = p.params ? '/:' + p.params : ''
    return p.route + params;
  }
  console.log('aaa', aaa)
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {/* <ContextProvider1> */}
        <ContextProvider2>
          <Router>
            <Routes>
              {AppDataArray && Object.keys(AppDataArray).map((key, index) => (
                Object.keys(AppDataArray[key].pages).map((page, index2) => (
                  <Route exact
                    key={index + '-' + index2}
                    path={path(AppDataArray[key].pages[page])}
                    element={
                      // <Switch test={someTest}>
                      //   <div value={false}>Will display if someTest is false</div>
                      //   <div value={true}>Will display if someTest is true</div>
                      // </Switch>
                      AppDataArray[key].pages[page].type === 'Main' ?
                        <Main app={{ ...AppDataArray[key], key: key }} table={AppDataArray[key].tables[AppDataArray[key].pages[page].table]} />
                        : <CustomComponent component={AppDataArray[key].pages[page].component} app={{ ...AppDataArray[key], key: key }} table={AppDataArray[key].tables[AppDataArray[key].pages[page].table]} />
                    }
                  />
                ))

              ))}
              {/* <Route exact path="/grouplist" element={<GroupList app={{ ...AppDataArray['group'], key: 'group' }} />} />
              <Route exact path="/grouplist/:id" element={<GroupList app={{ ...AppDataArray['group'], key: 'group' }} />} /> */}
              <Route exact path="/main" element={<Main />} />
              <Route exact path="/signin" element={<Login />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/reset" element={<Reset />} />
              <Route exact path="/" element={<Teszt />} />
              {/* <Route exact path='/qtemplate/:id' component={<CustomComponent component="Qtemplate" />} /> */}
            </Routes>
          </Router>
        </ContextProvider2>
        {/* </ContextProvider1> */}
      </DndProvider>
    </>
  );
}

export default App;
