import React, { useEffect, useState } from 'react';
import FSPCrud from '../../FSPCrud';
import { convertObjectById, getItemId } from '../../../Utility';
import FSPCrud2 from '../../FSPCrud2';

const FSPFormFieldJson2 = ({
    field,
    handleChangeTable,
    meta,
    metaArray,
    value,
    view,
    parentRecord
}) => {
    const [jsonData, setJsonData] = useState(value ?? { name: 'table' });
    const createElement1 = (newRecord) => {
        if (newRecord._id != undefined) {
            var filteredItems = Object.keys(jsonData).map((i) => jsonData[i]._id === newRecord._id ? newRecord : jsonData[i]);
            const a = convertObjectById(filteredItems, 'name');
            setJsonData(a);
            handleChangeTable(field.name, a)
        } else {
            const _id = newRecord.name;
            setJsonData({ ...jsonData, [_id]: newRecord });
            handleChangeTable(field.name, { ...jsonData, [_id]: newRecord })
        }
    }

    const deleteElement1 = (id) => {
        var filteredItems = { ...jsonData }
        var filteredItems = Object.keys(jsonData).filter(function (item, index) {
            return (jsonData[item] != id);
        });
        var filteredItems2 = {};
        filteredItems.forEach(element => {
            filteredItems2[element] = jsonData[element];
        });
        setJsonData(filteredItems2);
        handleChangeTable(field.name, filteredItems2)
    }

    const updateElement1 = (id, record) => { }
    useEffect(() => {

    }, [jsonData]);

    return (
        <div>
            <FSPCrud2
                model={jsonData}
                createElement={createElement1}
                deleteElement={deleteElement1}
                updateElement={updateElement1}
                // table={field}
                table={!Array.isArray(field.table)
                    ? (field.table ? field.table : field)
                    : (field.table.length > -1 ? field.table[0] : null)}
                meta={meta}
                view={view}
                metaArray={metaArray}
                parentRecord={parentRecord}
            />
        </div>
    );
};

export default FSPFormFieldJson2;