import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';

const FSPDeleteDialog = ({
    open,
    setShowDeleteDialog,
    deleteElement,
    showDeleteDialog
}) => {
    return (
        <Dialog
            fullWidth={true}
            open={open}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
                Törölni kívánja az elemet?
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setShowDeleteDialog(null);
                    }}
                    color="primary"
                    autoFocus>
                    Mégsem
                </Button>
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        deleteElement(showDeleteDialog.id ?? showDeleteDialog);
                        setShowDeleteDialog(null);
                    }}
                    color="primary"
                    variant="contained"
                    autoFocus>
                    Törlés
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FSPDeleteDialog;