export const table =
{
	"title_create": "Kérdés készítése",
	"ui": "general",
	"indexFields": [
		"name",
		"Skála",
		"labels"
	],
	"name": "Kérdések",
	"collection": "questions",
	"title_update": "Kérdés módosítáés",
	"icon": "QuestionMark",
	"title_index": "Kérdések",
	"meta": [
		"scales",
		"labels"
	],
	"type": "array",
	"_id": "questions",
	"updatedAt": {
		"seconds": 1692189609,
		"nanoseconds": 733000000
	},
	"fields": [
		{
			"name": "name",
			"label": "Kérdés",
			"type": "text",
			"required": "true",
			"helper": "Kérdés",
			"updatefields": [],
			"fields": [],
			"_id": "item_1692189239611",
			"indexFields": []
		},
		{
			"helper": "Skála",
			"valuesLabel": "name",
			"type": "document_select",
			"required": "true",
			"label": "Skála",
			"_id": "item_1692189276809",
			"indexFields": [],
			"valuesKey": "id",
			"updatefields": [],
			"fields": [],
			"name": "Skála",
			"model": "scales"
		},
		{
			"type": "document_multiselect",
			"label": "Címkék",
			"model": "labels",
			"_id": "item_1692189586337",
			"required": "true",
			"helper": "labels",
			"name": "labels",
			"updatefields": [],
			"fields": [],
			"valuesKey": "id",
			"valuesLabel": "name",
			"indexFields": []
		}
	],
	"updatefields": [
		"name",
		"Skála",
		"labels"
	],
	"id": "questions"
}
export default table