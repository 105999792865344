export const field_default = ({ name, required, label,
    autoHide, addId, defaultValue
}) => {
    return {
        "required": required,
        "name": name,
        "helper": label,
        "q2": label,
        "label": label,
        "type": "default",
        "autoHide": autoHide,
        "addId": addId,
        "default": defaultValue
    }
}