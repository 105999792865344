import React, { useState } from 'react';
import { setProps } from './Utility';
import { Button, Icon, IconButton } from '@mui/material';
import * as Icons from '@mui/icons-material';;

export const iconComponent = ({
    children,
    main,
    node,
    nodes,
    createNode,
    updateNode,
    deleteNode,
    active,
    setActive,
    classes
}) => {
    const data = nodes[node];
    const style = setProps({
        p: ['icon', 'size', 'color'],
        data: data.properties
    })
    const stop = (e) => {
        e.stopPropagation();
        setActive(node);
    }
    const IconComponents = Icons[style['icon']];
    return (
        (<span className={'icon_component'} onClick={(e) => stop(e)}>
            <Icon baseClassName="material-icons-two-tone">{style['icon']}</Icon>
        </span>)
    )
}