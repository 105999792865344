import React, { useContext, useEffect, useState } from 'react';
import { Context2 as Context } from '../../services/fbcontext';

const Model = (table_groups) => {
    const { environmentVariables, setEnvironmentVariable,
        queryDocumentEV,
        queryDocumentsEV
    } = React.useContext(Context);

    const getMyGroups = ({ user }) => {
        console.log('user', user);
        if (user) {
            queryDocumentsEV({
                key: 'myGroups',
                table: 'groups',
                filter:
                {
                    field: "Members",
                    operation: 'array-contains',
                    data: user.uid
                }
            });
        }
    }
    const getSubscribed = ({ user }) => {
        console.log('user', user);
        if (user) {
            queryDocumentsEV({
                key: 'subscribed',
                table: 'groups',
                filter:
                {
                    field: "subscribed",
                    operation: 'array-contains',
                    data: user.uid
                }
            });
        }
    }

    const getProfile = ({ user }) => {
        if (user) {
            queryDocumentEV({ key: 'profile', table: 'users', id: user.uid });
        }
    }

    return {
        getMyGroups, getProfile, getSubscribed
    };
};

export default Model;