import React from 'react';
import { Grid } from '@mui/material';
import CardWidget from './CardWidget';
import { getPropArray2 } from '../../common/Utility';

const CardsWidget = ({ cards, Widget, values }) => {
    console.log('cards', cards);
    console.log('Widget', Widget);
    console.log('values', values);
    const value = (item) => {
        console.log('item', item);
        const a = Object.keys(values).reduce((acc, key) => {
            acc[key] = Array.isArray(values[key])
                ? getPropArray2(item, values[key])
                : values[key];
            return acc;
        }, {})
        console.log('a', a);
        return a;
    }
    console.log('model', cards)
    return (
        <Grid container spacing={2}>
            {cards && cards.map((card, index) => (
                <Grid item xs={12} md={4} key={index}>
                    <Widget {...value(card)} />
                </Grid>
            ))}
        </Grid>
    );
};

export default CardsWidget;
