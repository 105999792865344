import React from 'react';
import styled from 'styled-components';
// import  CustomComponents  from './CustomComponents';
import * as   CustomComponents from './CustomComponents';

const CustomComponent = ({ children = [], component = 'GroupList', declarations = {}, ...params }) => {
    const ccomponent = CustomComponents[component];
    const StyledComponent = styled(ccomponent)(declarations);
    return (
        <StyledComponent {...params}>
            {children}
        </StyledComponent>
    );
};

export default CustomComponent;