export const value_select_dynamic = ({
    name, required, label, model, valuesKey, valuesLabel,
    valuesField, _document, autoHide, addId, defaultValue
}) => {
    return {
        "required": required,
        "name": name,
        "helper": label,
        "q2": label,
        "label": label,
        "model": model,
        "valuesKey": valuesKey,
        "valuesLabel": valuesLabel,
        "valuesField": valuesField,
        "_document": _document,
        "autoHide": autoHide,
        "addId": addId,
        "type": "value_select_dynamic",
        "default": defaultValue
    }
}