import { value_select_static } from "../fields/value_select_static"

export const table =
{
	"title_index": "Stílusok",
	"collection": "styles",
	"icon": "Style",
	"updatefields": [
		"name",
		"values"
	],
	"fields": [
		{
			"helper": "name",
			"default": "név",
			"type": "text",
			"_id": "item_1682759758168",
			"name": "name",
			"label": "name"
		},
		{
			"type": "json",
			"title_index": "Stílusok",
			"label": "values",
			"_id": "item_1682759778132",
			"fields": [
				{
					"label": "Layout típus",
					"model": "constants",
					"valuesField": "values",
					"type": "value_select_static",
					"default": "mobile",
					"valuesLabel": "label",
					"document": "layout_tipusok",
					"valuesKey": "value",
					"name": "layout",
					"_id": "item_1682792085041",
					"helper": "Layout típus"
				},
				{
					"default": "html",
					"helper": "CSS selector típusok",
					"model": "constants",
					"_id": "item_1682792143335",
					"document": "css_selector_tipusok",
					"type": "value_select_static",
					"valuesField": "values",
					"valuesLabel": "label",
					"valuesKey": "value",
					"name": "type",
					"label": "CSS selector típusok"
				},
				{
					"type": "jsoninput",
					"name": "values",
					"_id": "item_1682792191155",
					"helper": "values",
					"label": "values",
					"default": {
						"selector": "TableRow",
						"declarations": {}
					}
				}
			],
			"helper": "values",
			"title_create": "Új stílus",
			"title_update": "Stílus módosítása",
			"name": "values",
			"indexFields": [
				"layout",
				"type"
			],
			"updatefields": [
				"layout",
				"values",
				"type"
			]
		}
	],
	"indexFields": [
		"name",
		"_id"
	],
	"meta": [
		"constants"
	],
	"_id": "styles",
	"name": "Stílusok",
	"updatedAt": {
		"seconds": 1683927179,
		"nanoseconds": 329000000
	},
	"title_create": "Új stílus",
	"title_update": "Stílus módosítása",
	"id": "styles"
}
export default table