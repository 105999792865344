import { Delete, Edit } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';
import CTableRow from '../Layout/CTableRow';
import FSPRowFields from './FSPRowFields';

const FSPRowButtons = ({
    setNewRecord,
    setShowNewDialog,
    setShowDeleteDialog,
    record,
    recordData
}) => {
    return (
        <TableCell>
            <IconButton
                type='submit'
                variant="contained"
                color="primary"
                onClick={(event, id) => {
                    event.preventDefault();
                    setShowDeleteDialog(record)
                }}>
                <Delete />
            </IconButton>
            <IconButton
                type='submit'
                variant="contained"
                color="primary"
                onClick={(event) => {
                    event.preventDefault();
                    if (record.id != undefined)
                        setNewRecord({ ...recordData, id: record.id })
                    else
                        setNewRecord({ ...recordData })
                    setShowNewDialog(true);
                }}>
                <Edit />
            </IconButton>
        </TableCell>
    );
};

export default FSPRowButtons;