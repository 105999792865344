import React, { useState } from 'react';
import { ref, getDownloadURL, getStorage, uploadBytes } from 'firebase/storage';
import { db, auth, storage } from "../../../firebase";

const ImagesBucket = () => {
    const [images, setImages] = useState([]);

    const fetchImageAsBlob = (imageUrl) => {
        return new Promise((resolve, reject) => {
            fetch(imageUrl)
                .then((response) => response.blob())
                .then((blob) => resolve(blob))
                .catch((error) => reject(error));
        });
    };

    const setImage = ({ bucket, value }) => {
        if (bucket && value) {
            const id = bucket + '/' + value;
            const storageRef = ref(storage, id);
            if (!images[id]) {
                getDownloadURL(storageRef)
                    .then((url) => {
                        fetchImageAsBlob(url)
                            .then((blob) => {
                                // Itt dolgozhatsz a blob objektummal
                                console.log('ii---', blob);
                                // imageUrl = URL.createObjectURL(new Blob(blob));
                                // imageUrl = URL.createObjectURL(blob);
                                // setImage(URL.createObjectURL(blob));
                                setImages((prevState) => ({
                                    ...prevState,
                                    [id]: URL.createObjectURL(blob)
                                }));
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch((error) => {
                        console.error('Hiba történt a kép letöltésekor:', error);
                    });
            } else {
                console.log('már megvan nyugi!!!');
            }
        } else {
            console.log('üres');
        }

    };
    return {
        images,
        setImage,
        setImages
    };
};

export default ImagesBucket;