import React from 'react';
import CHtmlElement from '../../common/CHtmlElement';
import { Box, ButtonBase, Card, CardContent, CardMedia, IconButton, Typography } from '@mui/material';
import { Abc, PlayArrow, SkipNext, SkipPrevious } from '@mui/icons-material';
import { useTheme } from 'styled-components';
// import './style.css';
// import './style2.css';
import ProfileCard from './ProfilCard';
import Loadings from '../../common/SubscriptionsCleaner/Loadings';
import IconAutocomplete from '../../common/IconAutoComplete';
import DynamicForm from '../../common/miu/DynamicForm';
import { BottomNavigation } from '../../common/miu/components/BottomNavigation';
import { Button } from '../../common/miu/components/Button';
import Badge from '../../common/miu/components/Badge';
import BottomNavigationAction from '../../common/miu/components/BottomNavigationAction';
import { convertArrayById, getItemId } from '../../common/Utility';
import CardWidget from '../Widgets/CardWidget';

const layout = {
    "mobile": {
        "type": "custom", //miu, html, custom
        "selector": "Mobile",
        "declarations": {
            "backgroundColor": '#f00',
        }
    },
    "desktop": {
        "type": "custom",//miu, html, custom
        "selector": "Main",
        "declarations": {
            "backgroundColor": '#0f0',
        }
    }
}
const View = ({ children, app, loading, loading_backup,
    user, user_backup, view = 'desktop', ...params
}) => {
    const theme = useTheme();

    const handleDelete = () => {
        console.log('Delete clicked');
    };

    const handleEdit = () => {
        console.log('Edit clicked');
    };

    const handleView = () => {
        console.log('View clicked');
    };

    return (
        <CHtmlElement app={app} layout={layout} view={view}>
            <div>
                {view == 'mobile' ? 'Mobile' : 'Desktop'}
            </div>
            <CardWidget
                title="Csoport neve"
                avatar="url/to/avatar"
                subtitle="Oktató neve"
                onDelete={handleDelete}
                onEdit={handleEdit}
                onView={handleView}
            />
            <CardWidget
                title="Csoport neve"
                avatar="url/to/avatar"
                subtitle="Oktató neve"
                onDelete={handleDelete}
                onEdit={handleEdit}
                onView={handleView}
            />
            <Loadings showLabel={true} />
            <IconAutocomplete />
            {loading ? 'Loading...' : 'OK:'}
            {user ? 'Bejelentkezve a rendszerbe' : 'Nincs bejelentkezve a rendszerbe'}
            {loading_backup ? 'Loading...' : 'OK:'}
            {user_backup ? 'Bejelentkezve a backup rendszerbe' : 'Nincs bejelentkezve a backup rendszerbe'}
            <DynamicForm key={getItemId()} component={Button}>
                <>Teszt</><Abc />
            </DynamicForm>
            <div className="App">
                <header className="App-header">
                    <h1>{params.label}</h1>
                </header>
                <Card sx={{ display: 'flex' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h5">
                                Live From Space
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                Mac Miller
                            </Typography>
                        </CardContent>
                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                            <IconButton aria-label="previous">
                                <SkipNext />
                            </IconButton>
                            <IconButton aria-label="play/pause">
                                <PlayArrow sx={{ height: 38, width: 38 }} />
                            </IconButton>

                            <IconButton aria-label="next">
                                <SkipPrevious />
                            </IconButton>
                        </Box>
                    </Box>
                    <CardMedia
                        component="img"
                        sx={{ width: 151 }}
                        image="https://mui.com/static/images/cards/live-from-space.jpg"
                        alt="Live from space album cover"
                    />
                </Card>


                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="card card-1">
                                <h3>Ionic Native</h3>
                                <p>A curated set of   ES5/ES6/TypeScript wrappers for plugins to easily add any native functionality to your Ionic apps.</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card card-2">
                                <h3>UI Components</h3>
                                <p>Tabs, buttons, inputs, lists, cards, and more! A comprehensive library
                                    of mobile UI components, ready to go.</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card card-3">
                                <h3>Theming</h3>
                                <p>Learn how to easily customize and modify your app’s design to fit your
                                    brand across all mobile platform styles.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <ProfileCard
                    name="Rita Correia"
                    age="32"
                    city="London"
                    followers="80K"
                    likes="803K"
                    photos="1.4K"
                ></ProfileCard>
            </div>

            {children}
        </CHtmlElement>
    );
};

export default View;