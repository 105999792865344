import { TableFooter, TableRow } from '@mui/material';
import React from 'react';
import TablePagination from '@mui/material/TablePagination';

const FSPTableFooter = ({
    rowsPerPage,
    model,
    handleChangePage,
    handleChangeRowsPerPage,
    page
}) => {
    return (
        <TableFooter>
            <TablePagination                // style={{ width: '500px', display: 'flex' }}
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: 1000 }]}
                colSpan={3}
                count={Object.keys(model).length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableFooter>
    )
}

export default FSPTableFooter;