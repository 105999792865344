import React, { useEffect, useState } from 'react';

const FSPRowFieldImg = ({ value }) => {

    return (
        <>
            {value && <img id='myimg' style={{ maxWidth: "150px", maxHeight: "150px" }} src={value} />}
        </>
    );
};

export default FSPRowFieldImg;