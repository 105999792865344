import React from 'react';
import { TableRow, Avatar, Card, CardHeader, IconButton, TableBody, TableCell, CardMedia, Typography, Collapse, CardContent, CardActions, ListItem, ListItemAvatar, ListItemText, Divider } from '@mui/material';
import { Comment, ExpandMore, Favorite, MoreVert, Share } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import { CardContainer, CardTitle, CardBody } from '../../../styles';
// import { useStyles } from '../../styles';;

// const useStyles = makeStyles({
//     card: {
//       maxWidth: 345,
//       margin: "auto",
//       marginBottom: 16,
//     },
//   });
const CTableRow = ({ children, view = 'desktop', record, table, ...params }) => {

    return (
        <>
            {view == 'mobile' ?
                <ListItem
                    secondaryAction={
                        <IconButton aria-label="comment">
                            <Comment />
                        </IconButton>
                    }
                    params
                >
                    <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                    </ListItemAvatar>
                    <ListItemText>
                        {children}
                    </ListItemText>
                    <Divider variant="inset" component="li" />
                </ListItem>
                : <CardContainer>
                    <CardTitle></CardTitle>
                    <CardBody>
                        {children}
                    </CardBody>
                </CardContainer>
            }
        </>
    );
};

export default CTableRow;