import { TextField } from '@mui/material';
import React from 'react';

const FSPFieldImg = ({
    field,
    handleChangeArray,
    value
}) => {

    const HandleFileChange2 = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const content = e.target.result;
                handleChangeArray(field.name, content);
                console.log(content);
            };
            reader.readAsDataURL(selectedFile);
            //reader.readAsText(selectedFile);
        }
    };

    return (
        <div>
            <div>
                {field.label}
            </div>
            <input type="file" onChange={HandleFileChange2} />
            {value && <img id='myimg' src={value} style={{ maxWidth: "150px", maxHeight: "150px" }} />}
        </div>
    );
};

export default FSPFieldImg;