import {
    getFirestore, collection, setDoc, getDocs, onSnapshot,
    deleteDoc, addDoc, doc, getDoc, query, where, documentId
} from "firebase/firestore";
import { db, auth, storage } from "../../firebase";

const QueriesEV = ({
    setLoading,
    environmentVariables,
    setEnvironmentVariable,
}) => {

    const queryDocumentEV = ({ key, table, id }) => {
        setLoading({ key: key, status: true })
        if (key && table && id) {
            if (!environmentVariables[key]) {
                const userRef = doc(db, table, id);
                getDoc(userRef).then((snap) => {
                    setEnvironmentVariable({ key: key, value: snap.data() });
                    setLoading({ key: key, status: false })
                })
            } else {
                console.log('már megvan nyugi!!!');
            }
        }
    }

    const queryDocumentsEV = ({ key, table, filter }) => {
        setLoading({ key: key, status: true })
        if (key && table && filter) {
            if (!environmentVariables[key]) {
                const q = query(collection(db, table), where(
                    filter.field === 'uid' ? documentId() : filter.field,
                    filter.operation,
                    filter.data
                ));
                getDocs(q).then((snap) => {
                    setEnvironmentVariable({ key: key, value: snap.docs });
                    setLoading({ key: key, status: false })
                })
                    .catch((err) => {
                        console.error('FS error', err);
                        throw err;
                    });
            } else {
                console.log('már megvan nyugi!!!');
            }
        }
    }

    return {
        queryDocumentEV,
        queryDocumentsEV,
    };
};

export default QueriesEV;