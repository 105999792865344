import { document_multiselect } from "../fields/document_multiselect"
import { document_select } from "../fields/document_select"
import { field_json } from "../fields/field_json"
import { field_text } from "../fields/field_text"
import { value_select_static } from "../fields/value_select_static"
import table_ui_sub from "./table_ui_sub"
import table_ui_sub2 from "./table_ui_sub2"
export const table =
{
    "title_update": "Elem szerkesztése",
    "collection": "ui",
    "icon": "Devices",
    "fields": [
        field_text({
            name: "id", label: "id", required: true, default: "container",
            autoHide: []
        }),
        field_text({
            name: "widget", label: "widget", required: true, defaultValue: "CardWidget",
            autoHide: []
        }),
        field_text({
            name: "name", label: "name", required: true, default: "container",
            autoHide: []
        }),
        document_select({
            name: "model", label: "model11(document_select)", required: true,
            model: "tables", valuesKey: "id", valuesLabel: "name",
            autoHide: []
        }),
        document_select({
            name: "style", label: "style(document_select)", required: true,
            model: "styles", valuesKey: "id", valuesLabel: "name",
            autoHide: []
        }),
        document_multiselect({
            name: "meta", label: "Metaadatok", required: true,
            model: "tables", valuesKey: "id", valuesLabel: "name",
            autoHide: true
        }),
        field_json({
            name: "children", label: "Komponensek",
            required: true, table: table_ui_sub,
            autoHide: []
        }),
    ],
    "title_index": "UI elemek",
    "updatefields": [
        "id",
        "name",
        "model",
        "meta",
        "style",
        "children",
        "widget"
    ],
    "updatedAt": {
        "seconds": 1683883841,
        "nanoseconds": 619000000
    },
    "indexFields": [
        "name"
    ],
    "_id": "ui",
    "title_create": "Új elem",
    "meta": [
        "constants",
        "styles",
        "tables"
    ],
    "name": "UI",
    "id": "ui"
}
export default table