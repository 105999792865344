import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    setDoc,
    doc,
} from "firebase/firestore";

import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAUpXNjTkC6iB02HJOd5rElXb5cRONEHXg",
    authDomain: "backup-885d4.firebaseapp.com",
    projectId: "backup-885d4",
    storageBucket: "backup-885d4.appspot.com",
    messagingSenderId: "275604664793",
    appId: "1:275604664793:web:3dcacc56302f93d87ae9c8"
};
console.log('FIREBASE2 initializeApp')
const app_backup = initializeApp(firebaseConfig, 'backup');
const auth_backup = getAuth(app_backup);
const db_backup = getFirestore(app_backup);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle_backup = async () => {
    try {
        const res = await signInWithPopup(auth_backup, googleProvider);
        const user = res.user;
        const q = query(collection(db_backup, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
            await addDoc(collection(db_backup, "users"), {
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email,
            });
        }
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

// const logInWithEmailAndPassword = async (email, password) => {
//   try {
//     await signInWithEmailAndPassword(auth, email, password);
//   } catch (err) {
//     console.log(err);
//     // alert(err.message);
//   }
// };

const logInWithEmailAndPassword_backup = async (email, password) => {
    await signInWithEmailAndPassword(auth_backup, email, password);
};

const registerWithEmailAndPassword_backup = async (name, email, password) => {
    try {
        const res = await createUserWithEmailAndPassword(auth_backup, email, password);
        const user = res.user;
        await setDoc(doc(db_backup, "users", user.uid), {
            uid: user.uid,
            name,
            authProvider: "local",
            email,
        });
        // await addDoc(collection(db, "users"), {
        //   uid: user.uid,
        //   name,
        //   authProvider: "local",
        //   email,
        // });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const sendPasswordReset_backup = async (email) => {
    try {
        await sendPasswordResetEmail(auth_backup, email);
        alert("Password reset link sent!");
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const logout_backup = () => {
    console.log('FIREBASE 2 LOGOUT')
    signOut(auth_backup);
};

// const firebaseApp = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);

// const fetchToken = (setTokenFound) => {
//   return getToken(messaging, { vapidKey: 'BHGPr3pJQSflJAJtTIVXbmcEXlPV_HP29TZQRcqrGCN10gKIa-ojIJmtvM9kQGcsNKsWIA6ezKFG8Bd6LTjaVc0' }).then((currentToken) => {
//     if (currentToken) {
//       console.log('current token for client: ', currentToken);
//       setTokenFound(true);
//       // Track the token -> client mapping, by sending to backend server
//       // show on the UI that permission is secured
//     } else {
//       console.log('No registration token available. Request permission to generate one.');
//       setTokenFound(false);
//       // shows on the UI that permission is required 
//     }
//   }).catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
//     // catch error while creating client token
//   });
// }

// const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });

export {
    auth_backup,
    db_backup,
    signInWithGoogle_backup,
    logInWithEmailAndPassword_backup,
    registerWithEmailAndPassword_backup,
    sendPasswordReset_backup,
    logout_backup,
    // onMessageListener,
    // fetchToken
};
