import React from 'react';
import { Chip, ToggleButton } from '@mui/material';
import { Face, Help, Hub } from '@mui/icons-material';
import { Context2 } from '../../../services/fbcontext';
import { tables } from '../../../data/tables';
import { DynamicFaIcon } from '../../DynamicFaIcon';
import { blue, blueGrey, grey, orange, red, yellow } from '@mui/material/colors';
import { DynamicIcon } from '../../DynamicIcon';
import ProcessButton from '../../ProcessButton';
import { lightBlue } from '@mui/material/colors';
import { teal } from '@mui/material/colors';

const MiniView = ({
    unsubscribeFromMeta,
    metaUnSubscriptions,
    subscriptions,
    metaSubscriptions,
    loadings,
    showLabel,
    updatedList
}) => {
    return (
        <>
            {loadings && subscriptions &&
                Object.keys(subscriptions).map((label, index) =>
                    <>
                        {loadings[label]
                            ? <ProcessButton key={index} icon={tables[label].icon} name={label} fontSize="40" color={red[500]} />
                            : <ProcessButton key={index} icon={tables[label].icon} name={label} fontSize="40" color={blue[100]} />
                        }
                    </>
                )
            }
            {loadings && metaSubscriptions &&
                Object.keys(metaSubscriptions).map((label, index) =>
                    <>{
                        typeof updatedList[label] == 'object' && (
                            <ProcessButton key={index} icon={tables[label].icon} name={label} fontSize="40" color={blue[600]} />
                        )
                    }
                        {
                            typeof updatedList[label] != 'object' && (
                                loadings[label]
                                    ? <ProcessButton key={index} icon={tables[label].icon} name={label} fontSize="40" color={red[500]} />
                                    : <ProcessButton key={index} icon={tables[label].icon} name={label} fontSize="40" color={blue[100]} />
                            )

                        }
                    </>
                )
            }
            {/* {updatedList && Object.keys(updatedList).map(item => (
            <Chip icon={<Face />} label={showLabel ? item : ''} color="error" variant="filled" />
          ))} */}

        </>
    );
};

export default MiniView;