import { document_select } from "../fields/document_select";
import { select } from "../fields/select";
import { value_select_dynamic } from "../fields/value_select_dynamic";
import { field_text } from "../fields/field_text"
import subtable_tables_fields_tables from "./subtable_tables_fields_tables"
import { field_json } from "../fields/field_json"
import { value_mutiselect_local } from "../fields/value_mutiselect_local";
import { value_select_static } from "../fields/value_select_static";
import { value_select_dynamic2 } from "../fields/value_select_dynamic2";
import { document_select_dynamic } from "../fields/document_select_dynamic";
import subtable_tables_fields_tables_fields from "./subtable_tables_fields_tables_fields"
import subtable_tables_fields_tables_fields2 from "./subtable_tables_fields_tables_fields2"
import { field_jsoninput } from "../fields/field_jsoninput";
import { field_default } from "../fields/field_default";
import { field_json2 } from "../fields/field_json2";
export const table =
{
    "meta": [],
    "title_index": "Mezők [json]",
    "title_create": "Új mező3",
    "title_update": "Mező módosítása",
    "keyField": 0,
    "indexFields": [
        "name",
        "helper",
        "label"
    ],
    "updatefields": [
        "name",
        "helper",
        "label",
        "type",
        "model",
        "field",
        "valuesKey",
        "valuesLabel",
        "valuesField",
        "_document"
    ],
    "initial": {
        "name": null,
        "helper": null,
        "label": null,
        "type": null
    },
    "fields": [
        value_select_static({
            name: "type", label: "type(value_select_static)", required: true,
            model: "constants", valuesKey: "value", valuesLabel: "label",
            valuesField: "values", document: "mezo_tipusok", defaultValue: "text",
            autoHide: []
        }),
        value_select_static({
            name: "required", label: "Kötelező", required: true,
            model: "constants", valuesKey: "value", valuesLabel: "label",
            valuesField: "values", document: "igen_nem", defaultValue: "true",
            autoHide: []
        }),
        field_text({
            name: "name", label: "name", required: true,
            autoHide: []
        }),
        field_text({
            name: "helper", label: "helper", required: true,
            autoHide: []
        }),
        field_text({
            name: "label", label: "label", required: true,
            autoHide: []
        }),
        // select({
        //     name: "type", label: "type", required: true,
        //     valuesLabel: "label", valuesKey: "value", values: fieldtype
        // }),
        field_text({
            name: "bucket", label: "bucket", required: true,
            autoHide: ['text', 'number', 'icon', 'json', 'json2', 'jsoninput', 'img',
                'value_mutiselect_local', 'value_select_static',
                'value_select_dynamic2', 'document_select', 'image_select', 'document_multiselect',
                'select', 'value_select_dynamic', 'document_select_dynamic']
        }),
        value_select_static({
            name: "store_image_type", label: "store_image_type(value_select_static)", required: true,
            model: "constants", valuesKey: "value", valuesLabel: "label",
            valuesField: "values", document: "store_image_type",
            autoHide: ['text', 'number', 'icon', 'json', 'json2', 'jsoninput', 'img',
                'value_mutiselect_local', 'value_select_static',
                'value_select_dynamic2', 'document_select', 'image_select', 'document_multiselect',
                'select', 'value_select_dynamic', 'document_select_dynamic']
        }),
        document_select({
            name: "model", label: "model11(document_select)", required: true,
            model: "tables", valuesKey: "id", valuesLabel: "name",
            autoHide: ['text', 'number', 'icon', 'json', 'json2', 'jsoninput', 'img', 'img_store']
        }),
        value_select_dynamic({
            name: "valuesKey", label: "valuesKey(value_select_dynamic)", required: true,
            model: "tables", valuesKey: "name", valuesLabel: "name", "addId": true,
            valuesField: "fields", _document: "model",
            autoHide: ['text', 'number', 'icon', 'json', 'json2',
                'value_mutiselect_local', 'value_select_static',
                'value_select_dynamic2', 'jsoninput', 'img', 'img_store']
        }),
        value_select_dynamic({
            name: "valuesLabel", label: "valuesLabel(value_select_dynamic)", required: true,
            model: "tables", valuesKey: "name", valuesLabel: "name",
            valuesField: "fields", _document: "model",
            autoHide: ['text', 'number', 'icon', 'json', 'json2',
                'value_mutiselect_local', 'value_select_static',
                'value_select_dynamic2', 'jsoninput', 'img', 'img_store']
        }),
        value_select_dynamic({
            name: "valuesField", label: "valuesField(value_select_dynamic)", required: true,
            model: "tables", valuesKey: "name", valuesLabel: "name",
            valuesField: "fields", _document: "model",
            autoHide: ['text', 'number', 'icon', 'json', 'json2', 'document_select', 'image_select',
                'document_multiselect', 'jsoninput', 'img', 'img_store']
        }),
        document_select({
            name: "document", label: "document(document_select)", required: true,
            model: "constants", valuesKey: "id", valuesLabel: "name",
            autoHide: ['text', 'number', 'icon', 'json', 'json2', 'document_select', 'image_select',
                'document_multiselect', 'value_mutiselect_local',
                'value_select_dynamic2', 'jsoninput', 'img', 'img_store']
        }),
        document_select_dynamic({
            name: "document_dynamic", label: "document dynamic(document_select_dynamic)", required: true,
            _model: "model", valuesKey: "name", valuesLabel: "name",
            autoHide: ['text', 'number', 'icon', 'json', 'json2', 'document_select', 'image_select',
                'document_multiselect', 'value_mutiselect_local',
                'value_select_static', 'value_select_dynamic2',
                'jsoninput', 'img', 'img_store']
        }),
        value_select_static({
            name: "_document", label: "_document(value_select_static)", required: true,
            model: "tables", valuesKey: "name", valuesLabel: "label",
            valuesField: "fields", document: "tables",
            autoHide: ['text', 'number', 'icon', 'json', 'json2', 'document_select', 'image_select',
                'document_multiselect', 'value_mutiselect_local',
                'value_select_static', 'value_select_dynamic2',
                'jsoninput', 'img', 'img_store']
        }),
        // value_select_static({
        //     name: "valuesKey1", label: "valuesKey1", required: true,
        //     model: "constants", valuesKey: "value", valuesLabel: "label",
        //     valuesField: "values", document: "mezo_tipusok",
        //     autoHide: false
        // }),
        value_select_dynamic2({
            name: "valuesKey", label: "valuesKey1(value_select_dynamic2)", required: true,
            model: "tables", valuesKey: "name", valuesLabel: "name",
            _valuesField: "valuesField", _document: "model", "addId": true,
            autoHide: ['text', 'number', 'icon', 'json', 'json2', 'document_select', 'image_select',
                'document_multiselect', 'jsoninput', 'img', 'img_store']
        }),
        value_select_dynamic2({
            name: "valuesLabel", label: "valuesField1(value_select_dynamic2)", required: true,
            model: "tables", valuesKey: "name", valuesLabel: "name",
            _valuesField: "valuesField", _document: "model",
            autoHide: ['text', 'number', 'icon', 'json', 'json2', 'document_select', 'image_select',
                'document_multiselect', 'jsoninput', 'img', 'img_store']
        }),

        field_text({
            name: "title_index", label: "title_index", required: true,
            autoHide: ['text', 'number', 'icon', 'document_select', 'image_select',
                'document_multiselect', 'select', 'value_mutiselect_local',
                'value_select_static', 'value_select_dynamic', 'value_select_dynamic2',
                'document_select_dynamic', 'jsoninput', 'img', 'img_store']
        }),
        field_text({
            name: "title_create", label: "title_create", required: true,
            autoHide: ['text', 'number', 'icon', 'document_select', 'image_select',
                'document_multiselect', 'select', 'value_mutiselect_local',
                'value_select_static', 'value_select_dynamic', 'value_select_dynamic2',
                'document_select_dynamic', 'jsoninput', 'img', 'img_store']
        }),
        field_text({
            name: "title_update", label: "title_update", required: true,
            autoHide: ['text', 'number', 'icon', 'document_select', 'image_select',
                'document_multiselect', 'select', 'value_mutiselect_local',
                'value_select_static', 'value_select_dynamic', 'value_select_dynamic2',
                'document_select_dynamic', 'jsoninput', 'img', 'img_store']
        }),
        value_mutiselect_local({
            name: "indexFields", label: "indexFields", required: true,
            model: "fields", valuesKey: "name", valuesLabel: "name",
            autoHide: ['text', 'number', 'icon', 'document_select', 'image_select',
                'document_multiselect', 'select', 'value_mutiselect_local',
                'value_select_static', 'value_select_dynamic', 'value_select_dynamic2',
                'document_select_dynamic', 'jsoninput', 'img', 'img_store']
        }),
        value_mutiselect_local({
            name: "updatefields", label: "updatefields", required: true,
            model: "fields", valuesKey: "name", valuesLabel: "name",
            autoHide: ['text', 'number', 'icon', 'document_select', 'image_select',
                'document_multiselect', 'select', 'value_mutiselect_local',
                'value_select_static', 'value_select_dynamic', 'value_select_dynamic2',
                'document_select_dynamic', 'jsoninput', 'img', 'img_store']
        }),
        // field_json({
        //     name: "fields", label: "Mezők1",
        //     required: true, table: subtable_tables_fields_tables_fields,
        //     autoHide: ['text', 'number', 'icon', 'document_select', 'image_select',
        //         'document_multiselect', 'select', 'value_mutiselect_local',
        //         'value_select_static', 'value_select_dynamic', 'value_select_dynamic2',
        //         'document_select_dynamic', 'jsoninput', 'img', 'img_store', 'json2']
        // }),
        field_json2({
            name: "fields", label: "Mezők2",
            required: true, table: subtable_tables_fields_tables_fields2,
            autoHide: ['text', 'number', 'icon', 'document_select', 'image_select',
                'document_multiselect', 'select', 'value_mutiselect_local',
                'value_select_static', 'value_select_dynamic', 'value_select_dynamic2',
                'document_select_dynamic', 'jsoninput', 'img', 'img_store', 'json']
        }),
        field_default({
            name: "default", label: "default", required: false,
            autoHide: [], _valuesField: "valuesField"
        }),
        // field_text({
        //     name: "default", label: "default", required: false,
        //     autoHide: ['jsoninput']
        // }),
        // field_jsoninput({
        //     name: "default", label: "default",
        //     required: false,
        //     autoHide: ['text', 'number', 'icon', 'document_select', 'image_select',
        //         'document_multiselect', 'select', 'value_mutiselect_local',
        //         'value_select_static', 'value_select_dynamic', 'value_select_dynamic2',
        //         'document_select_dynamic', 'json', 'json2', 'img', 'img_store']
        // }),

        // field_json({
        //     name: "table", label: "table", required: true,
        //     table: subtable_tables_fields_tables,
        //     autoHide: ['text', 'number', 'icon', 'document_multiselect',
        //     'value_mutiselect_local', 'value_select_static']
        // }),
    ]
}

export default table