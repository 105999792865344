import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MiniDrawer from './MiniDrawer';
import { isMobile } from 'react-device-detect';
import Mobile from './MobileNavigation';
import MobileAppBar from './MobileAppBar';
import MobileNavigation from './MobileNavigation';
import { Paper } from '@mui/material';
import { Context2 as Context } from '../../services/fbcontext';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function EditorLayout({ children, app }) {
    const { environmentVariables, setEnvironmentVariable } = React.useContext(Context);
    const theme = useTheme();
    const ref = React.useRef(null);
    return (
        <>
            {
                environmentVariables && environmentVariables.view == 'mobile' ?
                    (
                        <Box sx={{ pb: 7 }} ref={ref}>

                            <CssBaseline />
                            <MobileAppBar app={app}/>
                            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>



                                {children}


                                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                                    <MobileNavigation app={app} />
                                </Paper>
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex' }}>
                            <CssBaseline />
                            <MiniDrawer app={app} />
                            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                                <DrawerHeader />
                                {children}
                            </Box>
                        </Box>
                    )

            }
        </>
    );
}