import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { Context2 as Context } from '../../services/fbcontext';
import { AdminPanelSettings, Apps, FormatAlignCenter, FormatAlignLeft, Group, Person, StayCurrentPortrait, Tv } from '@mui/icons-material';
import { DynamicFaIcon } from '../../common/DynamicFaIcon';
import { AppDataArray } from '../../data/appications';
import { useNavigate } from "react-router-dom";

const AppSelect = ({ app, orientation = "horizontal", showLabel = false }) => {
    const { environmentVariables, setEnvironmentVariable } = useContext(Context);
    const navigate = useNavigate();

    const handleAlignment = (event, newAlignment) => {
        event.preventDefault();
        const toApp = AppDataArray[newAlignment];
        console.log('newAlignment', newAlignment);
        if (newAlignment != null && newAlignment != undefined && toApp) {
            setEnvironmentVariable({ key: 'selectedApp', value: newAlignment });
            navigate(toApp.pages[toApp.defaultPage].route);
        }
    };

    return (<>
        <ToggleButtonGroup
            value={app && app.key ? app.key : environmentVariables.selectedApp ?? 0}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
            color="error"
            fullWidth={showLabel}
            orientation={orientation}
        >
            {AppDataArray &&
                Object.keys(AppDataArray).map((app, index) =>

                    <ToggleButton key={app} value={app} aria-label="left aligned">

                        <DynamicFaIcon title={AppDataArray[app].name} key={app} name={AppDataArray[app].icon} />
                        {showLabel && AppDataArray[app].label}
                    </ToggleButton >
                )}
        </ToggleButtonGroup >
    </>
    );
};

export default AppSelect;