import { Button, Table, TableBody } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FSPTitle from './FSPTitle';
import FSPForm from './FSPForm/FSPForm';
import FSPDeleteDialog from './FSPDeleteDialog';
import { convertArrayById, getItemId } from '../Utility';
import FSPTable from './FSPTable/FSPTable';
import CCrud from './Layout/CCrud';
import FSPCrudModel from './FSPCrudModel';
import FSPImportDialog from './FSPImportDialog';

const FSPCrud = ({
    model,
    createElement,
    deleteElement,
    updateElement,
    table,
    meta,
    metaArray,
    view,
    parentRecord
}) => {
    const {
        newRecord, setNewRecord,
        showNewDialog, setShowNewDialog,
        showDeleteDialog, setShowDeleteDialog,
        page, setPage,
        wkey, setKey,
        fieldsArray, setFieldsArray,
        errors, setErrors,
        rowsPerPage, setRowsPerPage,
        handleChange,
        handleChangeArray, handleChangeTable,
        handleChangePage,
        handleChangeRowsPerPage,
        showImportDialog, setShowImportDialog
    } = FSPCrudModel({ table: table });

    useEffect(() => {
        // const f = table && table.fields ? convertArrayById(table.fields, 'name') : [];
        const f = table && table.fields ?
            (Array.isArray(table.fields) ?
                convertArrayById(table.fields, 'name') : table.fields)
            : [];
        setFieldsArray(f);
        setNewRecord(table && table.initial ? table.initial : {});
    }, [table]);

    var exportjson = {};
    if (Array.isArray(model)) {
        model.forEach(record => {
            exportjson[record.id ?? record.name] = typeof record.data == 'function' ? record.data() : record
        });
    }

    return (
        <CCrud>
            <FSPTitle
                key={`FSPTableTitle${wkey}`}
                title={table && table.title_index ? table.title_index : ''}
                setNewRecord={setNewRecord}
                setShowNewDialog={setShowNewDialog}
                setShowImportDialog={setShowImportDialog}
                table={table}
                view={view}
            />
            {!showNewDialog &&
                <FSPTable
                    key={`FSPTableRows${wkey}`}
                    model={model}
                    table={table}
                    metaArray={metaArray}
                    fieldsArray={fieldsArray}
                    setNewRecord={setNewRecord}
                    setShowNewDialog={setShowNewDialog}
                    setShowDeleteDialog={setShowDeleteDialog}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    view={view}
                    page={page}
                />}

            <FSPForm
                key={`FSPTableForm${wkey}`}
                open={showNewDialog}
                createElement={createElement}
                updateElement={updateElement}
                table={table}
                setShowNewDialog={setShowNewDialog}
                handleChange={handleChange}
                handleChangeArray={handleChangeArray}
                handleChangeTable={handleChangeTable}
                newRecord={newRecord}
                errors={errors}
                meta={meta}
                metaArray={metaArray}
                rowsPerPage={rowsPerPage}
                view={view}
                parentRecord={parentRecord}
            />
            <FSPDeleteDialog
                key={`FSPTableDeleteDialog${wkey}`}
                open={showDeleteDialog != null}
                showDeleteDialog={showDeleteDialog}
                setShowDeleteDialog={setShowDeleteDialog}
                deleteElement={deleteElement}
                view={view}
            />
            <FSPImportDialog
                key={`FSPImportDialog${wkey}`}
                open={showImportDialog != null}
                setShowImportDialog={setShowImportDialog}
                view={view}
                table={table}
            />
            {view == 'desktop' &&
                <div style={{ width: '300px' }} >
                    <Button onClick={() => { navigator.clipboard.writeText(JSON.stringify(newRecord, null, "\t")) }}>Copy</Button>
                    <pre>
                        {JSON.stringify(newRecord, null, "\t")}
                    </pre>
                    <Button onClick={() => { navigator.clipboard.writeText(JSON.stringify(exportjson, null, "\t")) }}>Copy</Button>
                    <pre>
                        {JSON.stringify(exportjson, null, "\t")}
                    </pre>
                </div>

            }

        </CCrud>
    );
};

export default FSPCrud;