import React, { useContext, useEffect, useState } from "react";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
// import AppData from '../../AppConfig.json';
import { useNavigate } from 'react-router-dom';
import FSPIcon from './FSPIcon';
import { BottomNavigationAction, IconButton } from "@mui/material";
import { AppDataArray } from "../../data/appications";
import { Context2 as Context } from "../../services/fbcontext";

const MainMenu = ({ open, app }) => {
    const { environmentVariables, setEnvironmentVariable } = useContext(Context);
    const AppData = app ?? (AppDataArray[environmentVariables && environmentVariables.selectedApp ? environmentVariables.selectedApp : 'admin']);
    const navigate = useNavigate();
    const [menu, setMenu] = useState();
    const handleSubmit = (a) => {
        setMenu(a);
        console.log(a);
        navigate(a);
    };

    return (
        <><List>
            {Object.keys(AppData.pages).map((page, index) => (
                <ListItem
                    className={
                        menu === AppData.pages[page].route ? 'primary' : 'secondary'
                    }
                    onClick={() => handleSubmit(AppData.pages[page].route)}
                    key={index} disablePadding sx={{ display: 'block' }}>

                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <BottomNavigationAction
                            showLabel={true}
                            label={AppData.pages[page].label}
                            icon={<FSPIcon icon={AppData.pages[page].icon} active={menu === AppData.pages[page].route} />}
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                        </BottomNavigationAction>
                        <ListItemText primary={AppData.pages[page].label} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List >
            {/* <Divider />
            <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List> */}
        </>
    );
};

export default MainMenu;