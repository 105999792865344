import { AdUnits } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from "react";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
// import AppData from '../../AppConfig.json';
import { useNavigate } from 'react-router-dom';
import FSPIcon from './FSPIcon';
import { BottomNavigation, BottomNavigationAction, IconButton, Paper } from "@mui/material";
import { AppDataArray } from '../../data/appications';
import { Context2 as Context } from "../../services/fbcontext";

const MobileNavigation = ({ app }) => {
    const { environmentVariables, setEnvironmentVariable } = useContext(Context);
    const AppData = app ?? (AppDataArray[environmentVariables && environmentVariables.selectedApp ? environmentVariables.selectedApp : 0]);
    const [value, setValue] = React.useState('recents');
    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleSubmit = (a) => {
        // setMenu(a);
        console.log(a);
        navigate(a);
    };

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                {AppData && Object.keys(AppData.pages).map((page, index) => (
                    <BottomNavigationAction
                        key={index}
                        showLabel={true}
                        value={AppData.pages[page].label}
                        label={AppData.pages[page].label}
                        icon={<FSPIcon icon={AppData.pages[page].icon} />}
                        onClick={() => handleSubmit(AppData.pages[page].route)}
                        sx={{
                            minWidth: 0,
                            // mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                    </BottomNavigationAction>
                ))}
            </BottomNavigation>
        </Paper >
    );
};

export default MobileNavigation;