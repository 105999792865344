import React from 'react';
import { TableCell } from '@mui/material';

const CTableCell = ({ children, view = 'desktop', ...params }) => {

    return (
        <>
            {view == 'mobile' ?
                <div params>
                    {children}
                </div>
                : <div>
                    {children}
                </div>
            }
        </>
    );
};

export default CTableCell;