import React from 'react';
import { Table } from '@mui/material';

const CCrud = ({ children, view, ...params }) => {
    return (
        <>
            {view == 'mobile' ?
                <>
                    {children}
                </>
                :
                <div>
                    {children}
                </div>
            }
        </>
    );
};

export default CCrud;