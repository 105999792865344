export const value_select_static = ({
    name, required, label, model, valuesKey, valuesLabel,
    valuesField, document, autoHide, addId, defaultValue
}) => {
    return {
        "required": required,
        "name": name,
        "helper": label,
        "q2": label,
        "label": label,
        "model": model,
        "valuesKey": valuesKey,
        "valuesLabel": valuesLabel,
        "valuesField": valuesField,
        "document": document,
        "autoHide": autoHide,
        "addId": addId,
        "default": defaultValue,
        "type": "value_select_static",
    }
}