import { TextField } from '@mui/material';
import React from 'react';

const FSPFieldText = ({
    field,
    handleChange,
    value
}) => {
    return (
        <div>
            <div>
                {field.label}
            </div>
            <TextField
                required={field.required ?? false}
                id={field.name}
                name={field.name}
                label={field.label}
                multiline={field.multiline ?? false}
                rows={field.rows ?? 1}
                onChange={handleChange}
                fullWidth
                value={value}
            />
        </div>
    );
};

export default FSPFieldText;