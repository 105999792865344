import { document_select } from "../fields/document_select";
import { select } from "../fields/select";
import { value_select_dynamic } from "../fields/value_select_dynamic";
import { field_text } from "../fields/field_text"
import subtable_tables_fields_tables from "./subtable_tables_fields_tables"
import { field_json } from "../fields/field_json"
import { value_mutiselect_local } from "../fields/value_mutiselect_local";
import { value_select_static } from "../fields/value_select_static";
import { value_select_dynamic2 } from "../fields/value_select_dynamic2";
import { document_select_dynamic } from "../fields/document_select_dynamic";
import subtable_tables_fields_tables_fields from "./subtable_tables_fields_tables_fields"
import { field_jsoninput } from "../fields/field_jsoninput";
import { field_default } from "../fields/field_default";
import { value_select_dynamic_parent } from "../fields/value_select_dynamic_parent";
import table_ui_sub from "./table_ui_sub"

export const table =
{
    "meta": [],
    "title_index": "Komponensek",
    "title_create": "Új komponens",
    "title_update": "Komponens módosítása",
    "keyField": 0,
    "indexFields": [
        "name",
        "helper",
        "label"
    ],
    "updatefields": [
        "name",
        "helper",
        "label",
        "type",
        "model",
        "valuesKey",
        "valuesLabel",
        "valuesField",
        "_document"
    ],
    "initial": {
        "name": null,
        "helper": null,
        "label": null,
        "type": null
    },
    "fields": [
        value_select_static({
            name: "type", label: "type(value_select_static)", required: true,
            model: "constants", valuesKey: "value", valuesLabel: "label",
            valuesField: "values", document: "komponens_tipusok", defaultValue: "model",
            autoHide: []
        }),
        field_text({
            name: "name", label: "name", required: true,
            autoHide: []
        }),
        document_select({
            name: "style", label: "style(document_select)", required: true,
            model: "styles", valuesKey: "id", valuesLabel: "name",
            autoHide: []
        }),
        value_select_dynamic_parent({
            name: "field", label: "Mező(value_select_dynamic_parent)", required: true,
            model: "tables", valuesKey: "name", valuesLabel: "name",
            valuesField: "fields", _document: "model",
            autoHide: ['container']
        }),
        // field_json({
        //     name: "children", label: "Komponensek",
        //     required: true, table: table_ui_sub,
        //     autoHide: ['model', 'action', 'code', 'array']
        // }),
    ]
}

export default table