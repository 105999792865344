import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { auth, db, logout } from "./firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import MainLayout from "./layouts/Main/MainLayout";
import Page from "./common/Page";
import { convertArrayById } from "./common/Utility";
// import useFirestoreStore2 from "./services/useFirestoreStore2";

function Main({ table, app }) {
    const [user, loading, error] = useAuthState(auth);
    const [name, setName] = useState("");
    // const { subscriptions, subscribeToCollection, unsubscribeFromCollection } = useFirestoreStore2('myCollection');
    const navigate = useNavigate();

    const go = (a) => {
        console.log(a);
        navigate(a);
    }
    // const abc = (aa) => {
    //     console.log('subscribeToCollection callback', aa);
    // }
    // console.log('table', table.collection);

    // useEffect(() => {
    //     console.log('hoppacska', subscriptions);
    // }, [subscriptions]);

    // useEffect(() => {
    //     subscribeToCollection({ callback: abc, collectionName: table.collection });
    //     subscribeToCollection({ callback: abc, collectionName: 'teszt' });
    //     subscribeToCollection({ callback: abc, collectionName: 'constants' });
    //     return () => {
    //         unsubscribeFromCollection({ collectionName: table.collection });
    //     };
    // }, [table]);

    useEffect(() => {
        if (loading) return;
        if (!user) return go("/signin");
    }, [user, loading]);
    console.log('APP', table);

    return (
        <MainLayout app={app}>
            {loading ? <h1>loading</h1> :
                <div>
                    <Page
                        table={table}
                    />
                </div>
            }
        </MainLayout>
    );
}

export default Main;
