import React, { useContext, useEffect, useState } from 'react';
import {
    getFirestore, collection, setDoc, getDocs, onSnapshot,
    deleteDoc, addDoc, doc, getDoc, updateDoc
} from "firebase/firestore";
import { auth, db } from "../firebase";

export const DeleteRecord = (table, id) => {
    console.log(id)
    deleteDoc(doc(db, table, id)).then(() => {
        console.log("deleted");
    });
};

export const AddRecord = (table, record) => {
    console.log(table, record);
    const user = auth ? auth.currentUser : { uid: null }
    console.log('user', user);
    console.log('record', record);
    var data = {
        ...record,
        authorId: user?.uid,
        createdAt: new Date(),
        updatedAt: new Date(),
    };
    console.log('data', data);
    const userRef = collection(db, table);
    addDoc(userRef, data);
}

export const ImportRecord = (id, table, record) => {
    console.log('ID', id);
    console.log('ID', table);
    console.log('ID', record);

    const userRef = doc(db, table, id);


    setDoc(userRef, record, { merge: false }).then(() => {
        console.log("updated");
    })
}
export const UpdateRecord = (id, table, record, fields) => {
    console.log('ID', id);
    console.log('ID', table);
    console.log('ID', record);
    console.log('ID', fields);
    const userRef = doc(db, table, id);

    var fieldvalues = {};
    fieldvalues['updatedAt'] = new Date();
    fields.forEach(f => (
        fieldvalues[f] = record[f]
    ));
    console.log('fieldvalues', fieldvalues);

    // updateDoc(userRef, fieldvalues, { merge: true }).then(() => {
    //     console.log("updated");
    // })

    setDoc(userRef, fieldvalues, { merge: true }).then(() => {
        console.log("updated");
    })
}

export const GetAllRecord = (setter, table) => {
    const userRef = collection(db, table);
    getDocs(userRef).then((snap) => {
        console.log('.....', snap);
        snap.forEach((doc) => {
            console.log(doc.id);
            console.log(doc.data());
        });
        setter(table, snap);
    });

}
export const GetRecord = (setter, table, id) => {
    console.log('snap.....', setter);
    console.log('snap.....', table);
    console.log('snap.....', id);
    const userRef = doc(db, table, id);
    getDoc(userRef).then((snap) => {
        console.log('snap.....', snap.data());

        setter(snap.data());
    });

}

export const GetRecordContext = (variable, environmentVariables, setEnvironmentVariable, table, id) => {
    // const { environmentVariables, setEnvironmentVariable } = React.useContext(Context);
    if (!environmentVariables[variable]) {
        const userRef = doc(db, table, id);
        getDoc(userRef).then((snap) => {
            console.log('snap.....', snap.data());
            console.log('snap.....', variable);
            console.log('snap.....', table);
            console.log('snap.....', id);
            setEnvironmentVariable({ key: variable, value: snap.data() });
        })
    } else {
        console.log('már megvan nyugi!!!');
    }
}

export const Subscibe = (setter, table) => {
    const dbRef = collection(db, table);
    onSnapshot(dbRef, docsSnap => {
        console.log(docsSnap);
        setter(docsSnap);
    });
}

export const getUserId = () => {
    const user = auth ? auth.currentUser : { uid: null }
    return user.uid;
}

