import React from 'react';
import { getProp, getPropArray, getPropArrayParams } from '../../../Utility';
import FSPRowFieldDocumentMultiSelectElement from './FSPRowFieldDocumentMultiSelectElement';

const FSPRowFieldDocumentMultiSelect = ({
    model,
    values,
    label,
    metaArray,
}) => {
    const params = {
        model: model,
    }
    const options = getPropArrayParams(metaArray, params, ['model']);
    return (
        <>
            {
                values &&
                label &&
                values.map((value, index) => (
                    <FSPRowFieldDocumentMultiSelectElement
                        option={getProp(options, value)}
                        label={label}
                    />
                ))
            }
        </>
    );
};

export default FSPRowFieldDocumentMultiSelect;