import { useEffect, useState } from 'react';
import { getFirestore, collection, onSnapshot, query, where } from 'firebase/firestore';
import { db, auth } from "../../firebase";
import { getPropArray } from '../../common/Utility';
import MetaSubscriptions from './states/metaSubscriptions';
import MetaUnSubscriptions from './states/metaUnSubscriptions';

const Meta = ({ setLoading }) => {
    const { metaSubscriptions, addMetaSubscription, deleteMetaSubscription } = MetaSubscriptions();
    const { metaUnSubscriptions, addMetaUnSubscription, deleteMetaUnSubscription } = MetaUnSubscriptions();

    const subscribe = ({ callback, collectionName }) => {
        const collectionRef = collection(db, collectionName);
        const unsubscribe = onSnapshot(collectionRef, docsSnap => {

            const fromCache = getPropArray(docsSnap, ['_snapshot', 'fromCache']);
            if (!fromCache)
                setLoading({ key: collectionName, status: false })
            callback(collectionName, docsSnap);
        },
            error => {
                console.log('FS error', error)
            }
        );
        return unsubscribe;
    }

    const unsubscribe = ({ collectionName }) => {
        if (metaSubscriptions[collectionName]) {
            metaSubscriptions[collectionName]();
        }
    }

    const subscribeToMeta = ({ callback, collectionName }) => {
        if (metaSubscriptions[collectionName]) {
            console.log('MetaData123: Már fel vagyunk iratkozva', metaSubscriptions);
        } else {
            setLoading({ key: collectionName, status: true })
            const unsubscribe = subscribe({ callback: callback, collectionName: collectionName });
            addMetaSubscription({
                collectionName: collectionName, unsubscribe: unsubscribe
            })
        }
        console.log('TÖRÖLJÜK A UNS LISTÁBÓL', collectionName);
        deleteMetaUnSubscription({
            collectionName: collectionName
        })
    };

    const subscribeToMetas = ({ callback, collectionNames }) => {
        console.log('TÖRÖLJÜK A UNS LISTÁBÓL1', collectionNames);
        const c = Object.keys(metaSubscriptions);
        const unsub = c.filter((e) => collectionNames.indexOf(e) < 0);
        const sub = collectionNames.filter((e) => c.indexOf(e) < 0);
        console.log('TÖRÖLJÜK A UNS LISTÁBÓL2', collectionNames);
        collectionNames.forEach(collectionName => {
            console.log('TÖRÖLJÜK A UNS LISTÁBÓL3', collectionName);
            subscribeToMeta({ callback: callback, collectionName: collectionName })
        });
    };

    const addMetaUnSubscriptions = ({ collectionNames }) => {
        if (collectionNames) {
            collectionNames.forEach(collectionName => {
                addMetaUnSubscription({ collectionName: collectionName })
            });
        }
    };

    const unsubscribeFromMeta = ({ collectionName = null }) => {
        unsubscribe({ collectionName: collectionName })
        deleteMetaSubscription({ collectionName: collectionName })
        deleteMetaUnSubscription({ collectionName: collectionName })
    };

    const unsubscribeFromMetas = ({ collectionNames }) => {
        if (collectionNames) {
            collectionNames.forEach(collectionName => {
                unsubscribeFromMeta({ collectionName: collectionName })
            });
        }
    };

    // useEffect(() => {
    //     console.log('loadings metaSubscriptions', metaSubscriptions)
    // }, [metaSubscriptions]);

    // useEffect(() => {
    //     console.log('hoppacska1111111-cc', metaUnSubscriptions);
    // }, [metaUnSubscriptions]);

    return {
        metaSubscriptions,
        subscribeToMetas,
        unsubscribeFromMeta,
        unsubscribeFromMetas,
        metaUnSubscriptions,
        addMetaUnSubscription,
        deleteMetaUnSubscription,
        addMetaUnSubscriptions
    };
};

export default Meta;