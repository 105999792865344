import React from 'react';
// import ProcessButton from '../ProcessButton';
import { useDrag } from 'react-dnd'
import { BottomNavigationAction } from '@mui/material';
import ProcessButton from '../../ProcessButton';

const DragAbleComponent = ({ element }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: 'pet',
        item: { element },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    })
    console.log('element1', element);
    return (
        <span className='pet-card' ref={dragRef}>
            <BottomNavigationAction
                showLabel={true}
                label={element.name}
            icon={
            <ProcessButton
                element={element}
                name={element.name}
                icon={element.icon}
                fontSize={"large"}
                color='#000'

            />}
            // onClick={(e) => stop(e)}
            />

            {isDragging && '😱'}
        </span>
    );
};

export default DragAbleComponent;