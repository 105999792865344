import React, { useContext } from 'react';
// const { subscriptions, metaSubscriptions, loadings
// } = useFirestoreStore2('myCollection');
import { Context2 } from '../../services/fbcontext';
import { Chip, Tooltip } from '@mui/material';
import { Face, Hub } from '@mui/icons-material';
import { useEffect } from 'react';

const Loadings = ({ showLabel = false }) => {
    const { subscriptions, metaSubscriptions,
        subscribeToCollection, unsubscribeFromCollection,
        subscribeToMetas, unsubscribeFromMeta, loadings } = useContext(Context2)
    useEffect(() => {
        console.log('loadings111', loadings);
    }, [loadings]);


    useEffect(() => {
        console.log('metaSubscriptions111', metaSubscriptions);
    }, [metaSubscriptions]);
    return (
        <>
            {loadings && subscriptions &&
                Object.keys(subscriptions).map((label, index) =>
                    <>
                        {loadings[label]
                            ? <Chip icon={<Face />} label={showLabel ? label : ''} color="warning" variant="filled" />
                            : <Chip icon={<Face />} label={showLabel ? label : ''} color="success" variant="outlined" />
                        }
                    </>
                )
            }

            {loadings && metaSubscriptions &&
                Object.keys(metaSubscriptions).map((label, index) =>
                    <>
                        {loadings[label]
                            ? <Chip icon={<Hub />} label={showLabel ? label : ''} color="warning" variant="filled" />
                            : <Chip icon={<Hub />} label={showLabel ? label : ''} color="success" variant="outlined" />}
                    </>
                )
            }
        </>
    );
};

export default Loadings;