import React, { useState } from 'react';
import { setProps } from './Utility';
import { Button, Icon, IconButton } from '@mui/material';
import * as Icons from '@mui/icons-material';;


export const IconButtonComponent = ({
    node,
    nodes,
    setActive,
}) => {
    const data = nodes[node];
    const style = setProps({
        p: ['icon', 'size', 'color'],
        data: data.properties
    })
    const click = (e) => {
        e.stopPropagation();
        setActive(node);
    }
    const IconComponents = Icons[style['icon']];
    return (
        (<IconButton className={'icon_component'} onClick={(e) => click(e)}>
            <Icon baseClassName="material-icons-two-tone">{style['icon']}</Icon>
        </IconButton>)
    )
}