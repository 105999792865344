import { field_text } from "../fields/field_text"
import { value_select_static } from "../fields/value_select_static"

export const table_groups =
{
    "type": "array",
    "indexFields": [
        "name"
    ],
    "updatefields": [
        "name"
    ],
    "meta": [],
    "fields": [
        {
            "type": "text",
            "required": "true",
            "indexFields": [],
            "updatefields": [],
            "fields": {},
            "name": "name",
            "helper": "Név",
            "label": "Név",
            "_id": "item_1699194687414"
        }
    ],
    "id": "projects",
    "_id": "projects",
    "name": "Projektek",
    "icon": "Collections",
    "collection": "projects",
    "title_index": "Projektek",
    "title_create": "Új projekt",
    "title_update": "Projekt módosítása",
    "ui": "general"
}

export default table_groups