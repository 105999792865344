import React, { Component, useEffect, useState } from 'react';
import * as Icons from '@mui/icons-material';

export const iconOptions = [
    {
        value: "AcUnit",
        label: (
            <div>
                <Icons.AcUnit /> DataSaverOn
            </div>
        )
    },
    {
        value: "AccessAlarm",
        label: (
            <div>
                <Icons.AccessAlarm /> AccessTime
            </div>
        )
    },
    {
        value: "NotificationsNone",
        label: (
            <div>
                <Icons.NotificationsNone /> NotificationsNone
            </div>
        )
    },
    {
        value: "AddAlarmSharp",
        label: (
            <div>
                <Icons.AddAlarmSharp /> PhpOutlined
            </div>
        )
    },
    {
        value: "BarChart",
        label: (
            <div>
                <Icons.BarChart /> BarChart
            </div>
        )
    }
];

export const convertObjectToArray = (model) => {
    let converted = [];
    model && Object.keys(model).forEach(record => {
        converted.push(model[record]);
    })

    return converted;
}
export const convertObjectById = (model, field = 'id') => {
    let converted = {};
    model && Object.keys(model).forEach(record => {
        converted[model[record][field]] = model[record];
    })

    return converted;
}
export const convertArrayById = (model, field = 'id') => {
    let converted = [];
    model && model.forEach(record => {
        converted[record[field]] = record;
    })

    return converted;
}
export const convertArrayById2 = (model, field = 'id') => {
    let converted = [];
    model && model.forEach(record => {
        //  converted[record[field]] = { ...record.data(), id: record[field] };
        converted.push({ ...record.data(), id: record[field] });
    })

    return converted;
}

export const getProp = (model, field) => {
    const prop = model && field ? model[field] : null;
    return prop;
}
export const getPropArray = (model, fields) => {
    let data = model;
    if (typeof model == 'object' && Array.isArray(fields)) {
        fields.forEach(element => {
            if (typeof element == 'string') {
                data = getProp(data, element)
            }
            else {
                if (Array.isArray(element)) {
                    data = convertArrayById(data, element[0])
                }
            }
        });
    }
    else data = null;
    return data;
}

export const getPropArrayParams = (model, params, fields) => {
    let data = model;
    if (typeof model == 'object' && Array.isArray(fields)) {
        fields.forEach(e => {
            if (typeof e == 'string') {
                var element = params[e];
                data = getProp(data, element)
            }
            else {
                if (Array.isArray(e)) {
                    var element = params[e[0]];
                    data = convertArrayById(data, element)
                }
            }
        });
    }
    else data = null;
    return data;
}

export const getPropArray2 = (model, fields) => {
    let data = model;
    if (typeof model == 'object' && Array.isArray(fields)) {
        fields.forEach(element => {
            if (typeof data[element] != 'function') {
                data = getProp(data, element)
            }
            else {
                data = data[element]()
            }
        });
    }
    else data = null;
    return data;
}

export const getItemId = () => `item_${+new Date()}`;