import React from 'react';
import CTableCell from '../Layout/CTableCell';
import FSPRowFieldText from './FSPRowField/FSPRowFieldText';
import FSPRowFieldSelect from './FSPRowField/FSPRowFieldSelect';
import FSPRowFieldValueSelectStatic from './FSPRowField/FSPRowFieldValueSelectStatic';
import FSPRowFieldDocumentSelect from './FSPRowField/FSPRowFieldDocumentSelect';
import FSPRowFieldDocumentMultiSelect from './FSPRowField/FSPRowFieldDocumentMultiSelect';
import { getProp } from '../../Utility';
import FSPRowFieldImg from './FSPRowField/FSPRowFieldImg';
import FSPRowFieldImgStore from './FSPRowField/FSPRowFieldImgStore';
import FSPRowFieldImageSelect from './FSPRowField/FSPRowFieldImageSelect';

const FSPRowField =
    ({
        fieldName,
        record,
        metaArray,
        fieldsArray,
        view
    }) => {
        const field = getProp(fieldsArray, fieldName);
        const value = getProp(record, fieldName);
        const fieldtype = getProp(field, 'type');
        const model = getProp(field, 'model');
        const document = getProp(field, 'document');
        const valuesField = getProp(field, 'valuesField');
        const valuesKey = getProp(field, 'valuesKey');
        const valuesLabel = getProp(field, 'valuesLabel');
        const bucket = getProp(field, 'bucket');

        return (
            <CTableCell view={view} component="th" scope="row">
                {
                    (fieldtype == 'text' || fieldtype == 'number') &&
                    <FSPRowFieldText
                        value={value}
                    />
                }
                {
                    fieldtype == 'img' &&
                    <FSPRowFieldImg
                        value={value}
                    />
                }
                {
                    fieldtype == 'json' &&
                    <>"json"</>
                }
                {
                    fieldtype == 'json2' &&
                    <>"json2"</>
                }
                {
                    fieldtype == 'img_store' &&
                    <FSPRowFieldImgStore
                        value={value}
                        bucket={bucket}
                    />
                }
                {
                    fieldtype == 'select' &&
                    <FSPRowFieldSelect
                        value={value}
                        field={field}
                        valuesLabel={getProp(field, 'values_label')}
                    />
                }
                {
                    fieldtype == 'value_select_static' &&
                    <FSPRowFieldValueSelectStatic
                        metaArray={metaArray}
                        value={value}
                        model={model}
                        document={document}
                        valuesField={valuesField}
                        valuesKey={valuesKey}
                        valuesLabel={valuesLabel}
                    />
                }
                {
                    fieldtype == 'image_select' &&
                    <FSPRowFieldImageSelect
                        field={field}
                        model={model}
                        value={value}
                        valuesLabel={valuesLabel}
                        metaArray={metaArray}
                    />
                }
                {
                    fieldtype == 'document_select' &&
                    <FSPRowFieldDocumentSelect
                        model={model}
                        value={value}
                        valuesLabel={valuesLabel}
                        metaArray={metaArray}
                    />
                }

                {
                    fieldtype == 'document_multiselect' &&
                    <FSPRowFieldDocumentMultiSelect
                        model={model}
                        values={value}
                        label={valuesLabel}
                        metaArray={metaArray}
                    />
                }
            </CTableCell>
        );
    };

export default FSPRowField;