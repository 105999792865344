import React, { useState } from 'react';

const MetaSubscriptions = () => {
    const [metaSubscriptions, setMetaSubscriptions] = useState({});

    const addMetaSubscription = ({ collectionName, unsubscribe }) => {
        const subscriptionKey = `${collectionName}`;
        setMetaSubscriptions((prevState) => ({
            ...prevState,
            [subscriptionKey]: unsubscribe,
        }));
    }

    const deleteMetaSubscription = ({ collectionName }) => {
        const subscriptionKey = `${collectionName}`;
        setMetaSubscriptions((prevState) => {
            const { [subscriptionKey]: deleted, ...rest } = prevState;
            return rest;
        });
    }

    return { metaSubscriptions, addMetaSubscription, deleteMetaSubscription };
};

export default MetaSubscriptions;
