import React, { useContext, useEffect, useState } from 'react';
import { getItemId } from '../Utility';
import { Context2 } from '../../services/fbcontext';

const Model = () => {
    const {
        subscribeToCollection,
        unsubscribeFromCollection,
    } = useContext(Context2);
    const [model, setModel] = useState(null);
    const [store, setStore] = useState({ teszt: 11 });
    const [main, setMain] = useState('item_1680791259673');
    const [active, setActive] = useState(main);

    const setModelData = (tableName, records) => {
        console.log('SET DATA', records.docs);
        if ('components2' === tableName)
            setModel(records.docs);
        else
            console.log('miezaz impedellum', tableName)
    }

    const subscribe = () => {
        console.log('FELIRATKOZÁS');
        const collectionName = 'components2';
        subscribeToCollection({ callback: setModelData, collectionName: collectionName });
    }

    const unsubscribe = () => {
        console.log('LEIRATKOZUNK');
        const collectionName = 'components2';
        unsubscribeFromCollection({ collectionName: collectionName });
    }

    return {
        active, setActive,
        model, subscribe, unsubscribe,
        store, setStore,
        main, setMain,
    };
};

export default Model;