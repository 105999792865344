import React from 'react';
import CTableRow from '../Layout/CTableRow';
import FSPRowFields from './FSPRowFields';
import FSPRowButtons from './FSPRowButtons';
import FSPRowFieldsToJson from './FSPRowFieldsToJson';
import CardWidget from '../../../UI/Widgets/CardWidget';
import { getPropArray } from '../../Utility';

const FSPRow = ({
    record,
    table,
    metaArray,
    fieldsArray,
    setNewRecord,
    setShowNewDialog,
    setShowDeleteDialog,
    view
}) => {
    const recordData = record ?
        typeof record.data == 'function' ? record.data() : record : [];
    const layout = {
        "mobile": {
            "type": "miu",
            "selector": "TableRow",
            "declarations": {

            }
        },
        "card": {
            "type": "html",
            "selector": "TableRow",
            "declarations": {

            }
        },
        "table": {
            "type": "html",
            "selector": "TableRow",
            "declarations": {

            }
        },
    }
    const data = FSPRowFieldsToJson({
        table: table,
        record: recordData,
        metaArray: metaArray,
        fieldsArray: fieldsArray,
        view: view
    })
    // const uiFields = {
    //     "title": "name",
    //     "avatar": "Profilkép",
    //     "subtitle": "name",
    // }
    // .map((key) => data[uiFields[key]])
    

    
    const ui = getPropArray(table, ['ui']);
    const m = getPropArray(metaArray, ['ui', ui, 'children']);
    
    
    const uiFields = Array.isArray(m) ? m.reduce((acc, fieldName) => {
        acc[fieldName.widget_field] = fieldName.field
        return acc;
    }, {}) : null;
    


    const f = uiFields ? Object.keys(uiFields).reduce((acc, fieldName) => {
        acc[fieldName] = data[uiFields[fieldName]]
        return acc;
    }, {}) : null;
    
    const ff = f ? {
        ...f,
        onDelete: (event) => {
            event.preventDefault();
            setShowDeleteDialog(record)
        },
        onEdit: (event) => {
            event.preventDefault();
            if (record.id != undefined)
                setNewRecord({ ...recordData, id: record.id })
            else
                setNewRecord({ ...recordData })
            setShowNewDialog(true);
        },
        onView: () => console.log('Edit 1 clicked')
    } : null;

    return (<>
        {ff ? <CardWidget
            {...ff}
        />
            :
            <CTableRow view={view} layout={layout} record={record} table={table}>
                <FSPRowFields
                    table={table}
                    record={recordData}
                    metaArray={metaArray}
                    fieldsArray={fieldsArray}
                    view={view}
                />
                <FSPRowButtons
                    recordData={recordData}
                    record={record}
                    setNewRecord={setNewRecord}
                    setShowNewDialog={setShowNewDialog}
                    setShowDeleteDialog={setShowDeleteDialog}
                />

            </CTableRow>
        }
    </>
    );
};

export default FSPRow;