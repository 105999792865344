import React from 'react';
import * as Icons from '@mui/icons-material';

const FSPIcon = ({ icon, active = false }) => {
    if (icon) {
        const IconComponent = Icons[icon];
        return (
            <IconComponent color={active ? "primary" : ''} />
        );
    } else {
        return null;
    }

};

export default FSPIcon;