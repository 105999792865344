import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Stack } from '@mui/material';
import MainMenu from './MainMenu';
import { FirebaseSignInOut } from '../../services/firebaseServices';
import { Navigate, useNavigate } from 'react-router-dom';
import {
    getAuth,
    signOut,
} from "firebase/auth";
import { auth } from '../../firebase';
import { Logout } from '@mui/icons-material';
import LayoutSelect from './LayoutSelect';
import Profile from './Profile';
import { Context2 as Context } from '../../services/fbcontext';
import AppSelect from './AppSelect';
import MobileMenu from './MobileMenu';
import Loadings from '../../common/SubscriptionsCleaner/Loadings';
import SubscriptionsCleaner from '../../common/SubscriptionsCleaner/SubscriptionsCleaner';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MiniDrawer({ app }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const { deleteAllEnvironmentVariable } = React.useContext(Context);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const navigate = useNavigate();
    return (<>
        <AppBar position="fixed" open={false}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <>
                    <h6>
                        PR-VET
                    </h6>
                </>
                <SubscriptionsCleaner showLabel={false} />

                <Typography variant="h6"  component="div" sx={{ flexGrow: 1 }}>

                </Typography>




                <Stack direction="row" spacing={4}>
                    <AppSelect app={app} />
                    <LayoutSelect />
                </Stack>
                <Profile />
                <Button
                    color="inherit"
                    onClick={() => {
                        console.log("start logout");

                        signOut(auth).then(() => {
                            deleteAllEnvironmentVariable();
                            navigate("/signin");
                        })

                    }}
                >

                    <Logout />
                </Button>
            </Toolbar>
        </AppBar >
        <Drawer variant="permanent" open={false}>
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <MainMenu open={false} app={app} />
        </Drawer>
        <MobileMenu
            app={app}
            handleClose={handleDrawerClose}
            open={open}
        />
    </>
    );
}