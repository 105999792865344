import React, { useState } from 'react';
import { setProps } from './Utility';
import { useDrop } from 'react-dnd';
import { Button, Icon, IconButton } from '@mui/material';
import * as Icons from '@mui/icons-material';;


export const WindowComponent = ({
    children,
    main,
    node,
    nodes,
    createNode,
    updateNode,
    deleteNode,
    active,
    setActive,
    classes
}) => {
    const data = nodes[node];
    console.log('PROPS2', data)
    const style = setProps({
        p: ['display', 'flex', 'flex-flow', 'border', 'border-width', 'float',
            'top', 'bottom', 'left', 'right', 'color', 'background',
            'height', 'min-height', 'width'],
        data: data.properties
    });
    var style2 = { ...style };
    if (active == node) {
        style2 = { ...style, 'border-style': 'dashed' };
        style2 = { ...style2, 'border-color': '#f00' };
        style2 = { ...style2, 'border': '5px' };
    }
    const style3 = { ...style2, 'border-style': 'dashed', 'border-color': '#00f' };
    const stop = (e) => {
        e.stopPropagation();
        setActive(node);
    }

    const drop2 = (a) => {
        console.log('dropppp', a);
        console.log('dropppp', a.element.data);
        const d = createNode({
            parent_id: node,
            parent_prop: 'components',
            name: a.element.data.component,
            properties: a.element.data
        })
        setActive(d.node);
        // setBasket((basket) =>
        // !basket.includes(item) ? [...basket, item] : basket)
    }
    const [{ isOver, isHover }, dropRef] = useDrop({
        accept: 'pet',
        drop: (item) => drop2(item),
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true })
        }),
        canDrop: function (props, monitor, component) {
            console.log('PAMPAM' + node, isOver);
            return (
                isOver
            );
        }
    })

    return (
        (<div
            ref={dropRef}
            style={isOver ? style3 : style2}
            className={active !== node ? 'active_window' : 'window'}
            onClick={(e) => stop(e)}>

            {children}
            {isOver && <div>Drop Here2!</div>}

        </div>)
    );
}
export const defaultComponent = ({ children, data }) => {
    console.log('PROPS1', data)
    const color = data ? data.color : '#f00';
    return (
        (<>

            {children}

        </>)
    );
}
