import { MenuItem, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import useFirestoreStore2 from '../../services/useFirestoreStore2';
import { Context2 as Context } from '../../services/fbcontext';
import { FormatAlignCenter, FormatAlignLeft, StayCurrentPortrait, Tv } from '@mui/icons-material';

const LayoutSelect = () => {
    const { environmentVariables, setEnvironmentVariable } = useContext(Context);
    const variable = 'view';
    const valuesKey = 'key';
    const valuesLabel = 'label';
    const options = [
        { key: "mobile", label: "Mobile" },
        { key: "desktop", label: "Desktop" }
    ];

    const handleAlignment = (event, newAlignment) => {
        event.preventDefault();
        console.log(newAlignment);
        if (newAlignment)
            setEnvironmentVariable({ key: 'view', value: newAlignment });
    };

    return (<>
        <ToggleButtonGroup
            value={environmentVariables.view ?? "desktop"}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
            color="error"
        >
            <ToggleButton value="desktop" aria-label="left aligned">
                <Tv />
            </ToggleButton>
            <ToggleButton value="mobile" aria-label="centered">
                <StayCurrentPortrait />
            </ToggleButton>
        </ToggleButtonGroup >
    </>
    );
};

export default LayoutSelect;