import React, { useEffect } from 'react';
import { Box, Drawer } from '@mui/material';
import CCode from './CCode';
import CPropertyBar from './CPropertyBar';
import CToolbar from './CToolbar';
import CTree from './CTree';
import './styles.css';
import ProcessButton from '../ProcessButton';
import Model from "./Model";
import Nodes from "./Nodes";
import CNode from './components/CNode';

const drawerWidth = 240;

const ComponentEditor = () => {
    const [treeOpen, setTreeOpen] = React.useState(true);
    const [codeOpen, setCodeOpen] = React.useState(true);
    const [propertyOpen, setPropertyOpen] = React.useState(true);
    const {
        model, subscribe, unsubscribe,
        active, setActive,
        store, setStore,
        main, setMain,
    } = Model();
    const {
        nodes, setNode,
        createNode, deleteNode, updateNode } = Nodes();
    const handleToolbar = () => {
        setPropertyOpen((p) => !p)
    }
    const handleCode = () => {
        setCodeOpen((p) => !p)
    }
    const handleTree = () => {
        setTreeOpen((p) => !p)
    }

    useEffect(() => {
        subscribe();
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <>

            <Box sx={{
                margin: 0,
                padding: 0, display: 'flex'
            }}>
                <Drawer
                    sx={{
                        width: `${treeOpen ? 250 : 0}px`,
                        margin: 0,
                        padding: 0,
                        flexShrink: -250,
                        '& .MuiDrawer-paper': {
                            // width: 250,
                            width: `${treeOpen ? 250 : 0}px`,
                            top: 70,
                            left: 70,
                            margin: 0,
                            padding: 0

                        },
                    }}
                    variant="persistent"
                    anchor='left'
                    open={treeOpen}
                // onClose={toggleDrawer(anchor, false)}
                >

                    <CTree
                        node={main}
                        main={main}
                        nodes={nodes}
                        updateNode={updateNode}
                        deleteNode={deleteNode}
                        createNode={createNode}
                        active={active}
                        setActive={setActive}
                    />
                </Drawer>

                <Box
                    sx={{
                        flex: 1,
                        //  margin: 0,
                        flexGrow: 1,
                        marginTop: '-25px',
                        marginLeft: '-15px',
                        marginRight: '-25px',
                        // height: `calc(100vh - ${codeOpen ? 450 : 160}px)`
                    }}
                >
                    <div className="save__controls">

                        <ProcessButton
                            name="Toolbar"
                            icon="FormatAlignCenter"
                            onClick={(t) => handleToolbar()}
                        />
                        <ProcessButton
                            name="Code"
                            icon="Code"
                            onClick={(t) => handleCode()}
                        />
                        <ProcessButton
                            name="Tree"
                            icon="AccountTree"
                            onClick={(t) => handleTree()}
                        />
                    </div>

                    <CToolbar model={model} />
                    <Box
                        sx={{
                            flexGrow: 1,
                            padding: 0,
                            display: 'flex',
                            height: `calc(100vh - ${codeOpen ? 400 : 160}px)`, // A maradék hely kitöltése
                            overflow: 'auto', // Automatikus görgetősáv
                            margin: '0px ', // 200px padding fölül és alul, 250px padding balról és job  
                            padding: 0,
                            // width: { sm: `calc(100% - ${drawerWidth}px)` }
                        }}
                    >
                        <CNode
                            node={main}
                            main={main}
                            nodes={nodes}
                            updateNode={updateNode}
                            deleteNode={deleteNode}
                            createNode={createNode}
                            active={active}
                            setActive={setActive}
                            store={store}
                            setStore={setStore}
                        />
                    </Box>
                    <Drawer
                        sx={{
                            flexShrink: 250,
                            '& .MuiDrawer-paper': {
                                // width: `calc(100% - ${treeOpen ? 250 : 0}px- ${propertyOpen ? 250 : 0}px-70)`,
                                height: `calc(${codeOpen ? 250 : 0}px)`,
                                left: `calc(${treeOpen ? 320 : 70}px)`,
                                // left: '250px',
                                // right: '250px',
                            },
                        }}
                        variant="persistent"
                        anchor='bottom'
                        open={codeOpen}>
                        <CCode
                            node={main}
                            main={main}
                            nodes={nodes}
                            updateNode={updateNode}
                            deleteNode={deleteNode}
                            createNode={createNode}
                            active={active}
                            setActive={setActive}
                            store={store}
                            setStore={setStore}
                        />
                    </Drawer>
                    {/*  */}
                </Box>

                <Drawer
                    sx={{
                        width: `${propertyOpen ? 250 : 0}px`,
                        flexShrink: -250,
                        '& .MuiDrawer-paper': {
                            // width: 250,
                            width: `${propertyOpen ? 250 : 0}px`,
                            top: 70
                        },
                    }}
                    variant="persistent"
                    anchor='right'
                    open={propertyOpen}
                // onClose={toggleDrawer(anchor, false)}
                >
                    <CPropertyBar
                        node={main}
                        main={main}
                        nodes={nodes}
                        updateNode={updateNode}
                        deleteNode={deleteNode}
                        createNode={createNode}
                        active={active}
                        setActive={setActive}
                        store={store}
                        setStore={setStore}
                    />
                </Drawer>

            </Box>
        </>
    );
};

export default ComponentEditor;
