export const table =
{
	"indexFields": [
		"userId",
		"groupId"
	],
	"updatefields": [
		"userId",
		"groupId"
	],
	"collection": "groupmembers",
	"updatedAt": {
		"seconds": 1691512066,
		"nanoseconds": 468000000
	},
	"title_update": "Tag módosítása",
	"meta": [
		"users",
		"groups"
	],
	"type": "array",
	"fields": [
		{
			"helper": "userId",
			"_id": "item_1691504690018",
			"model": "users",
			"label": "userId",
			"name": "userId",
			"type": "document_select",
			"fields": [],
			"updatefields": [],
			"valuesLabel": "email",
			"required": "true",
			"indexFields": [],
			"valuesKey": "id"
		},
		{
			"helper": "groupId",
			"updatefields": [],
			"name": "groupId",
			"model": "groups",
			"label": "groupId",
			"type": "document_select",
			"required": "true",
			"fields": [],
			"valuesLabel": "name",
			"indexFields": [],
			"_id": "item_1691504717914",
			"valuesKey": "id"
		}
	],
	"title_create": "Új tag",
	"_id": "groupmembers",
	"ui": "subscibe_widget",
	"icon": "GroupAdd",
	"name": "Csoporttagság",
	"title_index": "Csoporttagság kezelése",
	"id": "groupmembers"
}
export default table