import React from 'react';
import { HTMLComponent } from './HTMLComponents';
import MiuComponent from './MiuComponent';

const CPreview = ({
    children,
    nodes,
    node,
    store,
    setStore,
    setActive,
    active,
    isOver,
    dropRef
}) => {
    const data = nodes[node];
    const properties = data.properties;
    const type = properties.component;

    let Selector = '';
    switch (type) {
        case 'html':
            Selector = HTMLComponent
            break;
        // case 'custom':
        //     selector = custom[layoutview.selector]
        //     break;
        case 'MUIComponent':
            Selector = MiuComponent
            break;
        default:
            Selector = HTMLComponent
            break;
    }
    console.log('Selector', type)
    return (
        <Selector
            children={children}
            nodes={nodes}
            node={node}
            setActive={setActive}
            store={store}
            setStore={setStore}
            active={active}
            dropRef={dropRef}
            isOver={isOver}
        />
    );
};

export default CPreview;