import { MenuItem, TextField } from '@mui/material';
import React from 'react';

const FSPFieldSelect = ({
    id,
    label,
    placeholder,
    options,
    valuesKey,
    valuesLabel,
    handleChange,
    value,
    addId
}) => {
    return (
        <div>
            <div>
                {label}
            </div>
            <TextField
                required
                fullWidth
                id={id}
                name={id}
                label={label}
                select
                value={value}
                onChange={handleChange}
            // helperText={field.helper}
            >
                {addId &&
                    <MenuItem
                        key={'id'}
                        value={'id'}>
                        {'id'}
                    </MenuItem>}
                {options && Object.keys(options).map((option) => (
                    <MenuItem
                        key={options[option][valuesKey]}
                        value={options[option][valuesKey]}>
                        {options[option][valuesLabel]}
                    </MenuItem>
                ))}
            </TextField>
        </div>
    );
};

export default FSPFieldSelect;