import React from 'react';
import { List, Table } from '@mui/material';

const CTable = ({ children, view, ...params }) => {

    return (
        <>
            {view == 'mobile' ?
                <List params sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {children}
                </List>
                :
                <div>
                    {children}
                </div>
            }
        </>
    );
};

export default CTable;