export const table =
{
	"type": "array",
	"_id": "applications",
	"name": "Applikációk",
	"title_update": "alkalmazás módosítása",
	"updatedAt": {
		"seconds": 1684257483,
		"nanoseconds": 533000000
	},
	"icon": "Apps",
	"title_create": "Új alkalmazások",
	"meta": [
		"constants",
		"tables",
		"styles"
	],
	"indexFields": [
		"name",
		"version",
		"sdkVersion",
		"id"
	],
	"updatefields": [
		"name",
		"version",
		"sdkVersion",
		"id",
		"config",
		"icon"
	],
	"collection": "applications",
	"fields": [
		{
			"helper": "Appname",
			"indexFields": [],
			"name": "name",
			"required": "true",
			"updatefields": [],
			"_id": "item_1684257223758",
			"fields": [],
			"label": "Appname",
			"type": "text"
		},
		{
			"name": "version",
			"fields": [],
			"helper": "version",
			"label": "version",
			"required": "true",
			"_id": "item_1684257242192",
			"updatefields": [],
			"type": "text",
			"indexFields": []
		},
		{
			"label": "sdkVersion",
			"fields": [],
			"name": "sdkVersion",
			"helper": "sdkVersion",
			"indexFields": [],
			"updatefields": [],
			"_id": "item_1684257251446",
			"required": "true",
			"type": "text"
		},
		{
			"label": "id",
			"type": "text",
			"required": "true",
			"fields": [],
			"name": "id",
			"_id": "item_1684257349453",
			"indexFields": [],
			"helper": "id",
			"updatefields": []
		},
		{
			"helper": "Firebase config",
			"fields": [],
			"updatefields": [],
			"_id": "item_1684257413798",
			"indexFields": [],
			"label": "Firebase config",
			"name": "config",
			"required": "true",
			"type": "jsoninput"
		},
		{
			"helper": "icon",
			"name": "icon",
			"fields": [],
			"required": "true",
			"_id": "item_1684257428129",
			"updatefields": [],
			"type": "icon",
			"default": "AcUnit",
			"indexFields": [],
			"label": "icon"
		}
	],
	"title_index": "Alkalmazások",
	"id": "applications"
}

export default table