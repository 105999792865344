import React from 'react';
import { getMessaging, getToken } from 'firebase/messaging';
import { sendPushNotification } from '../firebase';
// import { getToken } from 'firebase/messaging/sw';

const PushNotification = () => {


    return (
        <div>
            <button onClick={sendPushNotification}>Küldés</button>
        </div>
    );
};

export default PushNotification;
