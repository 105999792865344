export const table =
{
	"updatefields": [
		"template",
		"scope",
		"group"
	],
	"_id": "questionnaires",
	"title_create": "Új kérdőív",
	"collection": "questionnaires",
	"title_index": "Kérdőívek",
	"meta": [
		"constants",
		"qtemplates",
		"groups"
	],
	"name": "Kérdőív ",
	"updatedAt": {
		"seconds": 1693913165,
		"nanoseconds": 549000000
	},
	"title_update": "Kérdőív módosítása",
	"ui": "general",
	"icon": "QuestionMark",
	"type": "array",
	"indexFields": [
		"template",
		"scope",
		"group"
	],
	"fields": [
		{
			"type": "document_select",
			"valuesLabel": "name",
			"indexFields": [],
			"name": "template",
			"required": "true",
			"valuesKey": "id",
			"helper": "Sablon",
			"fields": {},
			"label": "Sablon",
			"model": "qtemplates",
			"_id": "item_1693774737428",
			"updatefields": []
		},
		{
			"name": "scope",
			"valuesField": "values",
			"required": "true",
			"_id": "item_1693775413753",
			"indexFields": [],
			"fields": {},
			"type": "value_select_static",
			"valuesLabel": "label",
			"document": "kerdoiv_scope",
			"label": "Kitöltők",
			"valuesKey": "value",
			"updatefields": [],
			"helper": "Kitöltők",
			"model": "constants"
		},
		{
			"required": "true",
			"label": "Dátumtól",
			"_id": "item_1693775469235",
			"name": "from",
			"fields": {},
			"type": "text",
			"updatefields": [],
			"helper": "Dátumtól",
			"indexFields": []
		},
		{
			"indexFields": [],
			"_id": "item_1693775485523",
			"name": "to",
			"label": "Dátumig",
			"helper": "Dátumig",
			"updatefields": [],
			"fields": {},
			"type": "text",
			"required": "true"
		},
		{
			"updatefields": [],
			"label": "Csoport",
			"indexFields": [],
			"model": "groups",
			"valuesKey": "id",
			"name": "group",
			"required": "true",
			"type": "document_select",
			"_id": "item_1693864846496",
			"helper": "Csoport",
			"fields": {},
			"valuesLabel": "name"
		}
	],
	"id": "questionnaires"
}
export default table