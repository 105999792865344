import React, { useState } from 'react';
import { TextField, Checkbox, FormControlLabel, Button, MenuItem } from '@mui/material';
import DynamicFormPreview from './DynamicFormPreview';
import { Badge as Badge2 } from '../../common/miu/components/Badge';
import FSPFieldIcon2 from '../FSPCrud/FSPForm/FSPField/FSPFieldIcon2';

const DynamicForm = ({ component, children }) => {
    const [formValues, setFormValues] = useState(component.props.reduce((acc, prop) => {
        acc[prop.name] = prop.default || '';
        return acc;
    }, {}));
    // const componentChildren = children ? children.map(child => renderComponent(child)) : null;
    const uuidv4 = (a) => {
        console.log('uuidv4', component.name + a)
        return component.name + a
    }
    const handleInputChange = (event) => {
        console.log(event)
        const { name, value, type, checked } = event.target;
        const newValue = parseValueByType(value, type);
        setFormValues((prevValues) => ({ ...prevValues, [name]: newValue }));
    };
    const handleChange = (event) => {
        console.log(event)
        const { name, value, type, checked } = event.target;
        const newValue = parseValueByType(value, type);
        setFormValues((prevValues) => ({ ...prevValues, [name]: checked }));

    };
    const handleChangeIcon = (name, value) => {

        console.log(name);
        console.log(value);
        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
        // if (Array.isArray(value)) {
        //     setNewRecord({ ...newRecord, [name]: value });
        // } else {
        //     setNewRecord({ ...newRecord, [name]: value });
        // }

    }
    const parseValueByType = (value, type) => {
        if (type === 'bool' || type === 'checkbox') {
            return Boolean(value);
        } else if (type === 'number') {
            return Number(value);
        } else if (type === 'array' || type === 'object') {
            try {
                return JSON.parse(value);
            } catch (error) {
                console.error('Invalid JSON value');
                return value;
            }
        } else if (type === 'elementType') {
            return value; // No type conversion needed for elementType
        }
        return value;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // TODO: Handle form submission
    };

    const isPropRequired = (propName) => {
        const prop = component.props.find((p) => p.name === propName);
        return prop && prop.required;
    };

    const isParamRequired = (methodName, paramName) => {
        const method = component.methods.find((m) => m.name === methodName);
        return method && method.params.some((p) => p.name === paramName && p.required);
    };

    return (
        <><h1>{component.name}</h1>
            <DynamicFormPreview children={children} component={component} formValues={formValues}>
               asdsa
            </DynamicFormPreview>

            <form onSubmit={handleSubmit}>
                {component && component.props && component.props.map((prop) => (
                    <div key={uuidv4('1')}>
                        {prop.type === 'bool' ? (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={prop.name}
                                        checked={formValues[prop.name]}
                                        onChange={handleChange}
                                    />
                                }
                                label={prop.name}
                            />
                        ) : prop.name === 'color' ? (
                            <TextField
                                name={prop.name}
                                label={prop.name}
                                // type="color"
                                value={formValues[prop.name]}
                                onChange={handleInputChange}
                                required={isPropRequired(prop.name)}
                                helperText={prop.description}
                                select>
                                <MenuItem value={"primary"}>primary</MenuItem>
                                <MenuItem value={"secondary"}>secondary</MenuItem>
                                <MenuItem value={"error"}>error</MenuItem>
                                <MenuItem value={"info"}>info</MenuItem>
                            </TextField>
                        ) : prop.name === 'size' ? (
                            <TextField
                                name={prop.name}
                                label={prop.name}
                                // type="color"
                                value={formValues[prop.name]}
                                onChange={handleInputChange}
                                required={isPropRequired(prop.name)}
                                helperText={prop.description}
                                select>
                                <MenuItem value={"small"}>small</MenuItem>
                                <MenuItem value={"medium"}>medium</MenuItem>
                                <MenuItem value={"large"}>large</MenuItem>

                            </TextField>
                        ) : prop.name === 'icon' ? (
                            <FSPFieldIcon2
                                field={{ name: 'icon' }}
                                handleChangeArray={handleChangeIcon}
                                newRecord={{ icon: 'Group' }}
                            />
                        ) : prop.name === 'variant' ? (
                            <TextField
                                name={prop.name}
                                label={prop.name}
                                // type="color"
                                value={formValues[prop.name]}
                                onChange={handleInputChange}
                                required={isPropRequired(prop.name)}
                                helperText={prop.description}
                                select>
                                <MenuItem value={"contained"}>contained</MenuItem>
                                <MenuItem value={"outlined"}>outlined</MenuItem>
                                <MenuItem value={"text"}>text</MenuItem>

                            </TextField>
                        ) : (
                            <TextField
                                name={prop.name}
                                label={prop.name}
                                value={formValues[prop.name]}
                                onChange={handleInputChange}
                                required={isPropRequired(prop.name)}
                                helperText={prop.description}
                            />
                        )}
                    </div>
                ))}
                <h3>Events:</h3>
                {component && component.events && component.events.map((event) => (
                    <div key={uuidv4('2')}>
                        <h4>{event.name}</h4>
                        {event && event.params && event.params.map((param) => (
                            <div key={uuidv4('3')}>
                                <TextField
                                    name={`${event.name}_${param.name}`}
                                    label={param.name}
                                    value={formValues[`${event.name}_${param.name}`]}
                                    onChange={handleInputChange}
                                    required={isParamRequired(event.name, param.name)}
                                    helperText={param.description}
                                />
                            </div>
                        ))}
                    </div>
                ))}
                <h3>Methods:</h3>
                {component && component.methods && component.methods.map((method) => (
                    <div key={uuidv4('4')}>
                        <h4>{method.name}</h4>
                        {method && method.params && method.params.map((param) => (
                            <div key={uuidv4('5')}>
                                <TextField
                                    name={`${method.name}_${param.name}`}
                                    label={param.name}
                                    value={formValues[`${method.name}_${param.name}`]}
                                    onChange={handleInputChange}
                                    required={isParamRequired(method.name, param.name)}
                                    helperText={param.description}
                                />
                            </div>
                        ))}
                    </div>
                ))}
                <Button type="submit" variant="contained" color="primary">
                    Submit
                </Button>
            </form>
            {children}
        </>
    );
};

export default DynamicForm;
