import React, { useEffect, useState } from "react";

// Component accept text, placeholder values and also pass what type of Input - input, textarea so that we can use it for styling accordingly
const Editable = ({
    childRef,
    text,
    type,
    placeholder,
    children,
    save,
    cancel,
    ...props
}) => {
    const [isEditing, setEditing] = useState(true);
    useEffect(() => {
        if (childRef && childRef.current && isEditing === true) {
            childRef.current.focus();
        }
    }, [isEditing, childRef]);
    // Event handler while pressing any key while editing
    const handleKeyDown = (event, type) => {
        const { key } = event;
        const keys = ["Escape", "Tab"];
        const enterKey = "Enter";
        const allKeys = [...keys, enterKey]; // All keys array

        if (
            (type === "textarea" && keys.indexOf(key) > -1) ||
            (type !== "textarea" && allKeys.indexOf(key) > -1)
        ) {
            setEditing(false);
        }
        if (key === 'Escape') {
            cancel()
        }
        if (key === 'Enter' || key === 'Tab') {
            save()
        }
    }

    return (
        <section {...props}>

            <div
                onBlur={() => setEditing(false)}
                onKeyDown={e => handleKeyDown(e, type)}
            >
                {children}
            </div>

        </section>
    );
};

export default Editable;