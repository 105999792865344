import React, { useContext, useEffect, useState } from 'react';
import { Context2 as Context } from '../../services/fbcontext';
import { getProp, getPropArray } from '../../common/Utility';

const Model = ({ table }) => {
    const [model, setModel] = useState();
    const { environmentVariables, setEnvironmentVariable,
        queryDocumentEV,
        queryDocumentsEV,
        subscriptions,
        subscribeToCollectionEV,
        unsubscribeFromCollectionEV,
    } = React.useContext(Context);

    const subscribe = () => {
        console.log('subscriptions FEL2');
        subscribeToCollectionEV({ table: table });
    }

    const unsubscribe = () => {
        console.log('subscriptions LE2');
        unsubscribeFromCollectionEV({ table: table });
    }

    useEffect(() => {
        console.log('environmentVariables', environmentVariables);
        setModel(getProp(environmentVariables, table))
    }, [environmentVariables[table]])


    useEffect(() => {
        console.log(' LEE subscriptions FEL2');
        subscribe();
        return () => {
            console.log('subscriptions LE2');
            unsubscribe();
        };
    }, [table]);

    // useEffect(() => {
    //     console.log('subscriptions', subscriptions);
    // }, [subscriptions])

    const getProfile = ({ user }) => {
        if (user) {
            queryDocumentEV({ key: 'profile', table: 'users', id: user.uid });
        }
    }

    return {
        subscribe, unsubscribe, environmentVariables, model
    };
};

export default Model;