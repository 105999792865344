import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FSPFieldJson from './FSPField/FSPFieldJson';
import FSPFieldText from './FSPField/FSPFieldText';
import FSPFieldSelect from './FSPField/FSPFieldSelect';
import FSPFieldModelMultiSelect from './FSPField/FSPFieldModelMultiSelect';
import FSPFieldNumber from './FSPField/FSPFieldNumber';
import FSPFieldIcon from './FSPField/FSPFieldIcon';
import { convertArrayById } from '../../Utility';
import FSPFieldJsonInput from './FSPField/FSPFieldJsonInput';
import FSPFieldImg from './FSPField/FSPFieldImg';
import FSPFieldImgStore from './FSPField/FSPFieldImgStore';
import FSPFieldDefault from './FSPField/FSPFieldDefault';
import FSPFieldImageSelect from './FSPField/FSPFieldImageSelect';
import FSPFormFieldJson2 from './FSPField/FSPFieldJson2';
import FSPFieldIcon2 from './FSPField/FSPFieldIcon2';
const FSPFormField = ({
    field,
    handleChange,
    handleChangeArray,
    handleChangeTable,
    newRecord,
    errors,
    meta,
    view,
    metaArray,
    parentRecord
}) => {
    const [op, setOp] = useState([]);
    const [hide, setHide] = useState(0);
    const {
        id, required, name, helper, q2, label, type,
        model,
        valuesLabel, valuesKey,
        values_key, values_label, values, valuesField,
        _document, document, autoHide, _valuesField, _model, addId
    } = field;
    const onSelect = event => {
        let a = event.map((i) => i.id);
        handleChangeArray(field.name, a)
    };
    const onRemove = event => {
        let a = event.map((i) => i.id);
        handleChangeArray(field.name, a)
    }

    var modelfieldsOptions = [];

    if (field.type == 'value_select_dynamic') {
        const tables = convertArrayById(meta[model], 'id');
        const table = newRecord ? newRecord[_document] : null;
        const documentum = tables && table ? tables[table] : null;
        modelfieldsOptions =
            documentum && valuesField
                ? documentum[valuesField]
                : [];
    }

    if (field.type == 'value_select_dynamic2') {
        const doc = newRecord && _document ? newRecord[_document] : null;
        const vField = newRecord && _valuesField ? newRecord[_valuesField] : null;
        const tables = meta && model && meta[model] ? convertArrayById(meta[model], 'id') : null;
        const table = tables && doc ? tables[doc] : null;
        const mainFields = table && table['fields'] ?
            (Array.isArray(table['fields']) ? convertArrayById(table['fields'], 'name') : table['fields'])
            : null;
        const mainField = mainFields && vField ? mainFields[vField] : null;
        const subFields = mainField &&
            mainField['fields'] ?
            mainField['fields'] : null;
        modelfieldsOptions =
            subFields
                ? subFields
                : [];
    }

    if (field.type == 'value_select_dynamic_parent') {
        const model2 = parentRecord.model;
        const tables = convertArrayById(meta[model], 'id');
        const table = model2;
        const valuesField2 = 'fields';
        const documentum = tables && table ? tables[table] : null;
        modelfieldsOptions =
            documentum && valuesField2
                ? documentum[valuesField2]
                : [];
    }

    useEffect(() => {
        if (field.type == 'value_select_static' && document && valuesField) {
            const tables = convertArrayById(meta[model], 'id');
            const documentum = tables ? tables[document] : null;
            const modelfieldsOptions = documentum ?
                documentum[valuesField] : [];
            setOp(modelfieldsOptions);
        }
    }, [field.type]);

    useEffect(() => {
        if (autoHide &&
            Array.isArray(autoHide) &&
            autoHide.indexOf(newRecord.type) > -1
        ) {
            setHide(1);
        } else {
            setHide(0);
        }
    }, [newRecord]);
    return <>
        {!hide &&
            <div>
                {
                    field.type == 'default' &&
                    <FSPFieldDefault
                        field={field}
                        handleChange={handleChange}
                        handleChangeArray={handleChangeArray}
                        handleChangeTable={handleChangeTable}
                        newRecord={newRecord}
                        errors={errors}
                        meta={meta}
                        view={view}
                        metaArray={metaArray}
                    />
                }
                {
                    field.type == 'text' &&
                    <FSPFieldText
                        field={field}
                        value={newRecord[field.name] ?? ''}
                        handleChange={handleChange}
                    />
                }
                {
                    field.type == 'img' &&
                    <FSPFieldImg
                        field={field}
                        value={newRecord[field.name] ?? ''}
                        handleChangeArray={handleChangeArray}
                    />
                }
                {
                    field.type == 'img_store' &&
                    <FSPFieldImgStore
                        field={field}
                        id={newRecord?.id}
                        value={newRecord[field.name] ?? ''}
                        handleChangeArray={handleChangeArray}
                    />
                }
                {
                    field.type == 'jsoninput' &&
                    <FSPFieldJsonInput
                        field={field}
                        value={newRecord[field.name] ?? ''}
                        handleChange={handleChange}
                        errors={errors}
                    />
                }
                {field.type == 'number' &&
                    <FSPFieldNumber
                        field={field}
                        value={newRecord[field.name] ?? ''}
                        handleChange={handleChange}
                    />
                }
                {field.type == 'select' &&
                    <FSPFieldSelect
                        id={name}
                        label={label}
                        placeholder={q2}
                        options={values ?? []}
                        valuesKey={values_key}
                        valuesLabel={values_label}
                        value={newRecord[name]}
                        handleChange={handleChange}
                    />
                }
                {field.type == 'document_select' &&
                    <FSPFieldSelect
                        id={name}
                        label={label}
                        placeholder={q2}
                        options={meta[model] ?? []}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        value={newRecord[name]}
                        handleChange={handleChange}
                    />
                }
                {field.type == 'image_select' &&
                    <FSPFieldImageSelect
                        id={name}
                        label={label}
                        placeholder={q2}
                        model={model}
                        options={meta[model] ?? []}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        value={newRecord[name]}
                        handleChange={handleChange}
                    />
                }
                {field.type == 'document_select_dynamic' &&
                    <FSPFieldSelect
                        id={name}
                        label={label}
                        placeholder={q2}
                        options={meta[newRecord[_model]] ?? []}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        value={newRecord[name]}
                        handleChange={handleChange}
                    />
                }
                {field.type == 'value_select_dynamic' &&
                    <FSPFieldSelect
                        id={name}
                        label={label}
                        placeholder={q2}
                        // options={meta[model] ?? []}
                        options={modelfieldsOptions ?? []}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        value={newRecord[name]}
                        handleChange={handleChange}
                    />
                }
                {field.type == 'value_select_dynamic2' &&
                    <FSPFieldSelect
                        id={name}
                        label={label}
                        placeholder={q2}
                        options={modelfieldsOptions ?? []}
                        _valuesField={_valuesField}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        value={newRecord[name]}
                        handleChange={handleChange}
                    />
                }
                {field.type == 'value_select_dynamic_parent' &&
                    <FSPFieldSelect
                        id={name}
                        label={label}
                        placeholder={q2}
                        // options={meta[model] ?? []}
                        options={modelfieldsOptions ?? []}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        value={newRecord[name]}
                        handleChange={handleChange}
                    />
                }
                {field.type == 'value_select_static' &&
                    <FSPFieldSelect
                        id={name}
                        label={label}
                        placeholder={q2}
                        // options={meta[model] ?? []}
                        options={op}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        value={newRecord[name]}
                        handleChange={handleChange}
                    />
                }
                {field.type == 'document_multiselect' &&
                    <FSPFieldModelMultiSelect
                        id={id}
                        label={label}
                        placeholder={q2}
                        model={meta[model] ?? []}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        selected={newRecord[name] ?? []}
                        onSelect={onSelect}
                        onRemove={onRemove}
                    />
                }
                {field.type == 'value_mutiselect_local' &&
                    <FSPFieldModelMultiSelect
                        id={id}
                        label={label}
                        placeholder={q2}
                        model={newRecord[model] ?? []}
                        valuesKey={valuesKey}
                        addId={addId}
                        valuesLabel={valuesLabel}
                        selected={newRecord[name]}
                        onSelect={onSelect}
                        onRemove={onRemove}
                    />
                }
                {field.type == 'json' &&
                    <FSPFieldJson
                        field={field}
                        handleChangeTable={handleChangeTable}
                        meta={meta}
                        // view={view}
                        metaArray={metaArray}
                        value={newRecord[field.name] ?? ''}
                        parentRecord={parentRecord ?? newRecord}
                    />
                }
                {field.type == 'json2' &&
                    <FSPFormFieldJson2
                        field={field}
                        handleChangeTable={handleChangeTable}
                        meta={meta}
                        // view={view}
                        metaArray={metaArray}
                        value={newRecord[field.name] ?? ''}
                        parentRecord={parentRecord ?? newRecord}
                    />
                }
                {field.type == 'icon' &&
                    <FSPFieldIcon2
                        field={field}
                        handleChangeArray={handleChangeArray}
                        newRecord={newRecord}
                    />
                }
                {errors[field.name] &&
                    <Alert severity="error">{errors[field.name]}</Alert>
                }
            </div>
        }
    </>
};

export default FSPFormField;