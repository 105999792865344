import { TextField } from '@mui/material';
import React from 'react';
import FSPFormField from '../FSPFormField';

const FSPFieldDefault = ({
    field,
    handleChange,
    handleChangeArray,
    handleChangeTable,
    newRecord,
    errors,
    meta,
    view,
    metaArray
}) => {

    return (
        <div>
            <div>
                {field.label}
                {newRecord.type}
                <FSPFormField
                    field={{ ...newRecord, required: field.required, name: field.name }}
                    errors={errors}
                    newRecord={newRecord}
                    handleChange={handleChange}
                    handleChangeArray={handleChangeArray}
                    handleChangeTable={handleChangeTable}
                    meta={meta}
                    view={view}
                    metaArray={metaArray} />
            </div>
        </div>
    );
};

export default FSPFieldDefault;