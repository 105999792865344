import { Alert, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

const FSPFieldJsonInput = ({
    field,
    handleChange,
    value
}) => {
    const textArea = useRef()
    const cursorPosition = 0;
    const [error, setError] = useState(false);
    const [jsonvalue, setJsonValue] = useState(
        (typeof value === 'object')
            ? JSON.stringify(value, null, "\t")
            : '{}'
    )

    function isJsonString(str) {
        try {
            JSON.parse(str);
            setError(false)
        } catch (e) {
            console.log(e)
            setError(true)
            return false;
        }
        return true;
    }
    const onChangeJson2 = (e) => {
        if (isJsonString(e)) {
            const j = JSON.parse(e);
            setJsonValue(JSON.stringify(j, null, "\t"))
        }

    }
    const onChangeJson = (e, a) => {
        const { selectionStart, selectionEnd } = e.target
        var j = null;
        var aa = null;
        if (isJsonString(e.target.value)) {
            j = JSON.parse(e.target.value);
            aa = JSON.stringify(j, null, "\t");
            setJsonValue(aa);
            handleChange(
                {
                    'target':
                    {
                        'name': a,
                        'value': j
                    },
                }
            );

        } else {
            setJsonValue(e.target.value);
            aa = e.target.value;
        }
        // inputRef.current.selectionStart= e.target.selectionStart;
        // inputRef.current.selectionEnd = e.target.selectionStart;
        if (textArea.current) {
            textArea.current.value = aa
            textArea.current.selectionStart = textArea.current.selectionEnd =
                selectionStart
        }
    };

    return (
        <form>
            <div>
                {field.q2}
            </div>

            <textarea
                id="w3review"
                ref={textArea}
                name="w3review" rows="20" cols="66"
                value={jsonvalue}
                onChange={(event, a) => onChangeJson(event, field.name)}
            >

            </textarea>
            {error &&
                <Alert severity="error">{"Hibás JSON formátum"}</Alert>
            }
        </form>
    );
};

export default FSPFieldJsonInput;