export const field_jsoninput = ({ name, required, label,
    autoHide, addId, defaultValue
}) => {
    return {
        "required": required,
        "name": name,
        "helper": label,
        "q2": label,
        "label": label,
        "type": "jsoninput",
        "autoHide": autoHide,
        "addId": addId,
        "default": defaultValue
    }
}