import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import MiuComponent from './MiuComponent';
import CPreview from './CPreview';

export const DropComponent = ({
    children,
    node,
    nodes,
    active,
    setActive,
    store,
    setStore,
    createNode,
}) => {

    const drop = (item) => {
        const d = createNode({
            parent_id: node,
            parent_prop: 'components',
            name: item.element.data.component,
            properties: item.element.data
        })
        setActive(d.node);
    }

    const [{ isOver, isHover }, dropRef] = useDrop({
        accept: 'pet',
        drop: (item) => drop(item),
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true })
        }),
        canDrop: function (props, monitor, component) {
            console.log('PAMPAM' + node, isOver);
            return (
                isOver
            );
        }
    })

    return (
        <CPreview
            children={children}
            nodes={nodes}
            node={node}
            setActive={setActive}
            store={store}
            setStore={setStore}
            active={active}
            dropRef={dropRef}
            isOver={isOver}
        />
    )
}
