export const document_select = ({
    name, required, label, model, valuesKey, valuesLabel,
    autoHide, addId, defaultValue
}) => {
    return {
        "required": required,
        "name": name,
        "helper": label,
        "q2": label,
        "label": label,
        "model": model,
        "valuesKey": valuesKey,
        "valuesLabel": valuesLabel,
        "autoHide": autoHide,
        "type": "document_select",
        "addId": addId,
        "default": defaultValue
    }
}