export const table =
{
	"updatedAt": {
		"seconds": 1693608290,
		"nanoseconds": 306000000
	},
	"title_create": "Új csoportjelentkezés",
	"_id": "groupsubscribes",
	"indexFields": [
		"userId",
		"groupId"
	],
	"fields": [
		{
			"model": "users",
			"helper": "userId",
			"fields": [],
			"_id": "item_1691530279342",
			"valuesKey": "id",
			"updatefields": [],
			"label": "userId",
			"indexFields": [],
			"required": "true",
			"valuesLabel": "email",
			"name": "userId",
			"type": "document_select"
		},
		{
			"updatefields": [],
			"label": "groupId",
			"helper": "groupId",
			"name": "groupId",
			"indexFields": [],
			"valuesLabel": "name",
			"_id": "item_1691530321302",
			"valuesKey": "id",
			"fields": [],
			"required": "true",
			"model": "groups",
			"type": "document_select"
		}
	],
	"collection": "groupsubscribes",
	"icon": "Subscriptions",
	"ui": "subscibe_widget",
	"name": "Csoport jelentkezések",
	"updatefields": [
		"userId",
		"groupId"
	],
	"meta": [
		"groups",
		"users"
	],
	"title_index": "Csoport jelentkezések",
	"type": "array",
	"title_update": "Csoportjelentkezés módosítása",
	"id": "groupsubscribes"
}
export default table