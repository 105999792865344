import React from 'react';
import CHtmlElement from '../../common/CHtmlElement';
import { getProp, getPropArray } from '../../common/Utility';
import { Avatar, CircularProgress, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { Comment } from '@mui/icons-material';

const layout = {
    "mobile": {
        "type": "custom", //miu, html, custom
        "selector": "Mobile",
        "declarations": {
            "backgroundColor": '#f00',
        }
    },
    "desktop": {
        "type": "custom",//miu, html, custom
        "selector": "Main",
        "declarations": {
            "backgroundColor": '#0f0',
        }
    }
}
const View = ({ model, children, app, loading, loading_backup, user, user_backup, view = 'desktop', ...params }) => {

    console.log('model', model);
    console.log('children', children);
    console.log('app', app);
    console.log('loading', loading);
    console.log('user', user);
    console.log('view', view);
    console.log('model', model);

    return (
        <CHtmlElement app={app} layout={layout} view={view}>
            <List params sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {model && model.docs &&
                    model.docs.map((field, index) =>
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton aria-label="comment">
                                    <Comment />
                                </IconButton>
                            }
                        >
                            <ListItemAvatar>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                            </ListItemAvatar>
                            <ListItemText>
                                {field.data().name}
                            </ListItemText>
                        </ListItem>
                    )}
            </List>
        </CHtmlElement>
    );
};

export default View;