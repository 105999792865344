export const pages = {
    "systems": {
        "id": "systems",
        "label": "Rendszerek",
        "route": "/systems",
        "table": "systems",
        "type": "Main",
        "icon": "AdminPanelSettings"
    },
    "applications": {
        "id": "applications",
        "label": "Applikációk",
        "route": "/applications",
        "table": "applications",
        "type": "Main",
        "icon": "AdminPanelSettings"
    },
    "tables": {
        "id": "tables",
        "label": "Táblák",
        "route": "/tables",
        "table": "tables",
        "type": "Main",
        "icon": "AdminPanelSettings"
    },
    "constants": {
        "id": "constants",
        "label": "Constants",
        "route": "/constants",
        "table": "constants",
        "type": "Main",
        "icon": "AdminPanelSettings"
    },
    "components2": {
        "id": "components2",
        "label": "Components2",
        "route": "/components",
        "table": "components2",
        "type": "Main",
        "icon": "AdminPanelSettings"
    },
    "styles": {
        "id": "styles",
        "label": "Stílusok",
        "route": "/styles",
        "table": "styles",
        "type": "Main",
        "icon": "AdminPanelSettings"
    },
    "ui": {
        "id": "ui",
        "label": "UI",
        "route": "/ui",
        "table": "ui",
        "type": "Main",
        "icon": "AdminPanelSettings"
    },
    "images": {
        "id": "images",
        "label": "Képek",
        "route": "/images",
        "table": "images",
        "type": "Main",
        "icon": "AdminPanelSettings"
    },
    "teszt": {
        "id": "teszt1",
        "label": "Teszt1",
        "route": "/teszt1",
        "table": "teszt",
        "type": "Main",
        "icon": "ContactSupport",
        "component": "Teszt",
    },
    "teszt5": {
        "id": "teszt5",
        "label": "Teszt5",
        "route": "/teszt5",
        "table": "teszt5",
        "type": "Main",
        "icon": "ContactSupport",
        "component": "Teszt",
    },
    "teszt2": {
        "id": "teszt2",
        "label": "Teszt2",
        "route": "/teszt2",
        "table": "teszt",
        "type": "Static",
        "icon": "ContactSupport",
        "component": "Teszt",
    },
    "teszt3": {
        "id": "teszt3",
        "label": "Teszt3",
        "route": "/teszt3",
        "table": "teszt",
        "type": "Static",
        "icon": "ContactSupport",
        "component": "Teszt2",
    },
    "symbols": {
        "id": "symbols",
        "label": "Ikonok",
        "route": "/symbols",
        "table": null,
        "type": "Static",
        "icon": "Category",
        "component": "Symbols"
    }
}
export default pages