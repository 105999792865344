import FSPIcon from "../../../../layouts/Main/FSPIcon";
import * as Icons from '@mui/icons-material';

export const setProps = ({ p, data }) => {
    console.log('PROPS2 DATA', data)
    console.log('PROPS2 P', p)
    var style = {};
    p.forEach((key, index) => {
        console.log(key)
        console.log('PROPS2 VALUE', data[key])

        if (data && data[key])
            style[key] = data[key];

        if (data && data._input[key]) {
            if (key === 'icon') {
                style[key] = Icons[data._input[key]['value']];
            } else {
                style[key] = data._input[key]['value'];
            }
        }

    });
    console.log('PROPS4', style)
    return style
}