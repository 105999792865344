import React from 'react';
import { Drawer, IconButton } from '@mui/material';

const CCode = ({ nodes }) => {
    return (

        <div className="save__controls_monitor_header">
            <div className='process_preview_title' >
                <pre>
                    {JSON.stringify(nodes, null, "\t")}
                </pre>
            </div>
        </div>

    );
};

export default CCode;