import { tables } from './tables'
import { pages } from './pages'

export const AppDataArray =
{
   "admin": {
      "Appname": "admin",
      "version": "0.0.2",
      "sdkVersion": "0.0.1",
      "label": "Kérdőívek",
      "components": [],
      "icon": "AdminPanelSettings",
      "tables": tables,
      "pages": pages.admin,
      "defaultPage": "projects"
   },
   "group": {
      "Appname": "group",
      "version": "0.0.2",
      "sdkVersion": "0.0.1",
      "label": "Csoportok",
      "components": [],
      "icon": "Group",
      "tables": tables,
      "pages": pages.group,
      "defaultPage": "groups"
   },
   "client": {
      "Appname": "client",
      "version": "0.0.2",
      "sdkVersion": "0.0.1",
      "label": "Értékelések",
      "components": [],
      "icon": "Person",
      "tables": tables,
      "pages": pages.test,
      "defaultPage": "questionnaires"
   },
   "system": {
      "Appname": "system",
      "version": "0.0.2",
      "sdkVersion": "0.0.1",
      "label": "Rendszer",
      "components": [],
      "icon": "Apps",
      "tables": tables,
      "pages": pages.system,
      "defaultPage": "systems"
   }
}

export const defaultApplication =
   "client"