import { field_json } from "../fields/field_json"
import { document_multiselect } from "../fields/document_multiselect"
import { value_mutiselect_local } from "../fields/value_mutiselect_local"
import { field_text } from "../fields/field_text"
import subtable_tables_fields_tables_fields from "./subtable_tables_fields_tables_fields"

export const table =
{

    "meta": [
        "tables"
    ],
    "title_index": "Táblák",
    "title_create": "Új tábla",
    "title_update": "Tábla módosítása",
    "keyField": 0,
    "indexFields": [
        "name"
    ],
    "updatefields": [
        "name",
        "title_index",
        "title_create",
        "title_update",
        "indexFields",
        "updatefields",
        "meta",
        "fields"
    ],
    "initial": {
        "name": null,
        "indexFields": [],
        "fields": [],
        "meta": []
    },
    "fields": [
        field_text({ name: "name", label: "name", required: true }),

        field_text({ name: "title_index", label: "title_index", required: true }),
        field_text({ name: "title_create", label: "title_create", required: true }),
        field_text({ name: "title_update", label: "title_update", required: true }),
        value_mutiselect_local({
            name: "indexFields", label: "indexFields", required: true,
            model: "fields", valuesKey: "name", valuesLabel: "name"
        }),
        value_mutiselect_local({
            name: "updatefields", label: "updatefields", required: true,
            model: "fields", valuesKey: "name", valuesLabel: "name"
        }),
        document_multiselect({
            name: "meta", label: "Metaadatok", required: true,
            model: "tables", valuesKey: "name", valuesLabel: "name"
        }),
        field_json({ name: "fields", label: "Mezők", required: true, table: subtable_tables_fields_tables_fields })
    ]
}

export default table