import React, { useContext, useEffect, useState } from "react";
import View from "./View";
import Model from "./Model";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth_backup } from "../../firebase_backup";
import { auth, db } from "../../firebase";
import { convertArrayById } from "../../common/Utility";
import { UpdateRecord } from "../../services/GeneralService";

export function QTemplates({ table, app }) {
    const Templates = Model({ table: 'qtemplates' });
    const Questions = Model({ table: 'questions' });
    const Labels = Model({ table: 'labels' });
    const Scales = Model({ table: 'scales' });
    const [user_backup, loading_backup, error_backup] = useAuthState(auth_backup);
    const [user, loading, error] = useAuthState(auth);
    const createList = ({ id, model }) => {
        const Scales2 = convertArrayById(Scales.model.docs, 'id');
        const Labels2 = convertArrayById(Labels.model.docs, 'id');
        const Questions2 = convertArrayById(Questions.model.docs, 'id');
        console.log(Scales2)
        console.log(Labels2)
        console.log(Questions2)
        console.log(model)
        let scales = {};
        let questions = {};
        let labels = {};
        {
            model && model.section &&
                model.section.map((field, index) => {
                    console.log('Skála', field.scale);
                    console.log('Skála', Scales2[field.scale].data());
                    scales[field.scale] = Scales2[field.scale].data();
                    field.questions.map((field2, index) => {
                        console.log(field2);
                        const q = Questions2[field2.question].data();
                        console.log('Questions', q);
                        questions[field2.question] = q;
                        console.log('Skála', q.Skála);
                        const s = Scales2[q.Skála].data();
                        console.log('Skála', s);
                        scales[q.Skála] = s;
                        console.log(field2.labels);
                        //
                        console.log('q.labels', q.labels);
                        console.log('old labels', field2?.labels);
                        q.labels?.map((field3, index) => {
                            console.log(field3);
                            console.log(Labels2[field3].data());
                            //  questions[field2.question]=Questions2[field2.question].data();
                            //  console.log(field2.labels);
                            labels[field3] = Labels2[field3].data();
                        })
                        // scales[field.scale]=Scales2[field.scale].data();
                    })
                })
        }
        const meta = {
            scales: scales,
            questions: questions,
            labels: labels
        }
        console.log('meta', meta)
        console.log('id', id)
        UpdateRecord(id, 'qtemplates', { meta: meta }, ['meta'])

    }
    return (
        <>
            <View createList={createList} model={Templates.model} loading_backup={loading_backup} loading={loading} app={app} user={user} user_backup={user_backup}>

            </View>
            <View model={Questions.model} loading_backup={loading_backup} loading={loading} app={app} user={user} user_backup={user_backup}>

            </View>
            <View model={Labels.model} loading_backup={loading_backup} loading={loading} app={app} user={user} user_backup={user_backup}>

            </View>
            <View model={Scales.model} loading_backup={loading_backup} loading={loading} app={app} user={user} user_backup={user_backup}>

            </View>
        </>
    );
}

